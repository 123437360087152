import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexChangelogs } from "~/modules/changelog/api/changelog/changelogApiDispatchers.ts";

export const CHANGELOG_BASE_QUERY_KEY = "changelog";

export const ALL_CHANGELOGS_QUERY_KEY = [CHANGELOG_BASE_QUERY_KEY, "all"];

export const useAllChangelogs = () => useQuery({
		queryKey: ALL_CHANGELOGS_QUERY_KEY,
		queryFn: () => indexChangelogs().then(getDataFromResponse),
	},
);