import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import StaffingBudgetFormSectionAllAttributes
	from "modules/project/components/forms/formSections/StaffingBudgetFormSectionAllAttributes";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ComboBox from "~/components/formElements/ComboBox";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import TimingFormSection from "~/components/formSections/TimingFormSection";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import useUserSelectOptions from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { createStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import type { CreateStaffingData } from "~/modules/project/api/staffing/staffingTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type CreateStaffingFormProps = {
	onSuccess: (staffedUserId: string) => void;
	onCancel: () => void;
	onCreateUserClick: () => void;
	orderData:Order;
	selectedUserId: string | null;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
};

interface CreateStaffingFormData
	extends Omit<CreateStaffingData, "orderId" | "projectId" | "projectPhaseId" | "startDate" | "endDate" |"careerLevelId"|"isActive"> {
	careerLevelId: string|null,
	startDate: Date;
	endDate: Date | null;
}

const CreateStaffingForm: React.FC<CreateStaffingFormProps> = ({
	onSuccess,
	onCancel,
	onCreateUserClick,
	orderData,
	selectedUserId,
	totalBudgetUnassigned,
	totalManDaysUnassigned,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const {
		data: allUsersData,
	} = useAllUsers();

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().required(),
			dailyRateCents: yup.number().required(),
			endDate: yup
				.date()
				.transform(transformEmptyDateValueToNull)
				.nullable()
				.default(null)
				.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
			isBillable: yup.boolean().required(),
			manDays: yup.number().typeError(t("form:validationErrors.number.requiredTypeError")).required(),
			projectRoleName: yup.string().required(),
			userId: yup.string().required(),
			startDate: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			careerLevelId: "" as CareerLevelId,
			dailyRateCents: 0,
			endDate: null,
			manDays: 0,
			isBillable: true,
			projectRoleName: "",
			startDate: orderData.startDate ? new Date(orderData.startDate) : new Date(),
			userId: selectedUserId || "",
		};
	}, [orderData.startDate, selectedUserId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty, isSubmitted },
		setValue,
		watch,
	} = useForm<CreateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateStaffingFormData>(schema),
	});
	const projectId = orderData.projectId;
	const careerLevelSelectOptions = useCareerLevelOptions();
	const orderId = orderData.id;
	const userSelectOptions = useUserSelectOptions();
	const projectRoleSelectOptions = useProjectsProjectRolesOptions({ projectId, valueProperty: "displayName" });
	const formIsSubmittable = useFormIsSubmittable({
		isValid,
		isSubmitted,
		isLoading: busy,
		isDirty,
	});

	const { data: projectsStaffingsData } = useProjectsStaffings(projectId);

	const filteredUserSelectOptions = useMemo(() => {
		if (projectsStaffingsData) {
			const assignedUserIds = projectsStaffingsData
				.filter(staffing => staffing.orderId === orderId)
				.map((staffing) => staffing.userId);

			return userSelectOptions.filter((user) => !assignedUserIds.includes(user.value));
		}
		return userSelectOptions;
	}, [projectsStaffingsData, userSelectOptions, orderId]);

	const userId = watch("userId");

	useEffect(() => {
		if (!allUsersData) return;
		const user = allUsersData.find(user => user.id === userId);
		if (user?.careerLevelId) {
			setValue("careerLevelId", user.careerLevelId);
		}
	}, [userId, allUsersData, setValue]);

	const onSubmit: SubmitHandler<CreateStaffingFormData> = async (data: CreateStaffingFormData) => {

		setBusy(true);
		try {

			const staffingData = {
				...data,
				careerLevelId: data.careerLevelId as CareerLevelId,
				projectId: projectId,
				projectPhaseId: orderData.projectPhaseId,
				orderId: orderId,
				startDate: formatDateToYYYYMMDD(data.startDate),
				endDate: data.endDate ? formatDateToYYYYMMDD(data.endDate) : null,
			};
			await createStaffing({ projectId, staffingData });
			onSuccess(data.userId);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neues Staffing erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}

				<FormSectionGroup>
					<FormSection title="Projektmitarbeiter:in">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									placeholder="Mitarbeiter:in auswählen"
									allowNew={false}
									name={"userId"}
									control={control}
									optionsData={filteredUserSelectOptions}
								/>
							</div>
							<div className="col-span-6">
								{!selectedUserId && <ButtonNewItem size="sm"
																   theme="inline"
																   onClick={onCreateUserClick}>NORD.WORK User
									hinzufügen</ButtonNewItem>}
							</div>
						</div>
					</FormSection>
					<FormSection title="Karrierestufe / Rolle">
						<div className="grid grid-cols-2 gap-x-6 gap-y-2">
							<Select name={"careerLevelId"}
									label="Karrierstufe (Staffing)"
									disabled={!userId}
									control={control}
									optionsData={careerLevelSelectOptions} />
							<ComboBox
								placeholder="Name der Rolle"
								label="Rolle im Projekt"
								name={"projectRoleName"}
								control={control}
								optionsData={projectRoleSelectOptions}
							/>
						</div>
					</FormSection>
					<StaffingBudgetFormSectionAllAttributes control={control}
															errors={errors}
															totalManDaysUnassigned={totalManDaysUnassigned}
															totalBudgetUnassigned={totalBudgetUnassigned}
															watch={watch} />

					<TimingFormSection<CreateStaffingFormData> control={control}
															   autoUpdateMissingValues={false}
															   startDateIsClearable={false}
															   errors={errors}
															   title="Gültigkeit"
															   watch={watch}
															   setValue={setValue}
					/>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateStaffingForm;
