export const timeTrackingsTableColumnDefs = [
	{
		header: "Mitarbeiter",
		accessor: "userFullName",
		type: "string",
	},
	{
		header: "Rolle",
		accessor: "projectRoleDisplayName",
		type: "string",
	},
	{
		header: "Phase",
		accessor: "phaseTitle",
		type: "string",
	},
	{
		header: "PO",
		accessor: "orderTitle",
		type: "string",
	},
	{
		header: "Buchungstext",
		accessor: "deliverablesText",
		type: "string",
	},
	{
		header: "Datum",
		accessor: "date",
		type: "date",
	},
	{
		header: "Abrechenbar",
		accessor: "isBillable",
		type: "string",
	},
	{
		header: "Tagessatz",
		accessor: "dailyRateCents",
		type: "currency",
	},
	{
		header: "Dauer (Stunden)",
		accessor: "minutesTracked",
		type: "number",
	},
	{
		header: "Kosten",
		accessor: "totalAmountCents",
		type: "currency",
	},
];