import type { Editor } from "@tiptap/react";
import clsx from "clsx";

import BulletListIcon from "~/components/RichTextEditor/assets/BulletListIcon";
import NumberedListIcon from "~/components/RichTextEditor/assets/NumberedListIcon";
import TextBoldIcon from "~/components/RichTextEditor/assets/TextBoldIcon";
import TextItalicIcon from "~/components/RichTextEditor/assets/TextItalicIcon";
import TextUnderlineIcon from "~/components/RichTextEditor/assets/TextUnderlineIcon";
import RichTextEditorColorPicker
	from "~/components/RichTextEditor/components/RichTextEditorToolbar/RichTextEditorColorPicker";
import type { EditorFeatures } from "~/components/RichTextEditor/RichTextEditor.tsx";



const ToolbarButton = ({
	active,
	disabled,
	onClick,
	children,
	title,
}: {
	active?: boolean;
	disabled?: boolean;
	onClick: () => void;
	children: React.ReactNode;
	title: string;
}) => (
	<button
		type="button"
		title={title}
		className={clsx("m-1 flex items-center justify-center rounded-md p-1 hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-blue-500",
			active ? "bg-blue-100 hover:bg-blue-200" : "bg-transparent",
			disabled && "pointer-events-none cursor-not-allowed opacity-50",
		)}

		onClick={onClick}
	>
		{children}
	</button>
);

const HeaderButton = ({ level, editor, className = "" }: { level: 1 | 2 | 3; editor: Editor, className?: string }) => (
	<ToolbarButton
		onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
		active={editor.isActive("heading", { level })}
		title={`Heading ${level}`}
	>
    <span className={clsx("font-semibold", className)}>
      H{level}
    </span>
	</ToolbarButton>
);

const RichTextEditorToolbar = ({ editor, features }: { editor: Editor | null; features: EditorFeatures }) => {
	if (!editor) return null;

	const iconBaseStyles = "size-4 text-gray-900";
	return (
		<div className="flex flex-wrap gap-1 border-b border-gray-200 px-2">
			{features.bold && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleBold().run()}
					active={editor.isActive("bold")}
					title="Bold"
				>
					<TextBoldIcon className={iconBaseStyles} />
				</ToolbarButton>
			)}
			{features.italic && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleItalic().run()}
					active={editor.isActive("italic")}
					title="Italic"
				>
					<TextItalicIcon className={iconBaseStyles} />
				</ToolbarButton>
			)}
			{features.underline && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleUnderline().run()}
					active={editor.isActive("underline")}
					title="Underline"
				>
					<TextUnderlineIcon className={iconBaseStyles} />
				</ToolbarButton>
			)}
			{/*			{features.strike && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleStrike().run()}
					active={editor.isActive("strike")}
					title="Strikethrough"
				>
					<TextS className={iconBaseStyles} />
				</ToolbarButton>
			)}*/}
			{features.bulletList && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					active={editor.isActive("bulletList")}
					title="Bullet list"
				>
					<BulletListIcon className={iconBaseStyles} />
				</ToolbarButton>
			)}
			{features.orderedList && (
				<ToolbarButton
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					active={editor.isActive("orderedList")}
					title="Numbered list"
				>
					<NumberedListIcon className={iconBaseStyles} />
				</ToolbarButton>
			)}
			{features.color && (
				<RichTextEditorColorPicker editor={editor} />
			)}
			{features.headings && <>
				<HeaderButton level={1}
							  editor={editor}
							  className="text-sm" />
				<HeaderButton level={2}
							  editor={editor}
							  className="text-xs" />
				<HeaderButton level={3}
							  editor={editor}
							  className="text-xs" />
			</>}

		</div>
	);
};

export default RichTextEditorToolbar;