import type { TableMeta } from "@tanstack/react-table";
import type Decimal from "decimal.js-light";

export enum ChartTypeValue {
	fte = "fte",
	sickDaysAndEmployeeCount = "sickDaysAndEmployeeCount",
	sickDaysPerEmployee = "sickDaysPerEmployee",
	remainingVacationDays = "remainingVacationDays",

}

export type SickDaysPerStaffMemberTableData = {
	staffMemberId: string;
	staffMemberFullName: string;
	months: Record<string, number>;
	total: number;
}

export type RemainingVacationDaysTableData = {
	staffMemberId: string;
	staffMemberFullName: string;
	currentCareerLevelDisplayName: string;
	wasEmployedAtEndOfLastYear: boolean;
	isEmployedToday: boolean;
	remainingDaysLastYear: Decimal|null;
	remainingDaysCurrentYear: Decimal|null;
}
export type SickDaysPerStaffMemberTableMeta = TableMeta<SickDaysPerStaffMemberTableData> & {
	selectedYear: string;
}

export type HrStatisticsPageState = {
	selectedChartType: ChartTypeValue;
	selectedYear: string;
}