import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { All_LOCATIONS_QUERY_KEY, USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { PROJECT_ROLES_BY_PROJECT_QUERY_KEY } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import CreateStaffingForm from "~/modules/project/components/forms/CreateStaffingForm";
import CreateInactiveUserForm
	from "~/modules/project/components/ProjectDetailsView/components/CreateStaffingSidebar/components/CreateInactiveUserForm";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";

type CreateStaffingSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	orderData: Order;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
};

const CreateStaffingSidebar: React.FunctionComponent<CreateStaffingSidebarProps> = ({
	isOpen,
	onClose,
	orderData,
	totalBudgetUnassigned,
	totalManDaysUnassigned,
}) => {
	const { user } = useAuth();
	const [showCreateUserForm, setShowCreateUserForm] = useState(false);
	const [createdUserId, setCreatedUserId] = useState<string | null>(null);

	const queryClient = useQueryClient();

	const handleCreateUserSuccess = useCallback(async (newUserData: User) => {
		await queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		setCreatedUserId(newUserData.id);
		setShowCreateUserForm(false);
	}, [queryClient]);

	const handleSuccess = useCallback(async (staffedUserId: string) => {
		await queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECTS_PHASES_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: All_LOCATIONS_QUERY_KEY });
		await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_ROLES_BY_PROJECT_QUERY_KEY(orderData.projectId) });
		if (staffedUserId === user?.id) {
			queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user?.id) });
			queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(user?.id) });
			queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(user?.id) });
		}
		onClose();
	}, [onClose, orderData.projectId, queryClient, user?.id]);

	useEffect(() => {
		if (!isOpen) {
			setCreatedUserId(null);
			setShowCreateUserForm(false);
		}
	}, [isOpen]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{showCreateUserForm ? <CreateInactiveUserForm
					onSuccess={handleCreateUserSuccess}
					onCancel={() => setShowCreateUserForm(false)} /> :

				<CreateStaffingForm
					selectedUserId={createdUserId}
					onCreateUserClick={() => setShowCreateUserForm(true)}
					orderData={orderData}
					totalBudgetUnassigned={totalBudgetUnassigned}
					totalManDaysUnassigned={totalManDaysUnassigned}
					onSuccess={handleSuccess}
					onCancel={onClose}
				/>}
		</Sidebar>
	);
};


export default CreateStaffingSidebar;
