import Decimal from "decimal.js-light";
import ExcelJS from "exceljs";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";
import { DEFAULT_EXCEL_TABLE_STYLE } from "~/constants/excelConsts.ts";
import type { VacationDaysReport } from "~/modules/humanResources/api/vacationDaysReport/vacationDaysReportTypes.ts";
import type {
	VacationDaysReportExcelData,
} from "~/modules/humanResources/components/StaffStatisticsView/components/RemainingVacationDaysTable/components/DownloadVacationDaysReportExcelButton/vacationDaysReportExcelColumnDefs.tsx";
import {
	getVacationDaysReportColumnDefs,
} from "~/modules/humanResources/components/StaffStatisticsView/components/RemainingVacationDaysTable/components/DownloadVacationDaysReportExcelButton/vacationDaysReportExcelColumnDefs.tsx";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


function DownloadVacationDaysReportExcelButton({ buttonText, disabled, isCurrentYearReport,vacationReportData }: {
	buttonText: string,
	disabled: boolean,
	isCurrentYearReport: boolean,
	vacationReportData?: VacationDaysReport[],
}) {
	const generateExcel = async () => {
		if(!vacationReportData) return;
		const year = isCurrentYearReport ? new Date().getFullYear() : new Date().getFullYear() - 1;
		const vacationDaysReportExcelColumnDefs = getVacationDaysReportColumnDefs(year.toString());


		const workBookTitle = `NORD.NORD Resturlaubstage ${year}`;
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(workBookTitle);

		// Add headers
		const headers = vacationDaysReportExcelColumnDefs.map(col => col.header);
		const headerRow = worksheet.addRow(headers);

		// Style header row
		headerRow.font = { bold: true };
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };

		headerRow.height = 45; // Adjust this value as needed

		const sheetData: VacationDaysReportExcelData[] = vacationReportData.filter((reportData) => isCurrentYearReport ? reportData.isEmployedToday : reportData.wasEmployedAtEndOfLastYear)
			.map((reportData) => {
				const remainingDaysString = isCurrentYearReport ? reportData.remainingDaysCurrentYear : reportData.remainingDaysLastYear;

				return {
					staffMemberFullName: reportData.staffMemberFullName,
					currentCareerLevelDisplayName: reportData.currentCareerLevelId ? getCareerLevelDisplayNameById(reportData.currentCareerLevelId) : "unbekannt",
					remainingDays: new Decimal(remainingDaysString).toNumber(),
				};
			}).sort(byObjectProperty("staffMemberFullName"));

		// Add data and apply formatting
		sheetData.forEach((row) => {
			const excelRow = worksheet.addRow(
				vacationDaysReportExcelColumnDefs.map(col => {
					const value = row[col.accessor as keyof VacationDaysReportExcelData];

					if (value === null) return null; // Handle null values
					return value;
				}),
			);

			// Apply cell formatting
			vacationDaysReportExcelColumnDefs.forEach((col, colIndex) => {
				const cell = excelRow.getCell(colIndex + 1);

				switch (col.type) {
					case "number":
						cell.numFmt = "#,##0.0";
						break;
				}

				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;

			// @ts-expect-error - TS doesn't know about the includeEmpty option
			column.eachCell({ includeEmpty: true }, (cell) => {
				let cellLength = 0;
				if (cell.value) {
					if (cell.type === ExcelJS.ValueType.Date) {
						// For date cells, use the formatted string length (e.g. "dd.mm.yyyy")
						cellLength = 10;
					} else if (typeof cell.value === "string" && cell.value.includes("\n")) {
						// For multi-line cells, find the longest line
						const lines = cell.value.toString().split("\n");
						cellLength = Math.max(...lines.map(line => line.length));
					} else {
						cellLength = cell.value.toString().length;
					}
				} else {
					cellLength = 10; // Default length for empty cells
				}
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});

			column.width = Math.min(maxLength + 2, 30); // Set width to content, but max 30
		});
		// Ensure all columns are visible
		worksheet.views = [
			{ state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2", activeCell: "A2" },
		];

		const headerRowNumber = 1; // Since we add headers first
		const tableEndRowNumber = headerRowNumber + sheetData.length;
		const tableEndColumnLetter = String.fromCharCode(64 + headers.length);
		const tableName = "ProjectsTable";
		const tableRef = `A${headerRowNumber}:${tableEndColumnLetter}${tableEndRowNumber}`;

		const table = worksheet.addTable({
			name: tableName,
			ref: tableRef,
			columns: vacationDaysReportExcelColumnDefs.map(col => ({ name: col.header })),
			rows: sheetData.map(row =>
				vacationDaysReportExcelColumnDefs.map(col => {
					const value = row[col.accessor as keyof VacationDaysReportExcelData];
					if (value === null) return null;
					if (col.type === "currency" && typeof value === "number") {
						return value / 100;
					}
					return value;
				}),
			),
			style: {
				theme: DEFAULT_EXCEL_TABLE_STYLE,
				showRowStripes: true,
			},
			totalsRow: false,
		});

		// Make table sortable and filterable
		// @ts-expect-error type definitions are missing these properties
		table.table.columns.forEach(column => {
			column.filterButton = true;
		});

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = workBookTitle + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton disabled={disabled}
								theme="none"
								buttonText={buttonText}
								onClick={generateExcel} />;
}

export default DownloadVacationDaysReportExcelButton;