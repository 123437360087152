import clsx from "clsx";
import type { FunctionComponent } from "react";

import FormInputDisplayOnly from "~/components/formElements/FormInputDisplayOnly";
import FormSection from "~/components/formElements/FormSection";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type {
	StaffingsTableData,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const CompactStaffing = ({ staffing, hasConflict }: { staffing: StaffingsTableData, hasConflict: boolean }) => {
	const manDaysFormatted = staffing.isBillable ? formatNumberWithComma(staffing.manDaysPlanned, 2) : "k/a (nicht abrechenbar)";
	const dailyRateFormatted = staffing.isBillable ? formatCentsToCurrency(staffing.dailyRateCents) : "k/a (nicht abrechenbar)";
	return (
		<div className={clsx("grid grid-cols-2 gap-4 rounded-lg border px-4 py-3", hasConflict ? "border-red-500" : " border-gray-300 ")}>
			<FormInputDisplayOnly label="Startdatum"
								  useValuePadding={false}
								  value={formatDateWithGermanMonth(staffing.startDate)} />
			<FormInputDisplayOnly label="Enddatum"
								  useValuePadding={false}
								  value={staffing.endDate ? formatDateWithGermanMonth(staffing.endDate) : EMPTY_VALUE_PLACEHOLDER} />
			<FormInputDisplayOnly label="Rolle"
								  useValuePadding={false}
								  value={staffing.roleDisplayName} />
			<FormInputDisplayOnly label="Karrierestufe"
								  useValuePadding={false}
								  value={staffing.careerLevelDisplayName || EMPTY_VALUE_PLACEHOLDER} />
			<FormInputDisplayOnly label="Personentage"
								  useValuePadding={false}
								  value={manDaysFormatted} />
			<FormInputDisplayOnly label="Tagessatz"
								  useValuePadding={false}
								  value={dailyRateFormatted} />
		</div>
	);
};

type Props = {
	overlappingStaffingIds?: string[];
	usersOtherStaffings: StaffingsTableData[];
};

const UsersOtherStaffingsInOrderFormSection: FunctionComponent<Props> = ({
	overlappingStaffingIds,
	usersOtherStaffings,
}) => {
	return usersOtherStaffings.length === 0 ? null : (
		<FormSection title="Weitere Staffings des Users">
			{overlappingStaffingIds && overlappingStaffingIds.length > 0 &&
				<div className="mb-4 text-sm text-red-600">Der gewählte Gültigkeitszeitraum überschneidet sich mit
					mindestens
					einem anderen Staffing dieses Users. Vorangehende Staffings müssen ggf. mit einem Enddatum versehen werden, um nachfolgende Staffings erstellen zu können.</div>}
			<div className="space-y-4">
			{usersOtherStaffings.sort(byObjectProperty("startDate", "desc")).map((staffing) => (
				<CompactStaffing key={staffing.id}
								 hasConflict={overlappingStaffingIds ? overlappingStaffingIds.includes(staffing.id) : false}
								 staffing={staffing} />))}
			</div>
		</FormSection>);
};

export default UsersOtherStaffingsInOrderFormSection;