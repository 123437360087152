import { XMarkIcon } from "@heroicons/react/20/solid";
import Button from "components/buttons/Button";

import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";

interface DetailsProps {
	data: TimeTrackingExtendedType;
	monthIsClosed: boolean;
	onClickDelete: () => void;
	onClickEdit: () => void;
	onClickClose: () => void;
}

const Details: React.FC<DetailsProps> = ({
	data: {
		projectTitle,
		projectPhaseTitle,
		orderTitle,
		locationName,
		text,
		timeTrackingTypeId,
	},
	monthIsClosed,
	onClickDelete,
	onClickEdit,
	onClickClose,
}) => {
	const title = () => {
		if (timeTrackingTypeId === TimeTrackingTypeId.Project) {
			return projectTitle;
		}

		if (timeTrackingTypeId === TimeTrackingTypeId.Internal) {
			return "Intern";
		}

		if (timeTrackingTypeId === TimeTrackingTypeId.Sales) {
			return "Sales";
		}

		if (timeTrackingTypeId === TimeTrackingTypeId.PartTime) {
			return "Teilzeit";
		}
	};
	const isProject = timeTrackingTypeId === TimeTrackingTypeId.Project;
	return (
		<div className="w-full">
			<div className="flex justify-between">
				<div className="font-bold">
					{title()}
				</div>
				<div className="flex items-center">
					{!monthIsClosed && <><Button theme="accent"
													onClick={onClickEdit}
													size="sm">
						bearbeiten
					</Button>
						<Button className="ml-2"
								theme="danger"
								onClick={onClickDelete}
								size="sm">
							löschen
						</Button>
					</>}
					<Button size="sm"
							theme="none"
							onClick={onClickClose}>
						<XMarkIcon className="-mr-2 size-5 text-white" />
					</Button>
				</div>
			</div>
			<dl className="min-w-full divide-y divide-gray-600 whitespace-pre-line text-sm leading-6">
				{isProject &&
					<div className="px-0 py-1 opacity-60">
						<span className="opacity-60">
							Phase:
						</span>
						{` ${projectPhaseTitle} `}
						<span className="opacity-60">
							| PO:
						</span>
						{` ${orderTitle} `}
						<span className="opacity-60">
							| Ort:
						</span>
						{` ${locationName}`}
					</div>}
				<div className="flex flex-row items-start px-0 pt-1">
					<dd className="whitespace-pre-line text-sm">{text || "-"}</dd>
				</div>
			</dl>
		</div>
	);
};

export default Details;
