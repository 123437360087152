import {
	ChevronDownIcon,
	ChevronUpIcon,
	EyeIcon,
	EyeSlashIcon,
	LockClosedIcon,
	LockOpenIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import BreadcrumbsSection from "components/Breadcrumbs";
import { eachDayOfInterval, getISOWeek, isSameDay } from "date-fns";
import { t } from "i18next";
import DeleteMonthlyClosingModal from "modules/timeTracking/components/DeleteMonthlyClosingModal";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import Button from "~/components/buttons/Button";
import ContentWrapper from "~/components/ContentWrapper";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import PageHeading from "~/components/headings/PageHeading";
import Headline from "~/components/Headline";
import MainContent from "~/components/mainContent/MainContent";
import MonthAndYearNavigation from "~/components/MonthAndYearNavigation";
import type {
	MonthAndYearNavigationHandlerPropsType,
} from "~/components/MonthAndYearNavigation/MonthAndYearNavigation.tsx";
import { TIME_TRACKINGS_PATH } from "~/constants/appRoute.ts";
import { TIME_TRACKING_INDEX_PAGE_KEY } from "~/constants/pageStateStorageKeys.ts";
import { useAuth } from "~/contexts/AuthContext";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import useScrollRestoration from "~/hooks/useScrollRestoration";
import useScrollToElement from "~/hooks/useScrollToElement";
import { useStaffMembersAbsences } from "~/modules/absence/api/absence/absenceQueries.ts";
import type { AbsenceType } from "~/modules/absence/api/absenceType/absenceTypeTypes.ts";
import type { Holiday } from "~/modules/absence/api/holiday/holidayTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { Location, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import type { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import type { MonthlyClosingType } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingTypes.ts";
import { useUsersTimeTrackings } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import type { TimeTrackingType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import DownloadTimeTrackingsByMonthExcelButton
	from "~/modules/timeTracking/components/components/DownloadTimeTrackingsByMonthExcelButton";
import TimeTrackingTotals from "~/modules/timeTracking/components/components/TimeTrackingTotals";
import Week from "~/modules/timeTracking/components/components/Week";
import CreateMonthlyClosingModal from "~/modules/timeTracking/components/CreateMonthlyClosingSidebar";
import CreateTimeTrackingSidebar from "~/modules/timeTracking/components/CreateTimeTrackingSidebar";
import UpdateTimeTrackingSidebar from "~/modules/timeTracking/components/UpdateTimeTrackingSidebar";
import type { TimeTrackingTotalsByType } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import {
	calculateTimeTrackingTotals,
	getStartAndEndOfMonthDates,
} from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import type { FormInputOption } from "~/types/form.ts";
import { formatDateToYYYYMMDD, getMonthNameFromNumber } from "~/utils/dateAndTimeUtils.ts";

type TimeTrackingViewProps = {
	activeStaffings: UsersActiveStaffingType[];
	allAbsenceTypes: AbsenceType[];
	userDeliverables: UserAvailableDeliverableType[];
	userLocations: UserAvailableLocationType[];
	currentMonth: string;
	currentYear: string;
	holidays: Holiday[];
	locations: Location[];
	month: string;
	monthSelectOptions: FormInputOption[];
	timeTrackingTypes: TimeTrackingType[];
	userCanManageTimeTracking: boolean;
	userId: User["id"];
	users: User[];
	userSelectOptions: FormInputOption[];
	usersMonthlyClosingsData: MonthlyClosingType[];
	year: string;
	yearSelectOptions: FormInputOption[];
};


type PageStateType = {
	hideWeekends: boolean;
	sortOrder: "asc" | "desc";
	showOpenDaysOnly: boolean;
}

type WeekData = { week: number; days: Date[] }

const defaultPageState: PageStateType = {
	hideWeekends: true,
	sortOrder: "asc",
	showOpenDaysOnly: false,
};

const scrollToTodayOffset = -200;


const sortWeeksByFirstDay = (weeks: WeekData[], ascending: boolean = true): WeekData[] => {
	return [...weeks].sort((a, b) => {
		const sortValue = a.days[0].getTime() - b.days[0].getTime();
		return ascending ? sortValue : -sortValue;
	});
};
const TimeTrackingView: FunctionComponent<TimeTrackingViewProps> = ({
	activeStaffings,
	allAbsenceTypes,
	userDeliverables,
	userLocations,
	currentMonth,
	currentYear,
	holidays,
	locations,
	month,
	monthSelectOptions,
	timeTrackingTypes,
	userCanManageTimeTracking,
	userId,
	users,
	userSelectOptions,
	usersMonthlyClosingsData,
	year,
	yearSelectOptions,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAuth();
	const userIsFreelancer = user!.employmentTypeId === EmploymentTypeId.Freelancer;

	const { pageState, setPageState } = usePageStateStorage({
		pageKey: TIME_TRACKING_INDEX_PAGE_KEY,
		defaultState: defaultPageState,
	});

	const { setScrollToElementRef, scrollToRef, currentRef } = useScrollToElement();

	const { saveScrollPosition } = useScrollRestoration(TIME_TRACKING_INDEX_PAGE_KEY);
	useScrollRestoration(TIME_TRACKING_INDEX_PAGE_KEY);

	const [showCreateMonthlyClosingSidebar, setShowCreateMonthlyClosingSidebar] = useState(false);
	const [showDeleteMonthlyClosingSidebar, setShowDeleteMonthlyClosingSidebar] = useState(false);
	const [hideWeekends, setHideWeekends] = useState(pageState.hideWeekends);
	const [sortOrder, setSortOrder] = useState<PageStateType["sortOrder"]>(pageState.sortOrder);
	const [selectedTimeTrackingId, setSelectedTimeTrackingId] = useState<string | null>(null);
	const [timeTrackingIdToUpdate, setTimeTrackingIdToUpdate] = useState<string | null>(null);
	const [showOpenDaysOnly, setShowOpenDaysOnly] = useState(pageState.showOpenDaysOnly);
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	const getTimeTrackingPath = useCallback(({ month, year }: { month: string, year: string }) => {
		return generatePath(TIME_TRACKINGS_PATH, { userId }) + `?year=${year}&month=${month}`;
	}, [userId]);


	const [startDate, endDate] = useMemo(() => {
		if (month && year) {
			const { startDate, endDate } = getStartAndEndOfMonthDates(new Date(parseInt(year), parseInt(month)));
			return [startDate, endDate];
		}
		return [undefined, undefined];
	}, [month, year]);

	const { isLoading: timeTrackingsAreLoading, data: usersTimeTrackingData } = useUsersTimeTrackings({
		userId: userId,
		filter: {
			startDate: startDate ? formatDateToYYYYMMDD(startDate) : "",
			endDate: endDate ? formatDateToYYYYMMDD(endDate) : "",
		},
		enabled: !!(startDate && endDate),
	});

	const userData = useMemo(() => {
		if (userId && users) {
			return users.find(user => user.id === userId);
		}
		return null;
	}, [userId, users]);

	const {
		isLoading: absencesAreLoading,
		data: userAbsencesData,
	} = useStaffMembersAbsences(userData?.staffMemberId || "");

	const monthlyClosingId = useMemo(() => {
		if (year && month && usersMonthlyClosingsData) {
			const startOfSelectedMonth = formatDateToYYYYMMDD(new Date(parseInt(year), parseInt(month), 1));
			const monthlyClosing = usersMonthlyClosingsData.find(closing => closing.month === startOfSelectedMonth);
			return monthlyClosing?.id || null;
		}

		return null;
	}, [year, month, usersMonthlyClosingsData]);

	useEffect(() => {
		setPageState({ hideWeekends, showOpenDaysOnly, sortOrder });
	}, [hideWeekends, setPageState, showOpenDaysOnly, sortOrder]);


	useEffect(() => {
		setPageState({ hideWeekends, showOpenDaysOnly, sortOrder });
	}, [hideWeekends, setPageState, showOpenDaysOnly, sortOrder]);


	const handleUserSelectChange = (userId: string | null) => {
		if (userId) {
			navigate(generatePath(TIME_TRACKINGS_PATH, { userId }));
		}
	};

	const handleMonthOrYearSelectChange = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		saveScrollPosition(0);
		navigate(getTimeTrackingPath({ year, month }));
	};

	const handleGoToPrevOrNextMonthClick = ({ month, year }: MonthAndYearNavigationHandlerPropsType) => {
		navigate(getTimeTrackingPath({ year, month }));
	};

	const handleGoToTodayClick = useCallback(() => {

		navigate(getTimeTrackingPath({
			year: currentYear,
			month: (parseInt(currentMonth) + 1).toString(),
		}), { state: { scrollToToday: true } });
	}, [currentMonth, currentYear, getTimeTrackingPath, navigate]);


	const weeksInMonthSorted = useMemo(() => {
		let daysInMonth =
			month &&
			eachDayOfInterval({
				start: new Date(parseInt(year, 10), parseInt(month), 1),
				end: new Date(parseInt(year, 10), parseInt(month) + 1, 0),
			});

		if (daysInMonth && sortOrder === "desc") {
			daysInMonth = daysInMonth?.sort((a, b) => b.getTime() - a.getTime());
		}

		const weeksInMonth: WeekData[] = [];

		if (daysInMonth) {
			daysInMonth?.forEach((date) => {
				const week = getISOWeek(date);
				let weekData = weeksInMonth.find((weekData) => weekData.week === week);
				if (!weekData) {
					weekData = { week, days: [] };
					weeksInMonth.push(weekData);
				}
				weekData.days.push(date);
			});
			return sortWeeksByFirstDay(weeksInMonth, sortOrder === "asc");
		}
		return [];
	}, [month, sortOrder, year]);

	useLayoutEffect(() => {
		if (currentRef && location.state?.scrollToToday) {
			// hacky... but works
			setTimeout(() => {
				scrollToRef(scrollToTodayOffset);
			}, 100);

		}
	}, [currentRef, location, scrollToRef]);

	const selectedTimeTrackingDataForUpdate = useMemo(() => {
		if (timeTrackingIdToUpdate && usersTimeTrackingData) {
			return usersTimeTrackingData.find(timeTracking => timeTracking.id === timeTrackingIdToUpdate) || null;
		}

		return null;
	}, [timeTrackingIdToUpdate, usersTimeTrackingData]);

	const staffingIdBookedOnSelectedDay = useMemo(() => {
		if (selectedDate && usersTimeTrackingData) {
			return usersTimeTrackingData.filter(timeTracking => isSameDay(new Date(timeTracking.date), selectedDate) && timeTracking.timeTrackingTypeId === TimeTrackingTypeId.Project).map(timeTracking => timeTracking.staffingId) as string[];
		}
		return [];
	}, [selectedDate, usersTimeTrackingData]);

	const monthTotals: TimeTrackingTotalsByType = useMemo(() => {
		if (year && month && usersTimeTrackingData) {
			const dates = getStartAndEndOfMonthDates(new Date(parseInt(year), parseInt(month)));
			return calculateTimeTrackingTotals({
				startDate: dates.startDate,
				endDate: dates.endDate,
				timeTrackingData: usersTimeTrackingData,
			});
		}
		return {
			[TimeTrackingTypeId.Internal]: 0,
			[TimeTrackingTypeId.Project]: 0,
			[TimeTrackingTypeId.Sales]: 0,
			[TimeTrackingTypeId.PartTime]: 0,
		};
	}, [usersTimeTrackingData, year, month]);

	return (
		<MainContent>
			<BreadcrumbsSection pages={["Zeiterfassung"]}
								className="bg-white" />
			<PageHeading title={t("timetracking.title", "Zeiterfassung")} />
			<PageHeading.BottomBar>
				<div
					className="flex w-full items-center justify-between gap-2 text-sm font-medium text-gray-700 hover:text-gray-900">
					<div className="flex flex-row items-center">
						<MonthAndYearNavigation onGoToNextMonthClick={handleGoToPrevOrNextMonthClick}
												onGoToPrevMonthClick={handleGoToPrevOrNextMonthClick}
												onGoToTodayClick={handleGoToTodayClick}
												onMonthSelectChange={handleMonthOrYearSelectChange}
												onYearSelectChange={handleMonthOrYearSelectChange}
												month={month}
												year={year}
												monthSelectOptions={monthSelectOptions}
												yearSelectOptions={yearSelectOptions} />

						{userCanManageTimeTracking && (
							<div className="w-56">
								<ComboBoxPlain
									allowNew={false}
									value={userId}
									optionsData={userSelectOptions}
									onChange={handleUserSelectChange as (value: string | FormInputOption | null) => void}
								/>
							</div>
						)}
					</div>

					<div className="flex items-center justify-center">
						<Button theme="none"
								size="sm"
								onClick={() => setShowOpenDaysOnly(!showOpenDaysOnly)}>
							{/*		{showOpenDaysOnly ? <NoSymbolIcon className="size-4" /> :
								<CheckIcon className="size-4" />}*/}
							{showOpenDaysOnly ? <EyeSlashIcon className="size-4" /> : <EyeIcon className="size-4" />}
							Offene Tage
						</Button>
						<Button theme="none"
								size="sm"
								onClick={() => setHideWeekends(!hideWeekends)}>
							{hideWeekends ? <EyeSlashIcon className="size-4" /> : <EyeIcon className="size-4" />}
							Wochenenden
						</Button>
						<Button
							theme="white"
							size="sm"
							onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
						>
							{sortOrder === "desc" ? <ChevronDownIcon className="size-4" /> :
								<ChevronUpIcon className="size-4" />}
							<span className="-ml-1">Sortierung</span>
						</Button>
						{!!startDate && !!endDate && userData &&
							<DownloadTimeTrackingsByMonthExcelButton endDate={endDate}
																	 startDate={startDate}
																	 staffMemberDisplayName={userData?.fullName || ""}
																	 timeTrackings={usersTimeTrackingData} />}
					</div>
				</div>
			</PageHeading.BottomBar>

			<ContentWrapper className="pt-8">
				{timeTrackingsAreLoading || !!userData && absencesAreLoading ? (
					<div className="flex h-20 w-full justify-center">
						<LoadingPage />
					</div>
				) : (
					<div className="flex flex-col">
						<div className="mb-6 flex flex-col pl-4">
							<div className="flex items-center gap-x-2">
								<Headline type="h2"
										  className="font-extrabold uppercase">
									{getMonthNameFromNumber(parseInt(month))}
								</Headline>
								<Button theme="plain"
										size="auto"
										rounded="none"
										className={clsx("ml-1 size-6 cursor-pointer text-primary-500")}>
									{monthlyClosingId ?
										<LockClosedIcon className="size-full"
														onClick={() => setShowDeleteMonthlyClosingSidebar(true)} /> :
										<LockOpenIcon className="size-full"
													  onClick={() => setShowCreateMonthlyClosingSidebar(true)} />}
								</Button>
							</div>
							<TimeTrackingTotals totals={monthTotals}
												showTotalSumOnly={userIsFreelancer}
												textSize="xs"
												useShortLabels={false}
							/>
						</div>

						{weeksInMonthSorted.map(({ week, days }) => (
							<Week
								allAbsenceTypes={allAbsenceTypes}
								days={days}
								holidays={holidays}
								key={week}
								monthIsClosed={!!monthlyClosingId}
								setSelectedDate={setSelectedDate}
								setSelectedTimeTrackingId={setSelectedTimeTrackingId}
								setTimeTrackingIdToUpdate={setTimeTrackingIdToUpdate}
								selectedDate={selectedDate}
								selectedTimeTrackingId={selectedTimeTrackingId}
								setScrollToElementRef={setScrollToElementRef}
								showOpenDaysOnly={showOpenDaysOnly}
								showTotalSumOnly={userIsFreelancer}
								timeTrackingData={usersTimeTrackingData}
								userAbsencesData={userAbsencesData}
								users={users}
								visible={!hideWeekends}
								week={week}
							/>
						))}
					</div>
				)}
			</ContentWrapper>
			<CreateMonthlyClosingModal
				month={month}
				isOpen={showCreateMonthlyClosingSidebar}
				onClose={() => setShowCreateMonthlyClosingSidebar(false)}
				userId={userId}
				year={year} />

			<DeleteMonthlyClosingModal
				isOpen={showDeleteMonthlyClosingSidebar && !!monthlyClosingId}
				userCanManageTimeTrackings={userCanManageTimeTracking}
				monthlyClosingId={monthlyClosingId}
				onClose={() => setShowDeleteMonthlyClosingSidebar(false)}
				userId={userId}
			/>
			<CreateTimeTrackingSidebar
				activeStaffings={activeStaffings}
				userDeliverables={userDeliverables}
				userLocations={userLocations}
				disabledStaffingIds={staffingIdBookedOnSelectedDay}
				close={() => setSelectedDate(null)}
				isOpen={selectedDate !== null}
				date={selectedDate}
				locations={locations}
				selectedUserId={userId}
				timeTrackingTypes={timeTrackingTypes}
				users={users}
				userIsFreelancer={userIsFreelancer}
			/>

			<UpdateTimeTrackingSidebar
				activeStaffings={activeStaffings}
				userDeliverables={userDeliverables}
				userLocations={userLocations}
				close={() => setTimeTrackingIdToUpdate(null)}
				isOpen={!!selectedTimeTrackingDataForUpdate}
				date={selectedTimeTrackingDataForUpdate?.date ? new Date(selectedTimeTrackingDataForUpdate.date) : null}
				locations={locations}
				timeTrackingData={selectedTimeTrackingDataForUpdate}
				selectedUserId={userId}
				timeTrackingTypes={timeTrackingTypes}
				users={users} />
		</MainContent>
	);
};

export default TimeTrackingView;
