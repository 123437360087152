import clsx from "clsx";
import type { PropsWithChildren } from "react";

import type { LabelVariants } from "~/components/formElements/Label/labelVariants.ts";
import { labelVariants } from "~/components/formElements/Label/labelVariants.ts";

type LabelProps = LabelVariants & {
	disabled?: boolean;
	className?: string;
	htmlFor?: string;
};

const Label: React.FunctionComponent<PropsWithChildren<LabelProps>> = ({
	className,
	disabled,
	htmlFor,
	lineHeight,
	size,
	theme,
	children,
}) => {
	return (
		<label htmlFor={htmlFor}
			   className={clsx(labelVariants({ size, lineHeight, theme, disabled }),className)}>
			{children}
		</label>
	);
};

export default Label;
