import type { FunctionComponent } from "react";

import TabBarTab from "~/components/TabBar/components/TabBarTab";
import type { TabBarVariants } from "~/components/TabBar/tabBarVariants.ts";
import { tabBarVariants } from "~/components/TabBar/tabBarVariants.ts";

import type { Tab } from "./tabBarTypes.ts";
import { TabBarVariantName } from "./tabBarTypes.ts";

type TabsProps = TabBarVariants & {
	activeTabId: string;
	tabs: Tab[];
	onTabClick: (tab: any) => void;
}

const TabBar: FunctionComponent<TabsProps> = ({ activeTabId, tabs, onTabClick, theme = TabBarVariantName.Default }) => {
	const { outerWrapper, tabWrapper } = tabBarVariants({ theme });
	return (
		<div className={outerWrapper()}>
			<nav className={tabWrapper()}>
				{tabs.map(({ icon, label, id }, tabIndex) => (
					<TabBarTab onClick={onTabClick}
							   key={`sectionTabs-${tabIndex}`}
							   icon={icon}
							   id={id}
							   label={label}
							   active={id === activeTabId}
							   theme={theme} />

				))}
			</nav>
		</div>
	);
};

export default TabBar;