import {
	add,
	differenceInBusinessDays,
	eachDayOfInterval,
	endOfMonth,
	format,
	isAfter,
	isBefore,
	isSameDay,
	isSameMonth,
	isSameYear,
	isWeekend,
	parse,
	startOfDay,
	startOfToday,
	sub,
} from "date-fns";
import { de } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import { format as formatTz } from "date-fns-tz";

import type { DateRangeWithOptionalEndDate } from "~/types/dateAndTimeTypes.ts";

/**
 * Parses a date string in the format "YYYY-MM-DD" to a Date object.
 * @param dateString - The date string to parse.
 * @returns A Date object representing the parsed date.
 * @throws Error if the date string is invalid.
 */
export function parseDateString(dateString: string): Date {
	if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
		throw new Error("Invalid date string format. Expected \"YYYY-MM-DD\".");
	}

	const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());

	if (isNaN(parsedDate.getTime())) {
		throw new Error("Invalid date.");
	}

	return parsedDate;
}

type DateFormatOptions = {
	alwaysShowMonth?: boolean,
	day?: string;
	month?: string;
	year?: string;
}

export function formatDateRange(
	startDate: Date,
	endDate: Date,
	dateFormat?: DateFormatOptions,
	alwaysShowMonth=false,
): string {
	const dayFormat = dateFormat?.day || "dd. ";
	const monthFormat = dateFormat?.month || "MMMM";
	const yearFormat = dateFormat?.year || " yyyy";
	const baseFormat = `${dayFormat}${monthFormat}${yearFormat}`;
	const localeOptions = { locale: de };
	let formattedStartDate = "";
	const formattedEndDate = format(endDate, baseFormat, localeOptions);

	if (isSameDay(startDate, endDate)) {
		return formattedEndDate;
	}

	// Both dates in the same year
	if (isSameYear(startDate, endDate)) {
		// Both dates in the same month and year
		if (isSameMonth(startDate, endDate)) {
			let dateFormat = dayFormat;
			if(alwaysShowMonth){
				dateFormat = `${dayFormat}${monthFormat}`;
			}
			formattedStartDate = format(startDate, dateFormat, localeOptions);
		} else {
			formattedStartDate = format(startDate, `${dayFormat}${monthFormat}`, localeOptions);
		}
	} else {
		formattedStartDate = format(startDate, baseFormat, localeOptions);
	}

	return `${formattedStartDate} - ${formattedEndDate}`;
}

export function formatDateRangeHumanReadable(startDate: Date, endDate: Date | null): string {
	const today = startOfToday();
	if (startDate && !endDate) {
		return isBefore(today, startDate) ? "ab " + formatDateCompact(startDate) : "seit " + formatDateCompact(startDate);
	} else if (startDate && endDate) {
		return formatDateRange(startDate, endDate, { day: "dd.", month: "MM.", year: "yyyy" }, true);
	}
	return "";
}

export const formatDateTimeToDate = (dateTime: string, hhmm = false, yearFormat = "yy") => {
	if (!dateTime) {
		return null;
	}

	const pattern = !hhmm ? `dd.MM.${yearFormat}` : `dd.MM.${yearFormat} HH:mm`;

	return `${format(parseISO(dateTime), pattern)} ${hhmm ? "h" : ""}`;
};

export function formatDateToYYYYMMDD(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export const formatDateFnsDate = (date: Date, hhmm = false, yearFormat = "yyyy") => {
	if (!date) {
		return null;
	}

	const format = !hhmm ? `dd.MM.${yearFormat}` : `dd.MM.${yearFormat} HH:mm`;

	return `${formatTz(date, format)} ${hhmm ? "h" : ""}`;
};

export const getStartOfDayEndOfMonth = (date: Date) => {
	if (!date) {
		return null;
	}
	return startOfDay(endOfMonth(date));
};

/**
 * Convert a local date to its equivalent UTC date.
 *
 * @param date - The local date to convert.
 * @returns The equivalent UTC date.
 */
export function convertLocalToUTCDate(date: Date | string): Date {
	if (!date) {
		throw new Error("Invalid date provided.");
	}
	const localDate = new Date(date);
	return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
}

/**
 * Convert a UTC date to its equivalent local date.
 *
 * @param date - The UTC date to convert.
 * @returns The equivalent local date.
 */
export function convertUTCToLocalDate(date: Date | string): Date {
	if (!date) {
		throw new Error("Invalid date provided.");
	}
	const utcDate = new Date(date);
	return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
}

export const isDateBetween = (target: Date, startDate: Date, endDate: Date): boolean => {
	// normalize dates to ignore hours
	target.setHours(0, 0, 0, 0);
	startDate.setHours(0, 0, 0, 0);
	endDate.setHours(0, 0, 0, 0);
	return target.getTime() >= startDate.getTime() && target.getTime() <= endDate.getTime();
};

export function isValidDate(year?: number, month?: number, day?: number): boolean {

	if (!year || !month || !day) {
		return false;
	}
	// Create a new date using the provided parameters.
	// Remember: In JavaScript, months are 0-indexed. So, January = 0, February = 1, etc.
	const date = new Date(year, month - 1, day);

	// Check if the constructed date's year, month, and day matches the original parameters.
	return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

export function formatDateWithGermanMonth(date: Date, useShortMonthName: boolean = true): string {
	const monthFormat = useShortMonthName ? "MMM" : "MMMM";
	return format(date, `dd. ${monthFormat} yyyy`, { locale: de });
}

export function formatDateCompact(date: Date): string {
	return format(date, `dd.MM.yyyy`, { locale: de });
}


export function getWorkDaysInterval(startDate: Date, endDate: Date): Date[] {
	const interval = eachDayOfInterval({ start: startDate, end: endDate });
	return interval.filter((date) => !isWeekend(date));
}

export function getWorkdayInMonthFromDate(date: Date, searchReverse: boolean = false): Date {
	if (!isWeekend(date)) {
		return date;
	}
	if (searchReverse) {
		let previousDay = sub(date, { days: 1 });
		while (isWeekend(previousDay)) {
			previousDay = sub(previousDay, { days: 1 });
		}
		return previousDay;
	}

	let nextDay = add(date, { days: 1 });

	while (isWeekend(nextDay)) {
		nextDay = add(nextDay, { days: 1 });
	}

	return nextDay;
}

export function getHoursAndMinutesFromMinutes(minutes: number): { hours: number, minutes: number } {
	const hours = Math.floor(minutes / 60);
	const minutesLeft = minutes % 60;
	return { hours, minutes: minutesLeft };
}

export function dayIsInRange(day: Date, startDate: Date, endDate: Date): boolean {
	return (isAfter(day, startDate) || isSameDay(day, startDate)) && (isBefore(day, endDate) || isSameDay(day, endDate));
}

export function formatHoursAndMinutes(totalMinutes: number, useUnit: boolean = true): string {
	const { hours, minutes } = getHoursAndMinutesFromMinutes(totalMinutes);
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}${useUnit ? " h" : ""}`;
}

export function getMonthNameFromNumber(monthNumber: number): string {
	const date = new Date(2023, monthNumber, 1);
	return format(date, "MMMM", { locale: de });
}

export function getBusinessDaysBetween(startDate: Date, endDate: Date, inclusive = true) {
	let businessDays = differenceInBusinessDays(endDate, startDate);

	if (inclusive) {
		if (!isWeekend(endDate)) {
			businessDays += 1;
		}
	}

	return businessDays;
}

/**
 * Checks if two date ranges overlap
 * @param range1 First date range
 * @param range2 Second date range
 * @returns true if the ranges overlap, false otherwise
 */
export function doDateRangesWithOptionalEndDatesOverlap(range1: DateRangeWithOptionalEndDate,
	range2: DateRangeWithOptionalEndDate): boolean {

	// Case 1: First range has no end date (open-ended)
	if (!range1.endDate) {
		// Overlap if range1 starts before or on the same day as range2's start
		return range1.startDate <= range2.startDate;
	}

	// Case 2: Second range has no end date (open-ended)
	if (!range2.endDate) {
		// Overlap if range2 starts on or before range1's end date
		return range2.startDate <= range1.endDate;
	}

	// Case 3: Both ranges have end dates
	// Check standard overlap conditions
	return (
		(range1.startDate <= range2.startDate && range1.endDate >= range2.startDate) ||
		(range1.startDate <= range2.endDate && range1.endDate >= range2.endDate) ||
		(range1.startDate >= range2.startDate && range1.endDate <= range2.endDate)
	);
}

/**
 * Checks if a date range overlaps with any existing date ranges
 * @param newRange The new date range to check
 * @param existingRanges Array of existing date ranges to check against
 * @param excludeId Optional ID to exclude from comparison (for updates)
 * @returns true if there is an overlap, false otherwise
 */
export function checkDateRangeOverlapWithMany(
	newRange: DateRangeWithOptionalEndDate,
	existingRanges: Array<DateRangeWithOptionalEndDate & { id?: any }>,
	excludeId?: any,
): boolean {
	return existingRanges.some(existing => {
		// Skip the item being updated
		if (excludeId !== undefined && existing.id === excludeId) {
			return false;
		}

		return doDateRangesWithOptionalEndDatesOverlap(existing, newRange);
	});
}

