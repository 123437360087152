import { addDays, endOfWeek, isSameDay, isWeekend } from "date-fns";
import type { FunctionComponent } from "react";

import Headline from "~/components/Headline/Headline.tsx";
import type { Absence } from "~/modules/absence/api/absence/absenceTypes.ts";
import type { AbsenceType } from "~/modules/absence/api/absenceType/absenceTypeTypes.ts";
import type { Holiday } from "~/modules/absence/api/holiday/holidayTypes.ts";
import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import Day from "~/modules/timeTracking/components/components/Day";
import TimeTrackingTotals from "~/modules/timeTracking/components/components/TimeTrackingTotals";
import { calculateTimeTrackingTotals } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";

type WeekProps = {
	allAbsenceTypes?: AbsenceType[];
	days: Date[];
	holidays?: Holiday[];
	monthIsClosed: boolean;
	setScrollToElementRef: (ref: HTMLDivElement) => void;
	setSelectedDate: (date: Date | null) => void;
	setSelectedTimeTrackingId: (id: string | null) => void;
	setTimeTrackingIdToUpdate: (id: string | null) => void;
	selectedTimeTrackingId: string | null;
	selectedDate: Date | null;
	showOpenDaysOnly: boolean;
	showTotalSumOnly: boolean;
	timeTrackingData?: TimeTrackingExtendedType[];
	userAbsencesData?: Absence[];
	users: User[];
	visible: boolean;
	week: number;
};

const Week: FunctionComponent<WeekProps> = ({
	allAbsenceTypes,
	days,
	holidays,
	monthIsClosed,
	setScrollToElementRef,
	setSelectedDate,
	setSelectedTimeTrackingId,
	setTimeTrackingIdToUpdate,
	selectedDate,
	selectedTimeTrackingId,
	showOpenDaysOnly,
	showTotalSumOnly,
	timeTrackingData,
	userAbsencesData,
	users,
	visible,
	week,
}) => {
	const weekdays = visible ? days : days.filter((day) => !isWeekend(day));
	if (weekdays.length === 0) return null;

	// days could come sorted asc or desc
	const firstDayOfWeek = weekdays.sort((a, b) => a.getTime() - b.getTime())[0];

	const totals = calculateTimeTrackingTotals({
		endDate: addDays(endOfWeek(firstDayOfWeek), 1),
		startDate: firstDayOfWeek,
		timeTrackingData: timeTrackingData || [],
	});

	return (
		<div>
			<div className="flex justify-between">
				<Headline className="mb-4 pl-4"
						  type="h4">KW {week}</Headline>
				<div className="mb-2 flex self-end pr-4">
					<TimeTrackingTotals totals={totals}
										showTotalSumOnly={showTotalSumOnly} />
				</div>
			</div>
			<div className="mb-10 overflow-hidden rounded-2xl bg-white empty:mb-0">
				{days.map((date) => {
					const daysTimeTrackings = timeTrackingData?.filter((timeTracking) =>
						isSameDay(new Date(timeTracking.date), date),
					);
					return (
						<Day
							allAbsenceTypes={allAbsenceTypes}
							date={date}
							holidays={holidays}
							key={date.toString()}
							monthIsClosed={monthIsClosed}
							setScrollToElementRef={setScrollToElementRef}
							setSelectedDate={setSelectedDate}
							setSelectedTimeTrackingId={setSelectedTimeTrackingId}
							setTimeTrackingIdToUpdate={setTimeTrackingIdToUpdate}
							selectedDate={selectedDate}
							selectedTimeTrackingId={selectedTimeTrackingId}
							showOpenDaysOnly={showOpenDaysOnly}
							timeTrackingData={daysTimeTrackings}
							userAbsencesData={userAbsencesData}
							users={users}
							visible={visible}
						/>
					);
				})}
			</div>
		</div>
	);
};
export default Week;
