import type { ElementType, PropsWithChildren } from "react";

import type {
	StatusBorderRadiusVariants,
	StatusSizeVariants,
	StatusThemeVariants} from "~/components/statusDisplay/Status/statusVariants.ts";
import {
	statusVariants,
} from "~/components/statusDisplay/Status/statusVariants.ts";
import { getWorkingStatusNameById, getWorkingStatusThemeById } from "~/modules/project/utils/projectUtils.ts";

interface BaseStatusProps {
	borderRadius?: StatusBorderRadiusVariants;
	theme?: StatusThemeVariants;
	size?: StatusSizeVariants;
	as?: ElementType;
}

type StatusProps = BaseStatusProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Status: React.FC<PropsWithChildren<StatusProps>> = ({
	borderRadius = 'default',
	children,
	theme = 'default',
	size = 'default',
	as = 'div',
	...rest
}) => {
	const Component: ElementType = as;

	return (
		<Component
			className={statusVariants({ borderRadius, size, theme })}
			{...rest}
		>
			{children}
		</Component>
	);
};

export default Status;

interface WorkingStatusProps extends BaseStatusProps {
	id: string;
}

export const WorkingStatus: React.FC<PropsWithChildren<WorkingStatusProps & React.ButtonHTMLAttributes<HTMLButtonElement>>> = ({
	id,
	borderRadius,
	size = 'default',
	as,
	...rest
}) => {
	const theme = getWorkingStatusThemeById(id);
	const statusName = getWorkingStatusNameById(id);

	return (
		<Status
			borderRadius={borderRadius}
			size={size}
			theme={theme}
			as={as}
			{...rest}
		>
			{statusName}
		</Status>
	);
};
