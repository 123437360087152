import { useEffect, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import Headline from "~/components/Headline/Headline.tsx";
import StatusIndicatorDot from "~/components/statusDisplay/StatusIndicatorDot";
import TabBar from "~/components/TabBar";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import { WorkingStatusId } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

import OrderDetails from "../OrderDetails";

interface OrdersProps {
	allUsers: User[];
	clientId: string;
	onNewOrderClick: () => void;
	phasesOrders: Order[];
	projectPhaseId: string;
	projectRoles: ProjectRole[];
	staffings: Staffing[];
	showBudget: boolean;
}

const Orders: React.FC<OrdersProps> = ({
		allUsers,
		clientId,
		onNewOrderClick,
		phasesOrders,
		projectRoles,
		staffings,
		showBudget,
	}) => {
		const [selectedOrderId, setSelectedOrderId] = useState<string>();

		const tabs = useMemo(() => {
			return phasesOrders.sort(byObjectProperty("id")).map((order) => {
				return {
					label: order.title,
					id: order.id,
					icon:
						<StatusIndicatorDot statusId={order.confirmedAt ? WorkingStatusId.Active : WorkingStatusId.Closed} />,
				};
			});
		}, [phasesOrders]);

		useEffect(() => {
			if (phasesOrders.length > 0 && !selectedOrderId) {
				const ordersSorted = phasesOrders.sort(byObjectProperty("id"));
				if (ordersSorted[0]) {
					setSelectedOrderId(ordersSorted[0].id);
				}
			}
		}, [phasesOrders, selectedOrderId]);

		const selectedOrderData = phasesOrders.find((order) => order.id === selectedOrderId);
		const selectedOrdersStaffings = staffings.filter((s) => selectedOrderId === s.orderId);
		const hasMultipleOrders = phasesOrders.length > 1;

		return (
			<div className="flex flex-col">

				<div className="flex items-center justify-between">
					<Headline type="h3">{hasMultipleOrders ? "Bestellungen" : "Bestellung"} & Staffing</Headline>
					<Button size="sm"
							theme="secondary"
							onClick={onNewOrderClick}>Neue Bestellung</Button>
				</div>
				{phasesOrders.length > 1 ? <div className="my-4"><TabBar tabs={tabs}
												   activeTabId={selectedOrderId || ""}
														onTabClick={setSelectedOrderId} /></div> :
					<div className="h-4" />
				}
				{selectedOrderData && selectedOrdersStaffings !== undefined && (
					<OrderDetails
						allUsers={allUsers}
						clientId={clientId}
						key={selectedOrderData.id}
						orderData={selectedOrderData}
						projectRoles={projectRoles}
						showBudget={showBudget}
						staffings={selectedOrdersStaffings}
						totalOrders={phasesOrders.length}
					/>
				)}

			</div>
		);
	}
;

export default Orders;
