import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { Changelog, CreateOrUpdateChangelogData } from "~/modules/changelog/api/changelog/changelogTypes.ts";

const changelogBasePath = "/changelog";

export function indexChangelogs(): AxiosApiResponsePromise<Changelog[]> {
	return axiosApi.get(changelogBasePath);
}

export function createChangelog(data: CreateOrUpdateChangelogData): AxiosApiResponsePromise<void> {
	return axiosApi.post(changelogBasePath, data);
}

export function updateChangelog(id: string, data: CreateOrUpdateChangelogData): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${changelogBasePath}/${id}`, data);
}

export function deleteChangelog(id: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${changelogBasePath}/${id}`);
}

