export type ProjectsExcelSheetData = {
	budgetCents: number;
	budgetCentsActual: number;
	budgetDays: number;
	budgetDaysActual: number;
	clientDisplayName: string;
	companyDisplayName: string;
	isActive: string;
	projectTitle: string;
	startDate: Date;
	endDate: Date;
}


export const monthlyReportTableColumnDefs = [
	{
		accessor: "isActive",
		header: "Aktiv",
		type: "string",
	},
	{
		accessor: "clientDisplayName",
		header: "Kunde",
		type: "string",
	},
	{
		accessor: "companyDisplayName",
		header: "Firma",
		type: "string",
	},
	{
		accessor: "projectTitle",
		header: "Projekt",
		type: "string",
	},
	{
		accessor: "startDate",
		header: "Startdatum",
		type: "date",
	},
	{
		accessor: "endDate",
		header: "Enddatum",
		type: "date",
	},
	{
		accessor: "budgetDays",
		header: "Budget Tage",
		type: "number",
	},
	{
		accessor: "budgetDaysActual",
		header: "Budget Tage vefügt",
		type: "number",
	},
	{
		accessor: "budgetCents",
		header: "Budget",
		type: "currency",
	},
	{
		accessor: "budgetCentsActual",
		header: "Budget verfügt",
		type: "currency",
	},
];