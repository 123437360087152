import clsx from "clsx";
import Status from "components/statusDisplay/Status";

import CompanyLogo from "~/components/CompanyLogo";
import Headline from "~/components/Headline/Headline.tsx";
import Hint from "~/components/Hint";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import type { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";

interface ProjectsProps {
	usersActiveStaffings: UsersActiveStaffingType[];
	disabledStaffingIds: string[];
	value: string | null;
	onChange: (staffingId: string) => void;
}

const Projects: React.FC<ProjectsProps> = ({ usersActiveStaffings, disabledStaffingIds, onChange }) => {
	const { data: allCompanyAvatarImages } = useAllCompanyAvatarImages();

	return (
		<div className="grid gap-8">
			{usersActiveStaffings.length > 0 ? (
				usersActiveStaffings.map(({ id, projectPhases, title, projectNumber, clientId }) => {
					const avatarImage = allCompanyAvatarImages?.find((image) => image.clientIds.includes(clientId));
					return <div key={`project-${id}`}
								className="flex flex-col gap-1">
						<div className="mb-1 flex items-center gap-x-2">
							{avatarImage && <CompanyLogo image={avatarImage} width="w-6" height="h-6"/> }
							<Headline type="h4">
								{title}
							</Headline>
							<div className="ml-auto">
								<Status>{projectNumber}</Status>
							</div>
						</div>
						<div className="flex flex-col flex-wrap justify-start gap-y-2 rounded-lg bg-gray-100 p-2">
							{projectPhases.map((phase) => {
								return phase.orders.map((order) => {
									const staffingId = order.staffings[0].id;
									const isDisabled = disabledStaffingIds.includes(staffingId);
									return (
										<button key={`order-${order.id}`}
												className={clsx("group divide-y divide-gray-100 rounded-md bg-white px-2 text-primary-500 hover:bg-gray-50", isDisabled && "pointer-events-none opacity-50")}
												onClick={onChange.bind(
													this,
													staffingId,
												)}
										>
											<div className="grid grid-cols-6 gap-4 py-2 text-left">
												<dt className="text-right text-sm text-gray-500 ">Phase</dt>
												<dd className="col-span-5 text-sm font-bold">{phase.title}</dd>
											</div>
											<div className="grid grid-cols-6 gap-4 py-2 text-left">
												<dt className="text-right text-sm text-gray-500">
													Bestellung
												</dt>
												<dd className="col-span-5 text-sm font-bold">
													<div
														className="flex flex-row gap-1"
													>
														{order.title}
													</div>
												</dd>
											</div>
											{isDisabled &&
												<div className="rounded bg-accent-500 py-1 text-sm">Die Zeiterfassung
													für diese Bestellung ist an diesem Tag bereits erfolgt.</div>}
										</button>
									);
								});
							})}
						</div>
					</div>;
				})
			) : (
				<Hint size="sm">Du hast derzeit keine aktiven Staffings / Bestellungen.</Hint>
			)}
		</div>
	);
};

export default Projects;
