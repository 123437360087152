import { Switch as BaseSwitch } from "@headlessui/react";
import type { InputHTMLAttributes } from "react";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import type { SwitchVariantsProps } from "~/components/formElements/Switch/switchVariants.ts";
import { switchVariants } from "~/components/formElements/Switch/switchVariants.ts";


type SharedSwitchProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & SwitchVariantsProps & {
	label?: string;
	labelOn?: string;
	labelOff?: string;
	onChange?(checked: boolean): void;
}

type SwitchProps = SharedSwitchProps & {
	control: Control<any>;
	name: string;
}

export const SwitchPlain: React.FunctionComponent<SharedSwitchProps> = ({
	checked,
	focusRing,
	inputSize,
	label,
	labelOff,
	labelOn,
	name,
	onChange,
	showAllLabels,
	reverseLabels,
	textColor,
}) => {

	const {
		button,
		indicator,
		labelOn: labelOnVariants,
		labelOff: labelOffVariants,
		container,
	} = switchVariants(
		{ checked, inputSize, focusRing, reverseLabels, showAllLabels, textColor });

	return (
		<BaseSwitch.Group as="div"
						  className={container()}>
			{labelOff && <BaseSwitch.Label
				as="span"
				className={labelOffVariants()}
			>
				{labelOff}
			</BaseSwitch.Label>}
			<BaseSwitch
				checked={checked}
				onChange={onChange}
				name={name}
				className={button()}>
					<span
						aria-hidden="true"
						className={indicator()}
					/>
			</BaseSwitch>
			{(label || (labelOn && labelOff)) && (
				<BaseSwitch.Label as="span"
								  className={labelOnVariants()}>
					{labelOn && labelOff ? (checked ? labelOn : showAllLabels ? labelOn : labelOff) : label}
				</BaseSwitch.Label>
			)}
		</BaseSwitch.Group>);

};


const Switch: React.FunctionComponent<SwitchProps> = ({ name, control, ...props }) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => {
				return <SwitchPlain name={name}
									checked={value === true}
									onChange={onChange}
									{...props}
				/>;
			}} />
	);
};

export default Switch;