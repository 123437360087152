import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { TabBarVariantName } from "~/components/TabBar/tabBarTypes.ts";

export const tabBarVariants = tv({
	slots: {
		outerWrapper: "border-b",
		tabWrapper:"-mb-px flex space-x-8",
	},
	variants: {
		theme: {
			[TabBarVariantName.Default]: "bg-white text-black",
			[TabBarVariantName.Light]: "bg-gray-100 text-gray-600",
		},
	},
	defaultVariants: {
		theme: "default",
	},
});


export type TabBarVariants = VariantProps<typeof tabBarVariants>;
