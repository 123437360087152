import { useQueryClient } from "@tanstack/react-query";
import { startOfDay } from "date-fns";
import { useCallback, useMemo } from "react";

import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { All_LOCATIONS_QUERY_KEY, USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { PROJECT_ROLES_BY_PROJECT_QUERY_KEY } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import CreateAdditionalStaffingForUserForm
	from "~/modules/project/components/forms/CreateAdditionalStaffingForUserForm";
import type {
	StaffingsTableData,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	allStaffings: StaffingsTableData[];
	isOpen: boolean;
	onClose: () => void;
	orderData: Order;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
	userId: string | null;
};

const CreateAdditionalStaffingForUserSidebar: React.FunctionComponent<Props> = ({
	allStaffings,
	isOpen,
	onClose,
	orderData,
	totalBudgetUnassigned,
	totalManDaysUnassigned,
	userId,
}) => {
	const { user } = useAuth();
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(async (staffedUserId: string) => {
		await queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECTS_PHASES_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: All_LOCATIONS_QUERY_KEY });
		await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(orderData.projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_ROLES_BY_PROJECT_QUERY_KEY(orderData.projectId) });
		if (user && staffedUserId === user.id) {
			queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user.id) });
			queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(user.id) });
			queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(user.id) });
		}
		onClose();
	}, [onClose, orderData.projectId, queryClient, user]);

	const usersStaffings = useMemo(() => {
		if (allStaffings && userId) {
			return allStaffings.filter(staffing => staffing.userId === userId).map(staffing => ({
				...staffing,
				startDate: startOfDay(staffing.startDate),
				endDate: staffing.endDate ? startOfDay(staffing.endDate) : null,
			})).sort(byObjectProperty("startDate"));
		}
		return [];
	}, [allStaffings, userId]);

	const {
		data: allUsersData,
	} = useAllUsers();

	const staffedUserData = useMemo(() => {
		if (allUsersData && userId) {
			return allUsersData.find(user => user.id === userId);

		}
		return null;
	}, [allUsersData, userId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{staffedUserData && <CreateAdditionalStaffingForUserForm onSuccess={handleSuccess}
																	 onCancel={onClose}
																	 orderData={orderData}
																	 staffedUserData={staffedUserData}
																	 totalBudgetUnassigned={totalBudgetUnassigned}
																	 totalManDaysUnassigned={totalManDaysUnassigned}
																	 usersStaffings={usersStaffings}
			/>}
		</Sidebar>
	);
};

export default CreateAdditionalStaffingForUserSidebar;
