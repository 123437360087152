import type { Control, FieldErrors, FieldValues, Path, UseFormWatch } from "react-hook-form";

import FormInputDisplayOnly from "~/components/formElements/FormInputDisplayOnly";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Label from "~/components/formElements/Label";
import OrdersRemainingBudgetFormSection
	from "~/modules/project/components/forms/formSections/OrdersRemainingBudgetFormSection";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type UpdateStaffingBudgetFormSectionProps<T extends FieldValues> = {
	control: Control<T>;
	dailyRateCents: number;
	errors: FieldErrors<T>;
	placeholder?: string;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
	watch: UseFormWatch<T>;

};

type UpdateStaffingBudgetFormSectionType = {
	manDays: number;
};

const StaffingBudgetFormSectionLimited = <T extends UpdateStaffingBudgetFormSectionType>({
	control,
	dailyRateCents,
	totalManDaysUnassigned,
	totalBudgetUnassigned,
	watch,

}: UpdateStaffingBudgetFormSectionProps<T>) => {
	const manDays = watch("manDays" as Path<T>);
	const totalCents = manDays * dailyRateCents;
	const totalRemainingBudget = totalBudgetUnassigned - totalCents;
	const totalRemainingManDays = totalManDaysUnassigned - manDays;

	return (
		<><FormSection title="Kommerzielle Daten">
			<div className="grid grid-cols-2 gap-x-6 gap-y-2">
				<Input
					name="manDays"
					control={control}
					label="Personentage"
					type={"number"}
				/>

				<FormInputDisplayOnly label="Tagessatz (EUR)"
									  value={formatCentsToCurrency(dailyRateCents)} />

				<div>
					<Label>Summe</Label>
					<div className="text-sm">{formatCentsToCurrency(totalCents)}</div>
				</div>

			</div>
		</FormSection>
			<OrdersRemainingBudgetFormSection totalRemainingBudget={totalRemainingBudget}
											  totalRemainingManDays={totalRemainingManDays} />
		</>
	);
};

export default StaffingBudgetFormSectionLimited;
