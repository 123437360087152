import { useQueryClient } from "@tanstack/react-query";
import {
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	type SortingState,
	useReactTable,
} from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useCallback, useState } from "react";

import Portal from "~/components/Portal";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import Table from "~/components/Table";
import { useAuth } from "~/contexts/AuthContext";
import { USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { updateStaffingActivity } from "~/modules/project/api/staffing/staffingApiDispatchers";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries";
import {
	staffingsTableColumnDefs,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableColumnDefs.tsx";
import type {
	HandleActivityClickProps,
	StaffingsTableData,
	StaffingsTableMeta,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import {
	staffingStatsPortalId,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingTableUtils.ts";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";

type Props = {
	showIneffectiveStaffings: boolean;
	onAddAdditionalStaffingClick: StaffingsTableMeta["onAddAdditionalStaffingClick"];
	onDeleteStaffingClick: StaffingsTableMeta["onDeleteStaffingClick"];
	onUpdateStaffingClick: StaffingsTableMeta["onUpdateStaffingClick"];
	tableData: StaffingsTableData[];
	projectId: string
	showBudget: boolean;

};

const StaffingsTable: FunctionComponent<Props> = ({
	showIneffectiveStaffings,
	onAddAdditionalStaffingClick,
	onDeleteStaffingClick,
	onUpdateStaffingClick,
	showBudget,
	tableData,
	projectId,
}) => {
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const [isUpdatingActivity, setIsUpdatingActivity] = useState(false);
	const [activityUpdateFailed, setActivityUpdateFailed] = useState(false);
	const [sorting, setSorting] = useState<SortingState>([{ id: "userFullName", desc: false }]);

	const handleActivityClick = useCallback(async ({ staffingId, newStatus, userId }: HandleActivityClickProps) => {
		setIsUpdatingActivity(true);

		try {
			await updateStaffingActivity({ staffingId, projectId, isActive: newStatus });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
			if (user && user.id === userId) {
				queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user!.id) });
				queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(user!.id) });
				queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(user!.id) });
			}

		} catch (error) {
			setActivityUpdateFailed(true);
		}

		setIsUpdatingActivity(false);
	}, [projectId, queryClient, user]);

	const columnFilters = useMemo(() => {
		const value = [true];
		if (showIneffectiveStaffings) {
			value.push(false);
		}
		return [{ id: "isCurrent", value }];
	}, [showIneffectiveStaffings]);

	const table = useReactTable({
		data: tableData,
		columns: staffingsTableColumnDefs,
		state: {
			sorting,
			columnFilters,
		},
		meta: {
			handleActivityClick,
			onAddAdditionalStaffingClick,
			onDeleteStaffingClick,
			onUpdateStaffingClick,
			showBudget,
			emptyTableDataMessage: "Es existieren noch keine Staffings für diese Bestellung.",
		} as StaffingsTableMeta,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return <div className="relative">
		{isUpdatingActivity && <SidebarBusyOverlay showSpinner={true} />}
		{activityUpdateFailed &&
			<div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-100/80">
				<div className="w-full bg-red-400 p-2 text-center text-sm text-white">Status
					Update fehlgeschlagen
				</div>
			</div>}
		<Table table={table} /><Portal targetId={staffingStatsPortalId} />
	</div>;
};

export default StaffingsTable;