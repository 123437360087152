import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { t } from "i18next";
import StaffingBudgetFormSectionAllAttributes
	from "modules/project/components/forms/formSections/StaffingBudgetFormSectionAllAttributes";
import StaffingBudgetFormSectionLimited
	from "modules/project/components/forms/formSections/StaffingBudgetFormSectionLimited";
import UsersOtherStaffingsInOrderFormSection
	from "modules/project/components/forms/formSections/UsersOtherStaffingsInOrderFormSection";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Avatar from "~/components/Avatar";
import Button from "~/components/buttons/Button/Button.tsx";
import ComboBox from "~/components/formElements/ComboBox";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Switch from "~/components/formElements/Switch";
import TimingFormSection from "~/components/formSections/TimingFormSection";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import useCareerLevelOptions from "~/hooks/form/formOptionsData/useCareerLevelOptions.ts";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import { updateStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import type {
	StaffingsTableData,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { doDateRangesWithOptionalEndDatesOverlap, formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type UpdateStaffingFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffedUserData: User;
	staffingData: Staffing;
	staffingIsEditable: boolean;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
	usersOtherStaffings: StaffingsTableData[];
};

type UpdateStaffingFormData = {
	careerLevelId: string,
	dailyRateCents: number,
	endDate: Date | null
	isActive: boolean;
	isBillable: boolean;
	manDays: number;
	projectRoleName: string;
	startDate: Date;
}

const UpdateStaffingForm: React.FC<UpdateStaffingFormProps> = ({
	onSuccess,
	onCancel,
	staffedUserData,
	staffingData,
	staffingIsEditable,
	totalManDaysUnassigned,
	totalBudgetUnassigned,
	usersOtherStaffings,
}) => {
	const [busy, setBusy] = useState(false);
	const [overlappingStaffingIds, setOverlappingStaffingIds] = useState<string[]>([]);
	const careerLevelSelectOptions = useCareerLevelOptions();
	const projectRoleSelectOptions = useProjectsProjectRolesOptions({
		projectId: staffingData.projectId,
		valueProperty: "displayName",
	});
	const { data: projectRolesData } = useProjectProjectRoles(staffingData.projectId);

	const schema = useMemo(() => {
		return yup.object({
			careerLevelId: yup.string().default(null).required(),
			dailyRateCents: yup.number().required(),
			isActive: yup.boolean().required(),
			isBillable: yup.boolean().required(),
			endDate: yup
				.date()
				.transform(transformEmptyDateValueToNull)
				.nullable()
				.default(null)
				.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
			manDays: yup.number().typeError(t("form:validationErrors.number.requiredTypeError")).required(),
			projectRoleName: yup.string().required(),
			startDate: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const projectRoleDisplayName = projectRolesData?.find((projectRole) => projectRole.id === staffingData.projectRoleId)?.displayName || "";

		return {
			careerLevelId: staffingData.careerLevelId ? staffingData.careerLevelId as string : staffedUserData.careerLevelId as string,
			dailyRateCents: staffingData.dailyRateCents,
			endDate: staffingData.endDate ? new Date(staffingData.endDate) : null,
			isActive: staffingData.isActive,
			isBillable: staffingData.isBillable,
			manDays: staffingData.manDays,
			projectRoleName: projectRoleDisplayName,
			startDate: new Date(staffingData.startDate),
		};
	}, [projectRolesData,
		staffingData.careerLevelId,
		staffingData.dailyRateCents,
		staffingData.endDate,
		staffingData.isActive,
		staffingData.isBillable,
		staffingData.manDays,
		staffingData.startDate,
		staffingData.projectRoleId,
		staffedUserData.careerLevelId]);

	const originalAssignedBudget = staffingData.dailyRateCents * staffingData.manDays;

	const totalBudgetUnassignedWithCurrentStaffing = totalBudgetUnassigned + originalAssignedBudget;
	const totalManDaysUnassignedWithCurrentStaffing = totalManDaysUnassigned + staffingData.manDays;

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty, isSubmitted },
		setValue,
		watch,
	} = useForm<UpdateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateStaffingFormData>(schema),
		mode: "onChange",
	});
	const startDate = watch("startDate");
	const endDate = watch("endDate");

	useEffect(() => {
		const overlappingStaffings = usersOtherStaffings.filter((staffing) => {
			return doDateRangesWithOptionalEndDatesOverlap(staffing, { startDate, endDate });
		}).map((staffing) => staffing.id);
		setOverlappingStaffingIds(overlappingStaffings);
	}, [startDate, endDate, usersOtherStaffings]);

	const formIsSubmittable = useFormIsSubmittable({
		enableInitially: false,
		isSubmitted,
		isValid,
		isLoading: busy,
		isDirty,
	});

	const onSubmit: SubmitHandler<UpdateStaffingFormData> = async (data: UpdateStaffingFormData) => {
		setBusy(true);

		const staffingUpdateData = {
			...data,
			projectId: staffingData.projectId,
			orderId: staffingData.orderId,
			startDate: formatDateToYYYYMMDD(data.startDate),
			endDate: data.endDate ? formatDateToYYYYMMDD(data.endDate) : null,
		};

		try {
			await updateStaffing({
				projectId: staffingData.projectId,
				staffingId: staffingData.id,
				staffingData: staffingUpdateData,
			});
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	const userCareerLevelId = staffingData.careerLevelId ? staffingData.careerLevelId : staffedUserData.careerLevelId;
	const careerLevelDisplayName = userCareerLevelId ? getCareerLevelDisplayNameById(userCareerLevelId) : EMPTY_VALUE_PLACEHOLDER;

	const roleSectionTitle = staffingIsEditable ? "Karrierestufe / Rolle" : "Rolle";

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Staffing bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			{!staffingIsEditable &&
				<div className="sticky bg-gray-400 px-7 py-1 text-sm text-white">Da mit diesem Staffing bereits abgerechnete
					Erfassungen verknüpft sind, kann es nur noch eingeschränkt bearbeitet werden.</div>}
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Projektmitarbeiter:in">
						<div className="flex items-center justify-between">
							<div className="flex items-center">
								<Avatar firstName={staffedUserData.firstName}
										lastName={staffedUserData.lastName}
										image={staffedUserData.avatarImage}
								/>
								<div className="ml-2 flex flex-col items-start">
									<div className="font-semibold">{staffedUserData.fullName}</div>
									<div className="-mt-0.5 text-sm">{careerLevelDisplayName}</div>
								</div>
							</div>
							<Switch name={"isActive"}
									control={control}
									labelOn={"Zeiterfassung aktiv"}
									labelOff={"Zeiterfassung deaktiviert"} />
						</div>
					</FormSection>
					<FormSection title={roleSectionTitle}>
						<div className="grid grid-cols-2 gap-x-6 gap-y-2">
							{staffingIsEditable &&
								<Select name={"careerLevelId"}
										label="Karrierstufe (Staffing)"
										control={control}
										optionsData={careerLevelSelectOptions} />}
							<div className={clsx(staffingIsEditable ? "col-span-1" : "col-span-2")}>
								<ComboBox
									label={staffingIsEditable ? "Rolle im Projekt" : undefined}
									placeholder="Name der Rolle"
									name={"projectRoleName"}
									control={control}
									optionsData={projectRoleSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					{staffingIsEditable && <StaffingBudgetFormSectionAllAttributes watch={watch}
																				   control={control}
																				   errors={errors}
																				   totalBudgetUnassigned={totalBudgetUnassignedWithCurrentStaffing}
																				   totalManDaysUnassigned={totalManDaysUnassignedWithCurrentStaffing} />}
					{!staffingIsEditable && staffingData.isBillable &&
						<StaffingBudgetFormSectionLimited control={control}
														  dailyRateCents={staffingData.dailyRateCents}
														  errors={errors}
														  totalBudgetUnassigned={totalBudgetUnassignedWithCurrentStaffing}
														  totalManDaysUnassigned={totalManDaysUnassignedWithCurrentStaffing}
														  watch={watch} />}
					{!staffingIsEditable && !staffingData.isBillable && <FormSection title="Kommerzielle Daten">
						<div className="-mt-0.5 text-sm">Nicht abrechenbar</div>
					</FormSection>}
					<TimingFormSection<UpdateStaffingFormData> control={control}
															   autoUpdateMissingValues={false}
															   startDateIsClearable={false}
															   errors={errors}
															   title="Gültigkeit"
															   watch={watch}
															   setValue={setValue} />

					<UsersOtherStaffingsInOrderFormSection overlappingStaffingIds={overlappingStaffingIds}
														   usersOtherStaffings={usersOtherStaffings} />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && (!isValid || overlappingStaffingIds.length > 0)}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || overlappingStaffingIds.length > 0}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateStaffingForm;
