import clsx from "clsx";
import type { FunctionComponent } from "react";

import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import TimeTrackingTotal
	from "~/modules/timeTracking/components/components/TimeTrackingTotals/components/TimeTrackingTotal";
import type { TimeTrackingTotalsByType } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { sumUp } from "~/utils/sumUp.ts";

type Props = {
	className?: string;
	totals: TimeTrackingTotalsByType,
	showTotalSumOnly: boolean,
	textSize?: "xs" | "sm",
	useShortLabels?: boolean,
};

const TimeTrackingTotals: FunctionComponent<Props> = ({
	className,
	totals,
	showTotalSumOnly,
	textSize = "xs",
	useShortLabels = true,
}) => {
	return <div className={clsx("flex", className)}>{!showTotalSumOnly && <>
		<TimeTrackingTotal theme="project"
						   label={useShortLabels ? "P" : "Projekt"}
						   textSize={textSize}
						   minutes={totals[TimeTrackingTypeId.Project]} />
		<TimeTrackingTotal theme="sales"
						   label={useShortLabels ? "S" : "Sales"}
						   textSize={textSize}
						   minutes={totals[TimeTrackingTypeId.Sales]} />
		<TimeTrackingTotal theme="internal"
						   label={useShortLabels ? "I" : "Intern"}
						   textSize={textSize}
						   minutes={totals[TimeTrackingTypeId.Internal]} />
		<TimeTrackingTotal theme="partTime"
						   label={useShortLabels ? "TZ" : "Teilzeit"}
						   textSize={textSize}
						   minutes={totals[TimeTrackingTypeId.PartTime]} />
	</>}
		<TimeTrackingTotal theme="sum"
						   label="&sum;"
						   textSize={textSize}
						   minutes={sumUp(Object.values(totals))}
						   showDot={false} />
	</div>;
};

export default TimeTrackingTotals;