import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { generatePath, NavLink } from "react-router-dom";

import ContentWrapper from "~/components/ContentWrapper";
import { PROJECT_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import { useProjectsOrders } from "~/modules/project/api/order/orderQueries.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import ProjectBudgetSection
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewContent/components/ProjectBudgetSection";
import ProjectDetailsSection
	from "~/modules/project/components/ProjectDetailsView/components/ProjectOverviewContent/components/ProjectDetailsSection";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	allUsers: User[];
	client: Client;
	company: Company;
	phases: ProjectPhase[];
	projectData: Project;
	projectHasStaffings: boolean;
	showBudget: boolean;
};
const ProjectOverviewContent: FunctionComponent<Props> = ({
	allUsers,
	client,
	company,
	projectData,
	projectHasStaffings,
	showBudget,
}) => {
	const {
		budgetCentsPlanned,
		budgetCentsTracked,
		clientId,
		endDate,
		managedBy,
		manDaysPlanned,
		minutesTracked,
		startDate,
	} = projectData;

	const {
		data: ordersData,
	} = useProjectsOrders(projectData.id);

	const {
		data: phasesData,
	} = useProjectsProjectPhases(projectData.id);

	const manDaysTracked = minutesToWorkdays(minutesTracked);
	const manDaysPlannedInt = parseInt(manDaysPlanned);

	const totalProjectBudgetCents = budgetCentsPlanned;

	const totalProjectManDaysBudget = manDaysPlannedInt;

	const projectLead = allUsers.find((u) => managedBy === u.id)?.fullName || "n/a";
	const clientDisplayName = client.displayName;
	const companyDisplayName = company.displayName;

	const overbookingWarning = useMemo(() => {
		if (ordersData && phasesData) {
			const overbookedOrders = ordersData.filter(order => order.budgetCents < order.budgetCentsTracked);
			const overbookedOrdersCount = overbookedOrders.length;
			if (overbookedOrdersCount === 0) return null;
			const overbookedOrderLinks = overbookedOrders.map(order => {
				const phase = phasesData.find(phase => phase.id === order.projectPhaseId)!;
				const percentageOverbooked = Math.round((order.budgetCentsTracked / order.budgetCents) * 100);
				const percentageOverbookedFormatted = percentageOverbooked !== Infinity ? formatNumberWithComma(percentageOverbooked - 100, 2, true) + "%" : "";
				const centsOverbooked = order.budgetCentsTracked - order.budgetCents;
				return <NavLink key={order.id}
								to={generatePath(PROJECT_DETAILS_PATH_WITH_TAB_ID, {
									projectId: projectData.id,
									tabId: "phase-" + phase.phaseNumber,
								})}
								className="flex flex-wrap items-center gap-x-2 rounded-lg bg-yellow-50 px-4 py-2 text-yellow-800 shadow hover:bg-yellow-100">
					<ExclamationTriangleIcon className="mt-0.5 size-5" /><span className="whitespace-pre text-sm">Kostenvolumen einer Bestellung aus Phase {phase.phaseNumber} überbucht (+{formatCentsToCurrency(centsOverbooked)}{percentageOverbookedFormatted && `  / ${percentageOverbookedFormatted}`}).</span>
				</NavLink>;
			});

			return <div className="col-span-2 flex flex-col gap-y-2">
				{overbookedOrderLinks}
			</div>;
		}
		return null;
	}, [ordersData, phasesData, projectData.id]);


	return <ContentWrapper className="w-full overflow-y-auto pb-10 pt-4">
		<div className="grid grid-cols-2 gap-4">
			{overbookingWarning}
			<div className="flex flex-col gap-y-4">
				<ProjectDetailsSection companyDisplayName={companyDisplayName}
									   clientDisplayName={clientDisplayName}
									   clientId={clientId}
									   endDate={endDate}
									   projectData={projectData}
									   projectHasStaffings={projectHasStaffings}
									   projectLead={projectLead}
									   startDate={startDate} />

			</div>
			<div className="flex flex-col gap-y-4">
				<ProjectBudgetSection budgetCentsPlanned={budgetCentsPlanned}
									  budgetCentsTracked={budgetCentsTracked}
									  manDaysPlanned={manDaysPlannedInt}
									  manDaysTracked={manDaysTracked}
									  totalBudgetCents={totalProjectBudgetCents}
									  totalManDays={totalProjectManDaysBudget}
									  showBudget={showBudget} />
			</div>


		</div>

	</ContentWrapper>;
};

export default ProjectOverviewContent;