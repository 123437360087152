import type { FunctionComponent } from "react";

import FormSection from "~/components/formElements/FormSection";
import Label from "~/components/formElements/Label";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type Props = {
	totalRemainingBudget: number;
    totalRemainingManDays: number;
};

const OrdersRemainingBudgetFormSection: FunctionComponent<Props> = ({
	totalRemainingBudget,
	totalRemainingManDays
}) => {
	return <FormSection title="Verbleibendes Budget Bestellung">
		<div className="grid grid-cols-2 gap-x-6 text-sm">
			<div>
				<Label>Budget</Label>
				<div className={totalRemainingBudget < 0 ? "text-red-600" : ""}>{formatCentsToCurrency(totalRemainingBudget)}</div>
			</div>
			<div>
				<Label>Personentage</Label>
				<div className={totalRemainingManDays < 0 ? "text-red-600" : ""}>{totalRemainingManDays.toFixed(0)}</div>
			</div>
		</div>
	</FormSection>;
};

export default OrdersRemainingBudgetFormSection;