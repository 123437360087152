import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { t } from "i18next";
import type { ForwardedRef } from "react";
import { forwardRef, useCallback, useState } from "react";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import FormInputError from "~/components/formElements/FormInputError";
import Label from "~/components/formElements/Label";
import type { FormInputOption } from "~/types/form.ts";

type SharedProps = FormInputBaseVariants & {
	disabled?: boolean;
	label?: string;
	error?: string;
	optionsData: FormInputOption[];
	defaultValue?: string | null;
};

type SelectPlainProps = SharedProps & {
	name?: string;
	value?: string | null;
	onChange?: (value: any) => void;
};

export const SelectPlain = forwardRef(
	(
		{
			defaultValue,
			disabled,
			error,
			inputSize,
			label,
			name,
			onChange,
			optionsData,
			theme,
			value,
		}: SelectPlainProps,
		ref: ForwardedRef<any>,
	) => {
		const [internalValue, setInternalValue] = useState<string | null>(defaultValue || null);

		const currentValue = value !== undefined ? value : internalValue;
		const setCurrentValue = onChange || setInternalValue;

		const getSelectedLabel = useCallback(
			(value: string | null | undefined): string => {
				if (!value) {
					return t("form:select.defaultOption");
				}

				return (
					optionsData?.find((option) => option.value === value)?.label || t("form:select.defaultOption")
				);
			},
			[optionsData],
		);

		return (
			<Listbox name={name}
					 value={currentValue}
					 onChange={setCurrentValue}
					 disabled={disabled}
					 ref={ref}>
				<FormInput>
					{label && <Label disabled={disabled}>{label}</Label>}
					<div className="relative">
						<ListboxButton
							className={clsx(formInputBaseVariants({ disabled, inputSize, theme }), "pr-10 text-left")}
						>
							<span className="block truncate">{getSelectedLabel(value)}</span>
							{!disabled && (
								<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
										<ChevronUpDownIcon className="size-5 text-gray-400"
														   aria-hidden="true" />
									</span>
							)}
						</ListboxButton>
						{error && <FormInputError>{error}</FormInputError>}
						{optionsData?.length > 0 && (
							<ListboxOptions
								transition
								className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
											text-sm
											opacity-100
											shadow-lg ring-1
											ring-black/5
											transition
											duration-100
											ease-in-out focus:outline-none data-[closed]:opacity-0
											"
							>
								{optionsData.map((option, index) => (
									<ListboxOption
										key={`${option.value}_${index}`}
										className={({ focus }) =>
											clsx(
												focus ? "bg-accent-500 text-white" : "text-gray-900",
												"relative cursor-default select-none py-2 pl-3 pr-9",
											)
										}
										value={option.value}
									>
										{({ selected, focus }) => (
											<>
														<span
															className={clsx(
																selected ? "font-semibold" : "font-normal",
																"block truncate",
															)}
														>
															{option.label}
														</span>
												{selected ? (
													<span
														className={clsx(
															focus ? "text-white" : "text-indigo-600",
															"absolute inset-y-0 right-0 flex items-center pr-4",
														)}
													>
																<CheckIcon className="size-5"
																		   aria-hidden="true" />
															</span>
												) : null}
											</>
										)}
									</ListboxOption>
								))}
							</ListboxOptions>
						)}
					</div>
				</FormInput>
			</Listbox>
		);
	},
);

SelectPlain.displayName = "SelectPlain";

type SelectProps = SharedProps & {
	name: string;
	control: any; // from react-hook-form
	rules?: any; // validation rules from react-hook-form
};

export const Select: React.FC<SelectProps> = ({ control, rules, defaultValue, name, ...props }) => {
	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});

	return <SelectPlain {...field} {...props} error={error?.message || props.error} />;
};

export default Select;
