import clsx from "clsx";
import type { FunctionComponent } from "react";

import FormInput from "~/components/formElements/FormInput";
import Label from "~/components/formElements/Label";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";

type Props = {
	label: string,
	value?: string,
	useValuePadding?: boolean,
};

const FormInputDisplayOnly: FunctionComponent<Props> = ({ label, value, useValuePadding=true,  }) => {
	return <FormInput>
		<Label>{label}</Label>
		<div className={clsx("text-sm text-gray-900", useValuePadding && "py-2.5")}>{value || EMPTY_VALUE_PLACEHOLDER}</div>
	</FormInput>;
};

export default FormInputDisplayOnly;