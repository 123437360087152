import Color from "@tiptap/extension-color";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { type ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant";
import Label from "~/components/formElements/Label";
import RichTextEditorToolbar from "~/components/RichTextEditor/components/RichTextEditorToolbar";
import { isEmptyContent } from "~/components/RichTextEditor/richTextEditorUtils.ts";

// Define available editor features
export type EditorFeatures = {
	bold?: boolean;
	italic?: boolean;
	underline?: boolean;
	headings?: boolean;
	strike?: boolean;
	bulletList?: boolean;
	orderedList?: boolean;
	color?: boolean;
};

const defaultFeatures: EditorFeatures = {
	bold: true,
	italic: true,
	underline: true,
	headings: true,
	strike: false,
	bulletList: true,
	orderedList: true,
	color: true,
};

type SharedProps = FormInputBaseVariants & {
	name: string;
	label?: string;
	error?: string;
	placeholder?: string;
	features?: EditorFeatures;
};

type RichTextEditorProps = SharedProps & {
	autoFocus?: boolean;
	value: string;
	onChange: (value: string) => void;
	onBlur?: () => void;
};

export const RichTextEditorPlain = forwardRef<HTMLDivElement, RichTextEditorProps>(
	({
			error,
			label,
			name,
			onBlur,
			onChange,
			value,
			placeholder = "Schreibe etwas...",
			features = defaultFeatures,
			autoFocus,
		}: RichTextEditorProps,
		ref: ForwardedRef<HTMLDivElement>,
	) => {

		const editor = useEditor({
			extensions: [
				StarterKit,
				Underline,
				Placeholder.configure({
					placeholder,
				}),
				TextStyle,
				Color.configure({
					types: ['textStyle'],
				})
			],
			content: value,
			onUpdate: ({ editor }) => {
				const html = editor.getHTML();
				onChange(isEmptyContent(html) ? "" : html);
			},
			onBlur,
			autofocus: false,
		});

		// Focus the editor when autoFocus is true
		useEffect(() => {
			if (autoFocus && editor) {
				// Small delay to ensure the editor is fully mounted
				setTimeout(() => {
					editor.commands.focus("end");
				}, 0);
			}
		}, [editor, autoFocus]);

		return (
			<FormInput>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div
					className={clsx(
						"overflow-hidden rounded-md bg-white ring-1 ring-inset ring-gray-300",
						error && "border border-red-500",
					)}
					ref={ref}
				>
					<RichTextEditorToolbar editor={editor}
										   features={features} />
					<EditorContent
						editor={editor}
						className={clsx("prose max-w-none px-4 py-3 text-sm leading-tight"
						)}
					/>
				</div>
				{error && (
					<p className="mt-2 text-sm text-red-600">
						{error}
					</p>
				)}
			</FormInput>
		);
	},
);

RichTextEditorPlain.displayName = "RichTextEditorPlain";

type RichTextEditorControllerProps = SharedProps & {
	control: Control<any>;
	defaultValue?: string;
	useControllerError?: boolean;
	rules?: any;
};

const RichTextEditor: React.FC<RichTextEditorControllerProps> = ({
	name,
	control,
	useControllerError,
	error,
	rules,
	...rest
}) => {
	const { field, fieldState: { error: errorFromRHF } } = useController({
		name,
		control,
		rules,
	});

	const errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <RichTextEditorPlain {...field} {...rest} error={errorMessage} />;
};

export const LazyRichTextEditor: React.FC<RichTextEditorControllerProps> = ({
	name,
	control,
	useControllerError,
	error,
	rules,
	disabled,
	inputSize,
	theme,
	placeholder,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const editorRef = useRef<HTMLDivElement>(null);
	const { field, fieldState: { error: errorFromRHF } } = useController({
		name,
		control,
		rules,
	});
	const errorMessage = useControllerError ? errorFromRHF?.message : error;

	const handleBlur = useCallback((event: FocusEvent | MouseEvent) => {
		// For mouse events, check if the click was outside the editor
		if (event instanceof MouseEvent) {
			if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
				setIsFocused(false);
				field.onBlur();
			}
			return;
		}

		// For focus events (tab navigation), check if the new focus target is outside the editor
		if (event instanceof FocusEvent) {
			if (editorRef.current && !editorRef.current.contains(event.relatedTarget as Node)) {
				setIsFocused(false);
				field.onBlur();
			}
		}
	}, [field]);

	useEffect(() => {
		if (isFocused) {
			document.addEventListener("mousedown", handleBlur);
			editorRef.current?.addEventListener("focusout", handleBlur);
			return () => {
				document.removeEventListener("mousedown", handleBlur);
				editorRef.current?.removeEventListener("focusout", handleBlur);
			};
		}
	}, [isFocused, handleBlur]);

	if (isFocused) {
		return (
			<div ref={editorRef}>
				<RichTextEditorPlain
					{...field}
					{...rest}
					error={errorMessage}
					autoFocus={true}
					disabled={disabled}
					inputSize={inputSize}
					theme={theme}
					placeholder={placeholder}
				/>
			</div>
		);
	}
	const PlaceHolderHtml = `<p class="text-gray-900/50">${placeholder}</p>`;

	const html = isEmptyContent(field.value) ? PlaceHolderHtml : field.value;

	return (
		<div
			tabIndex={0}
			className={clsx(
				formInputBaseVariants({ disabled, inputSize, theme }),
				"prose max-w-none cursor-text leading-tight",
				// Add the same list styles
				"[&_ul]:my-1 [&_ul]:list-disc [&_ul]:pl-[1.625em]",
				"[&_ol]:my-1 [&_ol]:list-decimal [&_ol]:pl-[1.625em]",
				// Add consistent text styling
				"[&_p]:my-0 [&_p]:leading-tight",
			)}
			onFocus={() => setIsFocused(true)}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
};

export default RichTextEditor;