import type { Control, FieldErrors, FieldValues, Path, UseFormWatch } from "react-hook-form";

import CurrencyInput from "~/components/formElements/CurrencyInput";
import FormSection from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Label from "~/components/formElements/Label";
import Switch from "~/components/formElements/Switch";
import OrdersRemainingBudgetFormSection
	from "~/modules/project/components/forms/formSections/OrdersRemainingBudgetFormSection";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type StaffingBudgetFormSectionProps<T extends FieldValues> = {
	watch: UseFormWatch<T>;
	control: Control<T>;
	errors: FieldErrors<T>;
	placeholder?: string;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
};

type StaffingBudgetFormSectionType = {
	manDays: number;
	dailyRateCents: number;
};

const StaffingBudgetFormSectionAllAttributes = <T extends StaffingBudgetFormSectionType>({
	control,
	errors,
	watch,
	totalManDaysUnassigned,
	totalBudgetUnassigned,
}: StaffingBudgetFormSectionProps<T>) => {
	const manDays = watch("manDays" as Path<T>);
	const isBillable = watch("isBillable" as Path<T>);
	const dailyRateCents = watch("dailyRateCents" as Path<T>);
	const totalCents = manDays * dailyRateCents;
	const totalRemainingBudget = totalBudgetUnassigned - totalCents;
	const totalRemainingManDays = totalManDaysUnassigned - manDays;

	return (
		<><FormSection title="Kommerzielle Daten">
			<div className="grid grid-cols-2 gap-x-6 gap-y-2">
				<Input
					name="manDays"
					disabled={!isBillable}
					control={control}
					label="Personentage"
					type={"number"}
				/>

				<CurrencyInput
					control={control}
					disabled={!isBillable}
					name="dailyRateCents"
					label="Tagessatz (EUR)"
					error={errors.dailyRateCents?.message as string}
				/>

				<div className={!isBillable ? "opacity-30" : ""}>
					<Label>Summe</Label>
					<div className="text-sm">{formatCentsToCurrency(totalCents)}</div>
				</div>
				<div className="flex flex-col justify-end">
					<Switch control={control}
							name="isBillable"
							labelOn="Abrechenbar"
							labelOff="Nicht abrechenbar" />
				</div>
			</div>
		</FormSection>
			{isBillable && <OrdersRemainingBudgetFormSection totalRemainingBudget={totalRemainingBudget} totalRemainingManDays={totalRemainingManDays}/>}
		</>
	);
};

export default StaffingBudgetFormSectionAllAttributes;
