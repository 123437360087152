import { PencilIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { createColumnHelper } from "@tanstack/react-table";
import Status from "components/statusDisplay/Status";

import Avatar from "~/components/Avatar";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import OverflowPercentageChart from "~/components/OverflowPercentageChart";
import { mainPopUpPortalId } from "~/constants/portalIds.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import StaffingStatsHoverCard
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/components/StaffingStatsHoverCard";
import type {
	StaffingsTableData,
	StaffingsTableMeta,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateRangeHumanReadable } from "~/utils/dateAndTimeUtils.ts";
import { customArrayIncludesSome } from "~/utils/tanStackTableUtils.ts";

const columnHelper = createColumnHelper<StaffingsTableData>();
export const staffingsTableColumnDefs = [

	columnHelper.accessor("userFullName", {
		header: "Mitarbeiter:in / Karrierestufe",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
		cell: (props) => {
			const rowData = props.row.original;
			return <div className="flex items-center">
				<Avatar
					firstName={rowData.userFirstName}
					lastName={rowData.userLastName}
					image={rowData.avatarImage}
				/>
				<div className="ml-2 flex flex-col items-start">
					<div className="font-semibold">{rowData.userFullName}</div>
					<div className="-mt-0.5 text-sm">{rowData.careerLevelDisplayName || EMPTY_VALUE_PLACEHOLDER}</div>
				</div>
			</div>;
		},
	}),
	columnHelper.display({
		header: "Verbrauch",

		cell: (props) => {
			const rowData = props.row.original;
			let percentageValue = rowData.manDaysTrackedPercentage;
			const {
				showBudget,
			} = props.table.options.meta as StaffingsTableMeta;

			if (showBudget) {
				percentageValue = rowData.budgetTrackedPercentage;
			}

			if (!rowData.isBillable) {
				return <div className="font-semibold">{rowData.manDaysTracked.toFixed(0)} PT</div>;
			}

			return <div className="flex w-full justify-center">
				<StaffingStatsHoverCard portalId={mainPopUpPortalId}
										budgetCentsPlanned={rowData.budgetCentsPlanned}
										budgetCentsTracked={rowData.budgetCentsTracked}
										manDaysPlanned={rowData.manDaysPlanned}
										manDaysTracked={rowData.manDaysTracked}
										showBudget={showBudget}
				>
					<div>
						<OverflowPercentageChart percentage={percentageValue === Infinity ? 0 : percentageValue}
												 size={40}
												 thickness={0.5}>
						</OverflowPercentageChart>
					</div>
				</StaffingStatsHoverCard>
			</div>;
		},
	}),
	columnHelper.accessor("roleDisplayName", {
		header: "Rolle im Projekt",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
		cell: (props) => props.getValue() || EMPTY_VALUE_PLACEHOLDER,
	}),
	columnHelper.accessor("manDaysPlanned", {
		header: "PT",
		meta: {
			headerTextAlign: "right",
			cellTextAlign: "right",
		},
		cell: (props) => {
			if (!props.row.original.isBillable) {
				return "k/a";
			}
			return props.getValue();
		},
	}),
	columnHelper.accessor("dailyRateCents", {
		header: "Tagessatz",
		meta: {
			headerTextAlign: "right",
			cellTextAlign: "right",
		},
		cell: (props) => {
			if (!props.row.original.isBillable) {
				return "k/a";
			}
			return formatCentsToCurrency(props.getValue());
		},
	}),
	columnHelper.accessor("isCurrent", {
		header: "Gültigkeit",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => {
			const rowData = props.row.original;
			return formatDateRangeHumanReadable(rowData.startDate, rowData.endDate);
		},
		filterFn: customArrayIncludesSome,
	}),
	columnHelper.accessor("isActive", {
		header: "Zeiterfassung",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		cell: (props) => {
			const rowData = props.row.original;
			if (!rowData.isCurrent) {
				return EMPTY_VALUE_PLACEHOLDER;
			}
			const {
				handleActivityClick,
			} = props.table.options.meta as StaffingsTableMeta;

			return <div className="flex justify-center">
				<Status onClick={() => handleActivityClick({
					staffingId: rowData.id,
					newStatus: !rowData.isActive,
					userId: rowData.id,
				})}
						as="button"
						theme={rowData.isActive ? "success" : "default"}>{rowData.isActive ? "Aktiv" : "Deaktiviert"}</Status>
			</div>;
		},
	}),
	columnHelper.display({
		id: "actions",
		meta: {
			cellTextAlign: "right",
		},
		cell: (props) => {
			const rowData = props.row.original;
			const {
				onAddAdditionalStaffingClick,
				onUpdateStaffingClick,
				onDeleteStaffingClick,
			} = props.table.options.meta as StaffingsTableMeta;

			const onAddClick = () => onAddAdditionalStaffingClick(rowData.userId);
			const onEditClick = () => onUpdateStaffingClick(rowData.id);
			const onEditRemove = () => onDeleteStaffingClick(rowData.id);

			return <div className="flex justify-end"><ButtonWithPopover
				theme="dark"
				items={[
					{
						label: "Bearbeiten",
						onClick: onEditClick,
						icon: PencilIcon,
						iconColorClassName: "text-primary-700",
					},
					{
						label: "Zusätzliches Staffing",
						onClick: onAddClick,
						icon: PlusCircleIcon,
						iconColorClassName: "text-success-700",
					},
					{
						label: "Löschen",
						disabled: rowData.manDaysTracked > 0,
						onClick: onEditRemove,
						icon: XCircleIcon,
						iconColorClassName: "text-secondary-700",
					},
				]}
			/></div>;
		},
	}),
];