import clsx from "clsx";
import type { FunctionComponent, MouseEvent, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
	alignEnd?: boolean;
	isActive: boolean;
	onClick: (event: MouseEvent) => void;
};

const ProjectDetailsTabBarTab: FunctionComponent<Props> = ({
	alignEnd,
	children,
	isActive,
	onClick,

}) => {
	return <div
		onClick={onClick}
		className={clsx(
			isActive
				? "border-primary-500 text-primary-500"
				: "border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700",
			"relative cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm [&:not(:first-child)]:ml-8",
			"flex gap-2 font-bold",
			alignEnd ? "grow justify-end" : "",
		)}
	>
		{children}
	</div>;
};

export default ProjectDetailsTabBarTab;