export type VacationDaysReportExcelData = {
	staffMemberFullName: string;
	currentCareerLevelDisplayName: string;
	remainingDays: number;
}


export const getVacationDaysReportColumnDefs = (year: string) => ([
	{
		accessor: "staffMemberFullName",
		header: "Mitarbeiter:in",
		type: "string",
	},
	{
		accessor: "currentCareerLevelDisplayName",
		header: "Karrierestufe",
		type: "string",
	},
	{
		accessor: "remainingDays",
		header: "Resturlaubstage " + year,
		type: "number",
	},
]);