import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

type PortalProps = PropsWithChildren & {
	targetId: string;
}

const Portal = ({ children, targetId }: PortalProps) => {
	const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const target = document.getElementById(targetId);
		setTargetElement(target);
	}, [targetId]);
	// Only render if we found the target element
	return targetElement ? createPortal(children, targetElement) : null;
};

export default Portal;