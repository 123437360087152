import { t } from "i18next";

import type { StatusThemeVariants } from "~/components/statusDisplay/Status/statusVariants.ts";
import { WorkingStatusId } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { WorkingStatusNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function formatProjectPhaseTitle(projectPhaseTitle: string, phaseNumber: number): string {
	return `${phaseNumber} - ${projectPhaseTitle}`;
}

export function getWorkingStatusNameById(workingStatusId: string): WorkingStatusNames {
	let statusNameIdentifier: WorkingStatusNames | undefined = undefined;

	switch (workingStatusId) {
		case WorkingStatusId.Quotation:
			statusNameIdentifier = WorkingStatusNames.Quotation;
			break;
		case WorkingStatusId.Active:
			statusNameIdentifier = WorkingStatusNames.Active;
			break;
		case WorkingStatusId.Hold:
			statusNameIdentifier = WorkingStatusNames.Hold;
			break;
		case WorkingStatusId.Closed:
			statusNameIdentifier = WorkingStatusNames.Closed;
			break;
		case WorkingStatusId.Cancelled:
			statusNameIdentifier = WorkingStatusNames.Cancelled;
			break;
		default:
			throw new Error("Invalid working status id");
	}

	return t(normalizeTKey("entities:workingStatus." + statusNameIdentifier));
}

export function getWorkingStatusThemeById(workingStatusId: string): StatusThemeVariants {
	switch (workingStatusId) {
		case WorkingStatusId.Quotation:
			return "highlight";
		case WorkingStatusId.Active:
			return "success";
		case WorkingStatusId.Hold:
			return "info";
		case WorkingStatusId.Closed:
			return "error";
		case WorkingStatusId.Cancelled:
			return "default";
		default:
			return "default";
	}
}

