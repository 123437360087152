import { createColumnHelper } from "@tanstack/react-table";

import type { ProjectsTimeTrackingsTableData } from "~/modules/project/types/projectViewTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth, formatHoursAndMinutes } from "~/utils/dateAndTimeUtils.ts";
import { customArrayIncludesSome, customDateFilter } from "~/utils/tanStackTableUtils.ts";

const columnHelper = createColumnHelper<ProjectsTimeTrackingsTableData>();

export const projectsTimeTrackingTableColumnDefs = [
	columnHelper.accessor("date", {
		header: "Datum",
		cell: props => formatDateWithGermanMonth(props.getValue()),
		filterFn: customDateFilter,
	}),
	columnHelper.accessor("userId", {
		header: "Mitarbeiter",
		meta: {
			cellTextAlign: "left",
			headerTextAlign: "left",
		},
		sortingFn: "text",
		enableSorting: true,
		filterFn:customArrayIncludesSome,
		cell: props => props.row.original.userFullName,
	}),
	columnHelper.accessor("phaseId", {
		header: "Phase",
		filterFn:customArrayIncludesSome,
		cell: props => props.row.original.phaseTitle,
		meta: {
			widthPercentage: 10,
		},
	}),
	columnHelper.accessor("orderTitle", {
		header: "PO",
		meta: {
			widthPercentage: 10,
		},
	}),
	columnHelper.accessor("deliverablesText", {
		header: "Buchungstext",
		meta: {
			cellTextAlign: "left",
			widthPercentage: 20,
		},
		cell: props => props.row.original.deliverablesText,
	}),

	columnHelper.accessor("dailyRateCents", {
		header: "Tagessatz",
		meta: {
			cellTextAlign: "right",
			headerTextAlign: "right",
		},
		cell: props => {
			const dailyRateCents = props.getValue();
			if(dailyRateCents === 0){
				return "--";
			}

			return formatCentsToCurrency(props.getValue());
		}
	}),
	columnHelper.accessor("minutesTracked", {
		header: "Dauer",
		cell: props => formatHoursAndMinutes(props.getValue()),
	}),
	columnHelper.accessor("totalAmountCents", {
		header: "Kosten",
		meta: {
			cellTextAlign: "right",
			headerTextAlign: "right",
		},
		cell: props => {
			const rowData = props.row.original;
			if(!rowData.isBillable){
				return "Nicht abrechenbar";
			}

			return formatCentsToCurrency(props.getValue());
		}
	}),
	columnHelper.accessor("isBillable", {
		id: "isBillable",
		header: "Abrechenbar",
		filterFn:customArrayIncludesSome,
	})
];
