import { ChartPieIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Button from "components/buttons/Button";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BookIcon from "~/components/icons/BookIcon";
import BrainIcon from "~/components/icons/BrainIcon";
import CartIcon from "~/components/icons/CartIcon";
import Sidebar from "~/components/Sidebar";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import type { Location, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import type { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import type { TimeTrackingFormData } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import type { TimeTrackingType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import Projects from "~/modules/timeTracking/components/components/Projects";
import CreateOrUpdateTimeTrackingForm from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import SyncUserAssetsForm from "~/modules/timeTracking/components/forms/SyncUserAssetsForm";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getTimeTrackingTypeDisplayNameById } from "~/modules/timeTracking/utils/timeTrackingTypeUtils.ts";
import {
	getAvailableUserDeliverables,
	getAvailableUserLocations,
	getStaffingDetailsFromActiveStaffings,
} from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type CreateTimeTrackingSidebarProps = {
	activeStaffings: UsersActiveStaffingType[];
	userDeliverables: UserAvailableDeliverableType[];
	userLocations: UserAvailableLocationType[];
	close: () => void;
	disabledStaffingIds: string[] | [];
	isOpen: boolean;
	date: null | Date,
	locations: Location[];
	selectedUserId: User["id"];
	timeTrackingTypes: TimeTrackingType[];
	users: User[];
	userIsFreelancer: boolean
};

const CreateTimeTrackingSidebar: React.FunctionComponent<CreateTimeTrackingSidebarProps> = ({
	activeStaffings,
	userDeliverables,
	userLocations,
	disabledStaffingIds,
	close,
	isOpen,
	date,
	locations,
	selectedUserId,
	timeTrackingTypes,
	users,
	userIsFreelancer,
}) => {
	const { t } = useTranslation();

	const [selectedTimeTrackingTypeId, setSelectedTimeTrackingTypeId] = useState<TimeTrackingTypeId>(TimeTrackingTypeId.Project);
	const [selectedStaffingId, setSelectedStaffingId] = useState<string | null>(null);
	const [currentFormName, setCurrentFormName] = useState<null | TimeTrackingFormNamesEnum>(null);
	const [currentTimeTrackingFormValues, setCurrentTimeTrackingFormValues] = useState<TimeTrackingFormData>(createTimeTrackingFormDefaultValues);
	const selectedStaffingData = useMemo(() => {
		if (selectedStaffingId && activeStaffings) {
			return getStaffingDetailsFromActiveStaffings(selectedStaffingId, activeStaffings);
		}

		return null;
	}, [selectedStaffingId, activeStaffings]);

	const selectedStaffingProjectId = selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ? selectedStaffingData?.projectId : null;

	const closeAndReset = useCallback(() => {
		close();
		// reset state after closing animation ends
		setTimeout(() => {
			setCurrentTimeTrackingFormValues(createTimeTrackingFormDefaultValues);
			setSelectedTimeTrackingTypeId("1");
			setSelectedStaffingId(null);
			setCurrentFormName(null);
		}, 800);
	}, [close]);


	const handleTimeTrackingTypeClick = useCallback((value: TimeTrackingTypeId) => {
		if (value !== TimeTrackingTypeId.Project) {
			setSelectedTimeTrackingTypeId(value);
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
			setSelectedStaffingId(null);
		}
	}, []);

	const handleStaffingIdClick = useCallback((value: string) => {
		setSelectedStaffingId(value);
		setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	}, []);

	useEffect(() => {
		if (currentFormName == null) {
			setSelectedTimeTrackingTypeId("1");
		}
	}, [currentFormName]);

	const selectedTimeTrackingTypeName = getTimeTrackingTypeDisplayNameById(selectedTimeTrackingTypeId);

	const title = userIsFreelancer ? "Auswahl des Projektes für Deine Erfassung" : "Welche Buchung möchtest Du machen?";

	const availableLocations = useMemo(() => {
		return getAvailableUserLocations({
			userLocations,
			selectedStaffingProjectId,
			selectedTimeTrackingTypeId,
		});
	}, [userLocations, selectedStaffingProjectId, selectedTimeTrackingTypeId]);

	const availableDeliverables = useMemo(() => {
		return getAvailableUserDeliverables({
			userDeliverables,
			selectedStaffingProjectId,
			selectedTimeTrackingTypeId,
		});
	}, [userDeliverables, selectedStaffingProjectId, selectedTimeTrackingTypeId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeAndReset}>
			<div className="flex min-h-full w-full flex-col justify-start">
				{currentFormName === null &&
					<>
						<SidebarHeader>
							<SidebarHeaderHeadline>{title}</SidebarHeaderHeadline>
						</SidebarHeader>
						<SidebarContent>
							{!userIsFreelancer && <div className="mb-10 grid grid-cols-4 gap-6 px-5">
								{timeTrackingTypes.map(({ id, name }) => (
										<button key={"timeTrackingTypeOption" + name}
												onClick={() => handleTimeTrackingTypeClick(id as TimeTrackingTypeId)}
												className={clsx("flex flex-row items-center gap-3",
													"cursor-pointer rounded-md px-4 py-2 sm:flex-1",
													"focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white",
													selectedTimeTrackingTypeId === id
														? "bg-primary-500 text-white hover:bg-primary-700"
														: "bg-white font-bold text-primary-500 ring-1 ring-inset ring-primary-500 hover:bg-gray-50",
												)}>
											{"1" === id &&
												<BookIcon className="size-[1.4rem] fill-primary-500 text-current" />}
											{"2" === id && <BrainIcon className="size-8 fill-primary-500 text-current" />}
											{"3" === id &&
												<CartIcon className="-mr-1 size-8 fill-primary-500 text-current" />}
											{"4" === id &&
												<ChartPieIcon className="mr-[-0.4rem] size-8 fill-primary-500 text-current" />}
											<div className="text-lg font-bold text-current">
												{getTimeTrackingTypeDisplayNameById(id as TimeTrackingTypeId)}
											</div>
										</button>
									),
								)}
							</div>}
							<div className={!userIsFreelancer ? "mt-10 px-5" : ""}>
								<Projects
									usersActiveStaffings={activeStaffings}
									disabledStaffingIds={disabledStaffingIds}
									value={selectedStaffingId}
									onChange={handleStaffingIdClick}
								/>
							</div>
						</SidebarContent>
						<SidebarFooter>
							<Button theme="none"
									onClick={closeAndReset}>
								{t(normalizeTKey("form:buttonTexts.cancel"))}
							</Button>
						</SidebarFooter>
					</>}
				{currentFormName === TimeTrackingFormNamesEnum.TIME_TRACKING_FORM &&
					<CreateOrUpdateTimeTrackingForm
						activeStaffings={activeStaffings}
						availableDeliverables={availableDeliverables}
						availableLocations={availableLocations}
						date={date}
						parentIsVisible={isOpen}
						initialValues={currentTimeTrackingFormValues}
						locations={locations}
						onSuccess={closeAndReset}
						setCurrentTimeTrackingFormValues={setCurrentTimeTrackingFormValues}
						selectedStaffingData={selectedStaffingData}
						selectedStaffingId={selectedStaffingId}
						selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
						selectedTimeTrackingTypeName={selectedTimeTrackingTypeName}
						selectedUserId={selectedUserId}
						setCurrentFormName={setCurrentFormName}
						users={users} />}

				{currentFormName === TimeTrackingFormNamesEnum.LOCATION_FORM &&
					<SyncUserAssetsForm
						assetType="location"
						existingAssets={availableLocations}
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}
				{currentFormName === TimeTrackingFormNamesEnum.DELIVERABLE_FORM &&
					<SyncUserAssetsForm
						assetType="deliverable"
						existingAssets={availableDeliverables}
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}

			</div>
		</Sidebar>
	);
};

export default CreateTimeTrackingSidebar;
