import { endOfMonth, format, isSameDay } from "date-fns";
import { de } from "date-fns/locale";
import ExcelJS from "exceljs";
import type { FunctionComponent } from "react";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";
import { DEFAULT_EXCEL_TABLE_STYLE } from "~/constants/excelConsts.ts";
import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import {
	staffMembersMonthlyTimeTrackingsExcelColumnDefs,
} from "~/modules/timeTracking/components/components/DownloadTimeTrackingsByMonthExcelButton/staffMembersMonthlyTimeTrackingsExcelColumnDefs.ts";
import { getTimeTrackingTypeDisplayNameById } from "~/modules/timeTracking/utils/timeTrackingTypeUtils.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	endDate: Date;
	startDate: Date;
	staffMemberDisplayName: string;
	timeTrackings?: TimeTrackingExtendedType[];
};

const DownloadTimeTrackingsByMonthExcelButton: FunctionComponent<Props> = ({
	endDate,
	startDate,
	staffMemberDisplayName,
	timeTrackings,
}) => {
	const { data: allUsersData } = useAllUsers();

	const handleDownload = async () => {
		if (!allUsersData) return;
		if (!timeTrackings) return;
		const monthAndYear = format(startDate, "MMMM yyyy", { locale: de });
		let timeSpanFormatted: string;
		if (isSameDay(endDate, endOfMonth(endDate))) {
			timeSpanFormatted = monthAndYear;
		} else if (isSameDay(startDate, endDate)) {
			timeSpanFormatted = format(startDate, "dd.MM.yyyy", { locale: de });
		} else {
			timeSpanFormatted = formatDateRange(startDate, endDate);
		}

		const workbook = new ExcelJS.Workbook();
		const fileName = `${staffMemberDisplayName} - Erfassungen ${monthAndYear}`;
		const workSheetName = `Erfassungen ${monthAndYear}`;
		const worksheet = workbook.addWorksheet(workSheetName);

		// Add header information
		worksheet.addRow(["Mitarbeiter:in", staffMemberDisplayName]);
		worksheet.addRow(["Zeitraum", timeSpanFormatted]);
		worksheet.addRow(["Erstellt am", format(new Date(), "dd.MM.yyyy HH:mm") + " h"]);

		// Align start and end dates to the left
		/*startDateRow.getCell(2).alignment = { horizontal: "left" };*/


		// Add an empty row for spacing
		worksheet.addRow([]);

		// Add table headers
		const headerRowNumber = worksheet.rowCount + 1;
		const headers = staffMembersMonthlyTimeTrackingsExcelColumnDefs.map(col => col.header);
		worksheet.addRow(headers);

		const rows = timeTrackings.sort(byObjectProperty("date")).map((row: TimeTrackingExtendedType,index:number) => {
			const currentRowNumber = headerRowNumber + index + 1;
			const timeTrackingTypeDisplayName = getTimeTrackingTypeDisplayNameById(row.timeTrackingTypeId);
			return [
				new Date(row.date),
				timeTrackingTypeDisplayName,
				row.companyName,
				row.projectTitle,
				row.projectPhaseTitle,
				row.orderTitle,
				row.locationName || "",
				row.text || "",
				row.minutes,
				{ formula: `ROUND(I${currentRowNumber} / 60,2)` },
			];
		});

		// Create an Excel table
		const tableEndRowNumber = headerRowNumber + rows.length;
		const tableEndColumnLetter = String.fromCharCode(64 + headers.length);
		const tableName = "Erfassungen";
		const tableRef = `A${headerRowNumber}:${tableEndColumnLetter}${tableEndRowNumber}`;

		const table = worksheet.addTable({
			name: tableName,
			ref: tableRef,
			columns: staffMembersMonthlyTimeTrackingsExcelColumnDefs.map(col => ({ name: col.header })),
			rows: rows,
			style: {
				theme: DEFAULT_EXCEL_TABLE_STYLE,
				showRowStripes: true,
			},
			totalsRow: false,
		});

		// Make table sortable and filterable
		// @ts-expect-error type definitions are missing these properties
		table.table.columns.forEach(column => {
			column.filterButton = true;
		});

		// Apply cell formatting
		staffMembersMonthlyTimeTrackingsExcelColumnDefs.forEach((colDef, index) => {
			const column = worksheet.getColumn(index + 1);
			let numFmt: string | undefined;
			switch (colDef.type) {
				case "date":
					numFmt = "dd.mm.yyyy";
					break;
				case "number":
					numFmt = "#,##0.00";
					break;
				case "integer":
					numFmt = "#,##0";
					break;
			}
			if (numFmt) {
				column.eachCell({ includeEmpty: true }, cell => {
					cell.numFmt = numFmt!;
				});
			}
		});

		// Add sum row with formulas
		const sumRow = worksheet.addRow([
			"", "", "", "", "", "", "", "Summe",
			{ formula: `SUBTOTAL(9,I${headerRowNumber + 1}:I${tableEndRowNumber})` },
			{ formula: `SUBTOTAL(9,J${headerRowNumber + 1}:J${tableEndRowNumber})` },
		]);
		sumRow.font = { bold: true };

		// Format sum and total days rows
		[sumRow].forEach(row => {
			row.eachCell((cell, colNumber) => {
				const column = staffMembersMonthlyTimeTrackingsExcelColumnDefs[colNumber - 1];
				if (column) {
					switch (column.type) {
						case "currency":
							cell.numFmt = "#,##0.00 \"€\"";
							break;
						case "number":
							cell.numFmt = "#,##0.00";
							break;
					}
				}
				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;
			// @ts-expect-error - TS doesn't know about the includeEmpty option
			column.eachCell({ includeEmpty: true }, (cell) => {
				const cellLength = cell.value ? cell.value.toString().length : 10;
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});
			if (column) {
				column.width = Math.min(maxLength + 2, 30);
			}
		});

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton disabled={!timeTrackings || timeTrackings.length === 0}
								buttonText=""
								theme="none"
								onClick={handleDownload} />;
};

export default DownloadTimeTrackingsByMonthExcelButton;