import type { FunctionComponent } from "react";
import { useCallback } from "react";

import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";

type Props = {
	filterValue: boolean[];
	onChange: (value: boolean[]) => void;
};

const BillabilityFilter: FunctionComponent<Props> = ({ filterValue, onChange }) => {
	const handleFilterChange = useCallback((checked: boolean) => {
		const newValue = [true];
		if (!checked) {
			newValue.push(false);
		}
		onChange(newValue);
	}, [onChange]);
	const isChecked = filterValue.length === 1;
	return <SwitchPlain name="billabilityFilter"
						checked={isChecked}
						label="Nur abrechenbare"
						onChange={handleFilterChange} />;
};

export default BillabilityFilter;