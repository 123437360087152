export type SortDirection = "asc" | "desc";

export function sortArray<T extends string | number | boolean | Date>(array: T[],
	sortDirection: SortDirection = "asc"): T[] {
	return [...array].sort((a: T, b: T): number => {
		if (typeof a === "string" && typeof b === "string") {
			return sortDirection === "asc" ? a.localeCompare(b as string) : b.localeCompare(a as string);
		} else if (typeof a === "boolean" && typeof b === "boolean") {
			return sortDirection === "asc" ? (a === b ? 0 : a ? -1 : 1) : (a === b ? 0 : a ? 1 : -1);
		} else if (typeof a === "number" && typeof b === "number") {
			return sortDirection === "asc" ? a - b : b - a;
		}
		return 0;
	});
}


export function byObjectProperty<T>(property: keyof T, order: SortDirection = "asc"): (a: T, b: T) => number {
	return (a: T, b: T): number => {
		const aValue = a[property];
		const bValue = b[property];

		// Check for null, undefined, or empty string
		const aIsEmpty = aValue == null || aValue === "";
		const bIsEmpty = bValue == null || bValue === "";

		if (aIsEmpty && bIsEmpty) return 0; // Both are empty, so equal
		if (aIsEmpty) return 1; // a should be at the end
		if (bIsEmpty) return -1; // b should be at the end

		if (typeof aValue === "string" && typeof bValue === "string") {
			return order === "asc"
				? (aValue as any).localeCompare(bValue)
				: (bValue as any).localeCompare(aValue);
		} else if (aValue < bValue) {
			return order === "asc" ? -1 : 1;
		} else if (aValue > bValue) {
			return order === "asc" ? 1 : -1;
		}

		return 0;
	};
}

export function byMultipleObjectProperties<T>(propsWithDirections: {
	property: keyof T;
	direction: SortDirection
}[]): (a: T, b: T) => number {
	return (a: T, b: T): number => {
		for (const { property, direction } of propsWithDirections) {
			const aValue = a[property];
			const bValue = b[property];

			// Check for null, undefined, or empty string
			const aIsEmpty = aValue == null || aValue === "";
			const bIsEmpty = bValue == null || bValue === "";

			if (aIsEmpty && bIsEmpty) continue; // Both are empty, so equal
			if (aIsEmpty) return 1; // a should be at the end
			if (bIsEmpty) return -1; // b should be at the end

			if (typeof aValue === "string" && typeof bValue === "string") {
				const comparison = (direction === "asc" ? 1 : -1) * (aValue as any).localeCompare(bValue);
				if (comparison !== 0) return comparison;
			} else {
				if (aValue < bValue) {
					return direction === "asc" ? -1 : 1;
				} else if (aValue > bValue) {
					return direction === "asc" ? 1 : -1;
				}
			}
		}

		return 0;
	};
}
