import clsx from "clsx";
import { t } from "i18next";
import type { FunctionComponent } from "react";
import { useEffect, useRef } from "react";

import Button from "~/components/buttons/Button";
import EmploymentStatusFilter
	from "~/components/filters/EmploymentStatusFilter";
import SearchInput from "~/components/formElements/SearchInput";
import { statusVariants } from "~/components/statusDisplay/Status/statusVariants.ts";
import { useAllEmploymentTypes } from "~/modules/humanResources/api/employmentType/employmentTypeQueries.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { isMobileOrTablet } from "~/utils/mobileAndTabletDetection.ts";

type Props = {
	search: string;
	setSearch: (search: string) => void;
	setShowCreateUserForm: (showCreateUserForm: boolean) => void;
	employmentTypeFilter: string[];
	onEmploymentStatusFilterChange: (employmentTypeId: string) => void;
	totalUserCount: number;
	visibleUserCount: number;
};

const AdminUsersFilterBar: FunctionComponent<Props> = ({
	employmentTypeFilter,
	onEmploymentStatusFilterChange,
	search,
	setSearch,
	setShowCreateUserForm,
	totalUserCount,
	visibleUserCount,
}) => {
	const searchInputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (searchInputRef.current && !isMobileOrTablet()) {
			searchInputRef.current.focus();
		}
	}, []);

	const { data: employmentTypes } = useAllEmploymentTypes();

	return <div className="relative sticky flex w-full justify-between">
		<div className="w-48">
			<SearchInput value={search}
				onChange={setSearch}
				ref={searchInputRef} />
		</div>
		<div className="ml-6 mr-auto flex items-center">
			<EmploymentStatusFilter
				employmentTypes={employmentTypes}
				employmentTypeFilter={employmentTypeFilter}
				onEmploymentStatusFilterChange={onEmploymentStatusFilterChange} />
		</div>
		<Button onClick={() => setShowCreateUserForm(true)}>Neuer User</Button>
		{(employmentTypeFilter.length > 0 || search) && (
			<div className="absolute left-0 w-full bg-gray-100 shadow-xl shadow-gray-100"
				style={{ top: 57 }}>
				<div className="px-4 py-3 sm:flex sm:items-center">
					<h3 className="text-sm font-medium text-gray-500">
						Aktive Filter
						<span className="sr-only">, active</span>
					</h3>

					<div aria-hidden="true"
						className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

					<div className="sm:ml-4 sm:mt-0">
						<div className="-m-1 flex flex-wrap items-center gap-x-1">
							{search && <span
								key={"searchFilter"}
								className={clsx(`inline-flex items-center 
										rounded-full
										py-1.5 pl-3 pr-2 
										text-sm font-medium`, statusVariants({
									theme: "default",
									borderRadius: "full",
								}))}
							>
										Suche: {search}
								<button
									type="button"
									className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
									onClick={() => setSearch("")}
								>
											<span className="sr-only">Remove filter for status option</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													strokeWidth="1.5"
													d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>}
							{employmentTypes?.filter(employmentType => employmentTypeFilter.includes(employmentType.id)).map((employmentType,
								index) => {
								const theme = "default";
								const statusDisplayName = t(normalizeTKey(`entities:employmentType.${employmentType.name}`));
								return <span
									key={"statusFilter" + index}
									className={clsx(`inline-flex items-center 
										rounded-full
										py-1.5 pl-3 pr-2 
										text-sm font-medium`, statusVariants({
										theme,
										borderRadius: "full",
									}))}
								>
										{statusDisplayName}
									<button
										type="button"
										className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
										onClick={() => onEmploymentStatusFilterChange(employmentType.id)}
									>
											<span className="sr-only">Remove filter for status option</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													strokeWidth="1.5"
													d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>;
							})}
						</div>
					</div>
					<span className="ml-auto text-xs text-gray-400">{visibleUserCount} von {totalUserCount} Usern sichtbar</span>
				</div>
			</div>
		)}
	</div>;
};

export default AdminUsersFilterBar;