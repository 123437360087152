export interface TimeTrackingType {
	id: string;
	name: string;
}

export const TimeTrackingTypeId = {
	Project: "1",
	Internal: "2",
	Sales: "3",
	PartTime: "4",
} as const;

export type TimeTrackingTypeId = typeof TimeTrackingTypeId[keyof typeof TimeTrackingTypeId];