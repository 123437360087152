import type { ReactNode } from "react";

export type Tab = {
	icon?: ReactNode;
	id: string;
	label: string;
}

export const TabBarVariantName = {
	Default: "default",
	Light: "light",
} as const;

export type TabBarVariantName = typeof TabBarVariantName[keyof typeof TabBarVariantName];