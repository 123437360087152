export const staffMembersMonthlyTimeTrackingsExcelColumnDefs = [
	{
		header: "Datum",
		accessor: "date",
		type: "date",
	},
	{
		header: "Typ",
		accessor: "timeTrackingTypeDisplayName",
		type: "string",
	},
	{
		header: "Kunde",
		accessor: "customerDisplayName",
		type: "string",
	},
	{
		header: "Projekt",
		accessor: "projectTitle",
		type: "string",
	},
	{
		header: "Phase",
		accessor: "phaseTitle",
		type: "string",
	},
	{
		header: "PO",
		accessor: "orderTitle",
		type: "string",
	},
	{
		header: "Ort",
		accessor: "locationName",
		type: "string",
	},
	{
		header: "Text",
		accessor: "text",
		type: "string",
	},
	{
		header: "Dauer (Minuten)",
		accessor: "minutes",
		type: "integer",
	},
	{
		header: "Dauer (Stunden)",
		accessor: "hours",
		type: "number",
	},

];