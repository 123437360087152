import { CloseButton, Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { EllipsisHorizontalIcon, PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import PhaseTabStatusIndicator
	from "components/statusDisplay/StatusIndicatorDot";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { PROJECT_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import ProjectDetailsTabBarTab
	from "~/modules/project/components/ProjectDetailsView/components/ProjectDetailsTabBar/components/ProjectDetailsTabBarTab";
import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	projectId: string;
	projectPhases: ProjectPhase[];
	selectedTabId: string;
	onCreatePhase: () => void;
};

type Tab = { label: string; tabId: string, phaseStatusId?: string };

function getTotalWidthWithMargins(element: HTMLElement) {
	const styles = window.getComputedStyle(element);
	const rect = element.getBoundingClientRect();
	const marginLeft = parseFloat(styles.marginLeft);
	const marginRight = parseFloat(styles.marginRight);

	return rect.width + marginLeft + marginRight;
}

type TabWidths = {
	totalWidth: number;
	elementWidths: Record<string, number>;
};

const OVERFLOW_TAB_ID = "overflow";
const CREATE_PHASE_TAB_ID = "create-phase";

const SAFETY_MARGIN = 32; // pixels

const ProjectDetailsTabBar: FunctionComponent<Props> = ({ projectId, projectPhases, selectedTabId, onCreatePhase }) => {
	const navigate = useNavigate();
	const [visibleTabs, setVisibleTabs] = useState<Tab[]>([]);
	const [overflowTabs, setOverflowTabs] = useState<Tab[]>([]);
	const [tabWidths, setTabWidths] = useState<TabWidths>({ totalWidth: 0, elementWidths: {} });
	const containerRef = useRef<HTMLDivElement>(null);
	const measurementContainerRef = useRef<HTMLDivElement>(null);
	const [hasCalculatedWidths, setHasCalculatedWidths] = useState(false);

	const tabs = useMemo<Tab[]>(() => {
		return [
			{ label: "Übersicht", tabId: ProjectDetailsTabName.Overview },
			{ label: "Tagging", tabId: ProjectDetailsTabName.ProjectTags },
			{ label: "Referenzen", tabId: ProjectDetailsTabName.ReferenceTexts },
			{ label: "Reporting", tabId: ProjectDetailsTabName.Reports },
			...projectPhases.sort(byObjectProperty("phaseNumber", "desc")).map((phase) => ({
				label: `${phase.phaseNumber}`,
				tabId: `phase-${phase.phaseNumber}`,
				phaseStatusId: phase.workingStatusId,
			})),
			{ label: "...", tabId: OVERFLOW_TAB_ID },
			{ label: "+", tabId: CREATE_PHASE_TAB_ID },
		];
	}, [projectPhases]);

	const measureTabWidths = useCallback(() => {
		if (!measurementContainerRef.current) return;
		const newTabWidths: TabWidths = { totalWidth: 0, elementWidths: {} };

		tabs.forEach((tab, index) => {
			const tabElement = measurementContainerRef.current?.children[index] as HTMLElement;
			if (tabElement) {
				const tabWidth = getTotalWidthWithMargins(tabElement);
				newTabWidths.elementWidths[tab.tabId] = tabWidth;
				newTabWidths.totalWidth += tabWidth;
			}
		});

		setTabWidths(newTabWidths);
		setHasCalculatedWidths(true);
	}, [tabs]);

	const calculateVisibleTabs = useCallback(() => {
		if (!containerRef.current || Object.keys(tabWidths.elementWidths).length === 0) return;
		const measuredWidths = tabWidths.elementWidths;
		const containerWidth = containerRef.current.offsetWidth;
		const visible: Tab[] = [];
		const overflow: Tab[] = [];


		const plusTab = tabs[tabs.length - 1];
		const overflowTab = tabs[tabs.length - 2];

		if (tabWidths.totalWidth - measuredWidths[overflowTab.tabId] <= containerWidth) {
			setVisibleTabs([...[...tabs].slice(0, -2), plusTab]);
			return;
		}

		let accumulatedWidth = measuredWidths[plusTab.tabId];
		let overflowReached = false;
		for (let i = 0; i < tabs.length - 2; i++) {
			const tab = tabs[i];
			if (!overflowReached && accumulatedWidth + measuredWidths[tab.tabId] + measuredWidths[overflowTab.tabId] + SAFETY_MARGIN <= containerWidth) {
				visible.push(tab);
				accumulatedWidth += measuredWidths[tab.tabId];
			} else {
				overflowReached = true;
				overflow.push(tab);
			}
		}

		if (overflow.length > 0) {
			visible.push(overflowTab);
		}

		visible.push(plusTab);

		setVisibleTabs(visible);
		setOverflowTabs(overflow);
	}, [tabWidths.elementWidths, tabWidths.totalWidth, tabs]);

	useLayoutEffect(() => {
		measureTabWidths();
	}, [measureTabWidths, tabs]);

	useLayoutEffect(() => {
		calculateVisibleTabs();
		window.addEventListener("resize", calculateVisibleTabs);

		return () => window.removeEventListener("resize", calculateVisibleTabs);
	}, [calculateVisibleTabs]);

	const handleTabClick = (tabId: string) => {
		if (tabId === CREATE_PHASE_TAB_ID) {
			onCreatePhase();
		} else if (tabId !== OVERFLOW_TAB_ID) {
			navigate(generatePath(PROJECT_DETAILS_PATH_WITH_TAB_ID, { projectId, tabId }));
		}
	};

	return (
		<div className="relative w-full">
			{!hasCalculatedWidths && <div className={clsx("absolute flex")}
										  ref={measurementContainerRef}>
				{tabs.map(({ label, phaseStatusId }, tabIndex) => {
					let content: ReactNode = label;

					if (phaseStatusId) {
						content = <div className="flex items-center gap-1">
							<span>{label}</span>
							<PhaseTabStatusIndicator statusId={phaseStatusId} />
						</div>;
					}
					return <div
						key={`sectionTabs-${tabIndex}`}
						className={clsx("border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
							"cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium [&:not(:first-child)]:ml-8",
							"flex items-center gap-2",
						)}
					>
						{content}
					</div>;
				})}
			</div>}
			<nav className="mt-4 flex"
				 ref={containerRef}>
				{visibleTabs.map(({ label, tabId, phaseStatusId }, tabIndex) => {
					const isActive = selectedTabId === tabId;
					let content: ReactNode = label;

					if (phaseStatusId) {
						content = <div className="flex items-center gap-1">
							<span className="mr-0.5">{label}</span>
							<PhaseTabStatusIndicator statusId={phaseStatusId} />
						</div>;
					}

					if (tabId === CREATE_PHASE_TAB_ID) {
						content = <PlusIcon className="size-5" />;
					}

					if (tabId === OVERFLOW_TAB_ID) {
						return (
							<Popover key={tabIndex}
									 className="relative ml-8">
								<PopoverButton>
									<ProjectDetailsTabBarTab key={tabIndex}
															 isActive={isActive}
															 onClick={() => {
															 }}><EllipsisHorizontalIcon className="size-5 translate-y-1/4"
																						aria-hidden="true" /></ProjectDetailsTabBarTab>
								</PopoverButton>
								<PopoverPanel anchor="bottom"
											  className="flex flex-col bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
									{overflowTabs.map(({ label, tabId, phaseStatusId }, index) => (
										<div
											key={`overflow-${index}`}
											onClick={() => handleTabClick(tabId)}
											className="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
											role="menuitem"
										>
											<CloseButton>
												<div className="flex items-center gap-1">
													<span className="mr-0.5">{label}</span>
													{phaseStatusId &&
														<PhaseTabStatusIndicator statusId={phaseStatusId} />}
												</div>
											</CloseButton>
										</div>
									))}
								</PopoverPanel>
							</Popover>
						);
					}
					return <ProjectDetailsTabBarTab key={tabIndex}
													isActive={isActive}
													onClick={() => handleTabClick(tabId)}>
						{content}
					</ProjectDetailsTabBarTab>;
				})}

			</nav>
		</div>
	);
};

export default ProjectDetailsTabBar;