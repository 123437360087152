import { useQueryClient } from "@tanstack/react-query";
import { startOfDay } from "date-fns";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { PROJECT_ROLES_BY_PROJECT_QUERY_KEY } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import { checkIfStaffingIsEditable } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	useProjectsStaffings,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import UpdateStaffingForm from "~/modules/project/components/forms/UpdateStaffingForm";
import type {
	StaffingsTableData,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type UpdateStaffingSidebarProps = {
	allStaffings: StaffingsTableData[];
	close: () => void;
	isOpen: boolean;
	projectId: string;
	staffingId: string | null;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
};

const UpdateStaffingSidebar: FunctionComponent<UpdateStaffingSidebarProps> = ({
	allStaffings,
	close,
	isOpen,
	projectId,
	staffingId,
	totalBudgetUnassigned,
	totalManDaysUnassigned,
}) => {
	const { data: projectsStaffingsData } = useProjectsStaffings(projectId);
	const [staffingIsEditable, setStaffingIsEditable] = useState<null | boolean>(null);
	const { user } = useAuth();
	const queryClient = useQueryClient();

	const staffingData = useMemo(() => {
		if (projectsStaffingsData && staffingId) {
			const staffingData = projectsStaffingsData?.find((staffing) => staffing.id === staffingId);
			if (!staffingData) {
				queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
				close();
			}
			return staffingData;
		}
	}, [close, projectId, projectsStaffingsData, queryClient, staffingId]);

	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_ROLES_BY_PROJECT_QUERY_KEY(projectId) });
		if (user && staffingData && staffingData.userId === user?.id) {
			queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user.id) });
		}

		close();
	}, [close, projectId, queryClient, staffingData, user]);

	const handleClose = useCallback(() => {
		setStaffingIsEditable(null);
		close();
	}, [close]);

	const usersOtherStaffings = useMemo(() => {
		if (allStaffings && staffingData) {
			return allStaffings.filter(staffing => staffing.userId === staffingData.userId && staffing.id !== staffingData.id).map(staffing => ({
				...staffing,
				startDate: startOfDay(staffing.startDate),
				endDate: staffing.endDate ? startOfDay(staffing.endDate) : null,
			})).sort(byObjectProperty("startDate"));
		}
		return [];
	}, [allStaffings, staffingData]);

	const {
		data: allUsersData,
	} = useAllUsers();

	const userData = useMemo(() => {
		if (allUsersData) {
			return allUsersData.find(user => user.id === staffingData?.userId);

		}
		return null;
	}, [allUsersData, staffingData?.userId]);

	useAsyncEffect(async (isMounted) => {
		if (staffingData) {
			const res = await checkIfStaffingIsEditable({
				projectId: staffingData.projectId,
				staffingId: staffingData.id,
			});
			const isEditable = getDataFromResponse(res).isEditable;
			if (isMounted()) {
				setStaffingIsEditable(isEditable);
			}
		}
		else{
			setStaffingIsEditable(null);
		}
	}, [staffingData]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={handleClose}>
			{(!staffingData || staffingIsEditable === null) ? (
				<LoadingPage />
			) : (
				<UpdateStaffingForm staffingData={staffingData}
									totalBudgetUnassigned={totalBudgetUnassigned}
									totalManDaysUnassigned={totalManDaysUnassigned}
									usersOtherStaffings={usersOtherStaffings}
									onSuccess={handleSuccess}
									onCancel={handleClose}
									staffedUserData={userData!}
									staffingIsEditable={staffingIsEditable}
				/>
			)}
		</Sidebar>
	);
};

export default UpdateStaffingSidebar;
