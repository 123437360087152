import type { DateRangeWithOptionalEndDate } from "~/types/dateAndTimeTypes.ts";

/**
 * Finds the next available start date that doesn't conflict with existing date ranges
 * @param existingRanges Array of existing date ranges
 * @param startFrom Optional date to start searching from (defaults to today)
 * @returns A Date representing the next non-conflicting start date
 */
export function findNextAvailableStartDateForStaffing(
	existingRanges: DateRangeWithOptionalEndDate[],
	startFrom: Date = new Date(),
): Date {
	// If no ranges exist, return the starting date
	if (!existingRanges.length) {
		return startFrom;
	}

	// Sort ranges by start date
	const sortedRanges = [...existingRanges].sort((a, b) =>
		a.startDate.getTime() - b.startDate.getTime(),
	);

	// Find ranges that might affect our search
	// (start date >= startFrom OR no end date OR end date >= startFrom)
	const relevantRanges = sortedRanges.filter(range =>
		range.startDate >= startFrom ||
		!range.endDate ||
		(range.endDate && range.endDate >= startFrom),
	);

	if (!relevantRanges.length) {
		return startFrom;
	}

	let candidateDate = new Date(startFrom);
	let foundDate = false;

	// Check each range to see if our candidate date works
	while (!foundDate) {
		foundDate = true;

		for (const range of relevantRanges) {
			// Check if candidateDate conflicts with this range
			if (
				(!range.endDate && range.startDate <= candidateDate) ||
				(range.endDate && range.startDate <= candidateDate && range.endDate >= candidateDate)
			) {
				// Conflict found, move candidate date to day after this range's end
				if (range.endDate) {
					// If range has an end date, move to the day after
					candidateDate = new Date(range.endDate);
					candidateDate.setDate(candidateDate.getDate() + 1);
				} else {
					// If range has no end date, this approach won't work
					// Return today's date as fallback
					return new Date();
				}

				foundDate = false;
				break;
			}
		}
	}

	return candidateDate;
}