import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import RichTextEditor from "~/components/RichTextEditor";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createChangelog, updateChangelog } from "~/modules/changelog/api/changelog/changelogApiDispatchers.ts";
import type { Changelog } from "~/modules/changelog/api/changelog/changelogTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	version: string;
	date: Date;
	html: string;
}

type Props = {
	changelogData?: Changelog;
	onSuccess: () => void;
	onCancel: () => void;

};

const CreateOrUpdateChangelogForm: React.FunctionComponent<Props> = ({
	changelogData,
	onSuccess,
	onCancel,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const isUpdate = !!changelogData;
	const schema = useMemo(() => {
		return yup.object({
			version: yup.string().required(),
			date: yup.date().required(),
			html: yup.string().required(),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			version: changelogData?.version ?? "",
			date: changelogData?.date ? new Date(changelogData.date) : new Date(),
			html: changelogData?.html ?? "",
		};
	}, [changelogData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {
		try {
			setIsBusy(true);
			const createOrUpdateData = {
				...data,
				date: formatDateToYYYYMMDD(data.date),
			}
			if(isUpdate) {
				await updateChangelog(changelogData.id, createOrUpdateData);
			}
			else{
				await createChangelog(createOrUpdateData);
			}

			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage('Fehler beim Speichern des Changelogs');
		}
	}, [changelogData?.id, isUpdate, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>
					{isUpdate ? "Changelog-Eintrag bearbeiten" : "Changelog-Eintrag erstellen"}
				</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Input
						name="version"
						placeholder="2025.1"
						control={control}
						label="Version"
						type="text"
					/>
					<DatePickerInput
						name="date"
						control={control}
						label="Datum"
					/>
					<div className="col-span-2">
						<RichTextEditor name="html"
										control={control}
										label="Changelog" />
					</div>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateOrUpdateChangelogForm;
