import { useQueryClient } from "@tanstack/react-query";
import CreateOrUpdateChangelogForm
	from "modules/changelog/components/ChangelogView/components/CreateOrUpdateChangelogForm";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { ALL_CHANGELOGS_QUERY_KEY } from "~/modules/changelog/api/changelog/changelogQueries.ts";

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const CreateChangelogSidebar: React.FunctionComponent<Props> = ({
	isOpen,
	onClose,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: ALL_CHANGELOGS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 width="xl"
				 setOpen={() => onClose()}>
			<CreateOrUpdateChangelogForm onSuccess={handleSuccess} onCancel={onClose}/>
		</Sidebar>
	);
};

export default CreateChangelogSidebar;
