import Button from "components/buttons/Button";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Sidebar from "~/components/Sidebar";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import type { Location, UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import type { UsersActiveStaffingType } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import type {
	TimeTrackingExtendedType,
	TimeTrackingFormData,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import type { TimeTrackingType } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import Projects from "~/modules/timeTracking/components/components/Projects";
import CreateOrUpdateTimeTrackingForm from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm";
import {
	createTimeTrackingFormDefaultValues,
} from "~/modules/timeTracking/components/forms/CreateTimeTrackingForm/createTimeTrackingFormDefaultValues.ts";
import SyncUserAssetsForm from "~/modules/timeTracking/components/forms/SyncUserAssetsForm";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { getTimeTrackingTypeDisplayNameById } from "~/modules/timeTracking/utils/timeTrackingTypeUtils.ts";
import {
	getAvailableUserDeliverables,
	getAvailableUserLocations,
	getStaffingDetailsFromActiveStaffings,
} from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { getHoursAndMinutesFromMinutes } from "~/utils/dateAndTimeUtils.ts";

type UpdateTimeTrackingSidebarProps = {
	activeStaffings: UsersActiveStaffingType[];
	userDeliverables: UserAvailableDeliverableType[];
	userLocations: UserAvailableLocationType[];
	close: () => void;
	isOpen: boolean;
	date: null | Date,
	locations: Location[];
	timeTrackingData: TimeTrackingExtendedType | null;
	selectedUserId: User["id"];
	timeTrackingTypes: TimeTrackingType[];
	users: User[];
};

const UpdateTimeTrackingSidebar: React.FunctionComponent<UpdateTimeTrackingSidebarProps> = ({
	activeStaffings,
	userDeliverables,
	userLocations,
	close,
	isOpen,
	date,
	locations,
	selectedUserId,
	timeTrackingData,
	users,
}) => {
	const { t } = useTranslation();

	const [selectedTimeTrackingTypeId, setSelectedTimeTrackingTypeId] = useState<TimeTrackingTypeId>(TimeTrackingTypeId.Project);
	const [selectedStaffingId, setSelectedStaffingId] = useState<string | null>(null);
	const [currentFormName, setCurrentFormName] = useState<TimeTrackingFormNamesEnum | null>(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	const [currentTimeTrackingFormValues, setCurrentTimeTrackingFormValues] = useState<TimeTrackingFormData>(createTimeTrackingFormDefaultValues);

	useEffect(() => {
		if (timeTrackingData) {
			const { hours, minutes } = getHoursAndMinutesFromMinutes(timeTrackingData.minutes);

			const formData = {
				hours,
				minutes,
				locationName: timeTrackingData.locationName,
				text: timeTrackingData.text || "",
				isBillable: timeTrackingData.isBillable,
			};
			setSelectedStaffingId(timeTrackingData.staffingId || null);
			setSelectedTimeTrackingTypeId(timeTrackingData.timeTrackingTypeId);
			setCurrentTimeTrackingFormValues(formData);
		}
	}, [timeTrackingData]);

	const selectedStaffingData = useMemo(() => {
		if (selectedStaffingId && activeStaffings) {
			return getStaffingDetailsFromActiveStaffings(selectedStaffingId, activeStaffings);
		}

		return null;
	}, [selectedStaffingId, activeStaffings]);

	const selectedStaffingProjectId = selectedTimeTrackingTypeId === TimeTrackingTypeId.Project ? selectedStaffingData?.projectId : null;

	const closeAndReset = useCallback(() => {
		close();
		// reset state after closing animation ends
		setTimeout(() => {
			setCurrentTimeTrackingFormValues(createTimeTrackingFormDefaultValues);
			setSelectedTimeTrackingTypeId(TimeTrackingTypeId.Project);
			setSelectedStaffingId(null);
			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		}, 800);
	}, [close]);

	const handleStaffingIdClick = useCallback((value: string) => {
		setSelectedStaffingId(value);
		setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
	}, []);


	const selectedTimeTrackingTypeName = getTimeTrackingTypeDisplayNameById(selectedTimeTrackingTypeId);

	const availableLocations = useMemo(() => {
		return getAvailableUserLocations({
			userLocations,
			selectedStaffingProjectId,
			selectedTimeTrackingTypeId,
		});
	}, [userLocations, selectedStaffingProjectId, selectedTimeTrackingTypeId]);

	const availableDeliverables = useMemo(() => {
		return getAvailableUserDeliverables({
			userDeliverables,
			selectedStaffingProjectId,
			selectedTimeTrackingTypeId,
		});
	}, [userDeliverables, selectedStaffingProjectId, selectedTimeTrackingTypeId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeAndReset}>
			<div className="flex min-h-full w-full flex-col justify-start">
				{currentFormName === null &&
					<>
						<SidebarHeader>
							<SidebarHeaderHeadline>Projekt / Bestellung wählen</SidebarHeaderHeadline>
						</SidebarHeader>
						<SidebarContent>
							<Projects
								usersActiveStaffings={activeStaffings}
								disabledStaffingIds={[]}
								value={selectedStaffingId}
								onChange={handleStaffingIdClick}
							/>
						</SidebarContent>
						<SidebarFooter>
							<Button theme="none"
									onClick={closeAndReset}>
								{t(normalizeTKey("form:buttonTexts.cancel"))}
							</Button>
						</SidebarFooter>
					</>}
				{currentFormName === TimeTrackingFormNamesEnum.TIME_TRACKING_FORM &&
					<CreateOrUpdateTimeTrackingForm
						activeStaffings={activeStaffings}
						availableDeliverables={availableDeliverables}
						availableLocations={availableLocations}
						date={date}
						parentIsVisible={isOpen}
						initialValues={currentTimeTrackingFormValues}
						locations={locations}
						onSuccess={closeAndReset}
						setCurrentTimeTrackingFormValues={setCurrentTimeTrackingFormValues}
						selectedStaffingData={selectedStaffingData}
						selectedStaffingId={selectedStaffingId}
						selectedTimeTrackingTypeId={selectedTimeTrackingTypeId}
						selectedTimeTrackingTypeName={selectedTimeTrackingTypeName}
						selectedUserId={selectedUserId}
						setCurrentFormName={setCurrentFormName}
						timeTrackingId={timeTrackingData?.id}
						users={users} />}
				{currentFormName === TimeTrackingFormNamesEnum.LOCATION_FORM &&
					<SyncUserAssetsForm
						assetType="location"
						existingAssets={availableLocations}
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}
				{currentFormName === TimeTrackingFormNamesEnum.DELIVERABLE_FORM &&
					<SyncUserAssetsForm
						assetType="deliverable"
						existingAssets={availableDeliverables}
						userId={selectedUserId}
						projectId={selectedStaffingProjectId}
						projectTitle={selectedStaffingData?.projectTitle}
						setCurrentFormName={setCurrentFormName}
						timeTrackingTypeId={selectedTimeTrackingTypeId} />}
			</div>
		</Sidebar>
	);
};

export default UpdateTimeTrackingSidebar;
