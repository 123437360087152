import { useQueryClient } from "@tanstack/react-query";
import CreateOrUpdateChangelogForm
	from "modules/changelog/components/ChangelogView/components/CreateOrUpdateChangelogForm";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { ALL_CHANGELOGS_QUERY_KEY } from "~/modules/changelog/api/changelog/changelogQueries.ts";
import type { Changelog } from "~/modules/changelog/api/changelog/changelogTypes.ts";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	changelogData: Changelog | null;
};

const UpdateChangelogSidebar: React.FunctionComponent<Props> = ({
	isOpen,
	onClose,
	changelogData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: ALL_CHANGELOGS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 width="xl"
				 setOpen={() => onClose()}>
			{changelogData && <CreateOrUpdateChangelogForm changelogData={changelogData}
										 onSuccess={handleSuccess}
										 onCancel={onClose} />}
		</Sidebar>
	);
};

export default UpdateChangelogSidebar;
