import type { FunctionComponent } from "react";

import {
	statusIndicatorDotVariants,
} from "~/components/statusDisplay/StatusIndicatorDot/statusIndicatorDotVariants.ts";
import { getWorkingStatusThemeById } from "~/modules/project/utils/projectUtils.ts";

type Props = { statusId: string };

const StatusIndicatorDot: FunctionComponent<Props> = ({ statusId }) => {
	const theme = getWorkingStatusThemeById(statusId);

	return <div className={statusIndicatorDotVariants({ theme })} />;
};

export default StatusIndicatorDot;