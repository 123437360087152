import type { FunctionComponent } from "react";
import { useMemo } from "react";

import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import { ChartTypeValue } from "~/modules/humanResources/types/staffStatisticsTypes.ts";

type Props = {
	selectedChartType: ChartTypeValue;
	setSelectedChartType: (chartType: ChartTypeValue) => void
}

const ChartTypeSwitch: FunctionComponent<Props> = ({ selectedChartType, setSelectedChartType }) => {
	const buttons = useMemo(() => {
		let label = "button";

		return Object.keys(ChartTypeValue).map((key) => {
			switch (key) {
				case ChartTypeValue.sickDaysPerEmployee:
					label = "Krankheitstage pro Mitarbeiter:in";
					break;
				case ChartTypeValue.sickDaysAndEmployeeCount:
					label = "Krankheitstage und Mitarbeiteranzahl";
					break;
				case ChartTypeValue.fte:
					label = "FTE";
					break;
				case ChartTypeValue.remainingVacationDays:
					label = "Resturlaubstage";
					break;
			}
			return {
				label: label,
				value: key,
			};
		});
	}, []);

	return <div className="ml-auto w-80"><SelectPlain value={selectedChartType}
													  onChange={(value: string) => setSelectedChartType(value as ChartTypeValue)}
													  name="chartType"
													  optionsData={buttons}
	/></div>;
};

export default ChartTypeSwitch;