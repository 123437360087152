import type { FilterFn, Row } from "@tanstack/react-table";
import Decimal from "decimal.js-light";

import type { ColumnFilterType } from "~/types/tanstackTableTypes.ts";


export const customArrayIncludesSome: FilterFn<any> = (row, columnId, value) => {
	if (!Array.isArray(value)) {
		return false;
	}

	if (value.length === 0) {
		return true;
	}
	return value.includes(row.original[columnId]);
};

export const customStringEquals: FilterFn<any> = (row, columnId, value) => {
	if (value === null) {
		return true;
	}
	return value === (row.original[columnId]);
};

export const customDateFilter = (row: any, columnId: string, filterValue: [Date, Date]) => {
	const rowDate = new Date(row.getValue(columnId));
	const [startDate, endDate] = filterValue;

	if (startDate && endDate) {
		return rowDate >= startDate && rowDate <= endDate;
	} else if (startDate) {
		return rowDate >= startDate;
	} else if (endDate) {
		return rowDate <= endDate;
	}

	return true;
};


export const createColumnFilterInputHandler = (
	filters: ColumnFilterType[],
	setFilters: React.Dispatch<React.SetStateAction<ColumnFilterType[]>>,
) => (key: string, value: any) => {

	const newFilters = filters.map(filter =>
		filter.id === key ? { ...filter, value } : filter,
	);
	setFilters(newFilters);
};

export const getColumnFilterValueForKey = (key: string, filters: ColumnFilterType[]): any => {
	const filter = filters.find(f => f.id === key);
	return filter ? filter.value : "";
};

export function sanitiseColumnFilters(defaultColumnFilters: ColumnFilterType[],
	columnFilters: ColumnFilterType[],
	dateFields?: string[]) {
	const columnFilterIds = defaultColumnFilters.map(columnFilter => columnFilter.id);
	const sanitisedColumnFilter = columnFilters.filter(columnFilter => columnFilterIds.includes(columnFilter.id));
	if (sanitisedColumnFilter.length !== columnFilterIds.length) {
		defaultColumnFilters.forEach(defaultColumnFilter => {
			if (!sanitisedColumnFilter.find(columnFilter => columnFilter.id === defaultColumnFilter.id)) {
				sanitisedColumnFilter.push(defaultColumnFilter);
			}
		});
	}

	if (dateFields) {
		sanitisedColumnFilter.forEach(filter => {
			if (dateFields.includes(filter.id)) {
				filter.value = filter.value.map((date: string) => date ? new Date(date) : null);
			}
		});
	}
	return sanitisedColumnFilter;
}

// sorting
export const decimalJsSort = <T extends Record<string, unknown>>(
	rowA: Row<T>,
	rowB: Row<T>,
	columnId: string,
): number => {
	const a = rowA.getValue(columnId);
	const b = rowB.getValue(columnId);

	// Handle null cases first
	if (a === null && b === null) return 0;
	if (a === null) return -1;
	if (b === null) return 1;

	// Type check for Decimal
	if (!(a instanceof Decimal) || !(b instanceof Decimal)) {
		throw new Error("Values must be instances of Decimal.js");
	}

	return a.comparedTo(b);
};
