import { format } from "date-fns";
import { de } from "date-fns/locale";
import Decimal from "decimal.js-light";
import ExcelJS from "exceljs";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";
import { DEFAULT_EXCEL_TABLE_STYLE } from "~/constants/excelConsts.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import type { ProjectSummary } from "~/modules/project/api/project/projectTypes.ts";
import { WorkingStatusId } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";

import type { ProjectsExcelSheetData } from "./projectsExcelDef.tsx";
import { monthlyReportTableColumnDefs } from "./projectsExcelDef.tsx";

function DownloadProjectsExcelButton({ projectsSorted, visibleProjectIds }: {
	projectsSorted: ProjectSummary[],
	visibleProjectIds: string[],
}) {

	const {
		data: clientData,
		isLoading: clientIsLoading,
	} = useAllClients();

	const {
		data: companyData,
		isLoading: companyIsLoading,
	} = useAllCompanies();

	const generateExcel = async () => {
		if (clientIsLoading || companyIsLoading || !clientData || !companyData) return;
		const workBookTitle = `NORD.NORD Projekte ${format(new Date(), "dd.MM.yyyy", { locale: de })}`;
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(workBookTitle);

		// Add headers
		const headers = monthlyReportTableColumnDefs.map(col => col.header);
		const headerRow = worksheet.addRow(headers);

		// Style header row
		headerRow.font = { bold: true };
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };

		headerRow.height = 45; // Adjust this value as needed

		const sheetData: ProjectsExcelSheetData[] = projectsSorted.filter((project) => visibleProjectIds.includes(project.id)).map((project) => {
			const projectIsClosed = project.projectPhaseStatusesIds.every(item => item === WorkingStatusId.Closed|| item === WorkingStatusId.Cancelled);

			let clientDisplayName = "Unbekannt";
			let companyDisplayName = "Unbekannt";
			const client = clientData.find(client => client.id === project.clientId);

			if (client) {
				clientDisplayName = client.displayName;
				const company = companyData.find(company => company.id === client.companyId);
				if (company) {
					companyDisplayName = company.displayName;
				}
			}

			return {
				budgetCents: project.budgetCentsPlanned,
				budgetCentsActual: project.budgetCentsTracked,
				budgetDays: new Decimal(project.manDaysPlanned).toNumber(),
				budgetDaysActual: minutesToWorkdays(project.minutesTracked),
				clientDisplayName,
				companyDisplayName,
				endDate: new Date(project.endDate),
				isActive: projectIsClosed ? "Nein" : "Ja",
				projectTitle: project.title,
				startDate: new Date(project.startDate),
			};
		});

		// Add data and apply formatting
		sheetData.forEach((row) => {
			const excelRow = worksheet.addRow(
				monthlyReportTableColumnDefs.map(col => {
					const value = row[col.accessor as keyof ProjectsExcelSheetData];

					if (value === null) return null; // Handle null values

					if (col.type === "currency" && typeof value === "number") {
						return value / 100; // Convert cents to euros
					}

					return value;
				}),
			);

			// Apply cell formatting
			monthlyReportTableColumnDefs.forEach((col, colIndex) => {
				const cell = excelRow.getCell(colIndex + 1);

				switch (col.type) {
					case "currency":
						cell.numFmt = "#,##0.00 \"€\"";
						break;
					case "date":
						cell.numFmt = "dd.mm.yyyy";
						break;
					case "number":
						cell.numFmt = "#,##0.00";
						break;
				}

				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;

			// @ts-expect-error - TS doesn't know about the includeEmpty option
			column.eachCell({ includeEmpty: true }, (cell) => {
				let cellLength = 0;
				if (cell.value) {
					if (cell.type === ExcelJS.ValueType.Date) {
						// For date cells, use the formatted string length (e.g. "dd.mm.yyyy")
						cellLength = 10;
					} else if (typeof cell.value === "string" && cell.value.includes("\n")) {
						// For multi-line cells, find the longest line
						const lines = cell.value.toString().split("\n");
						cellLength = Math.max(...lines.map(line => line.length));
					} else {
						cellLength = cell.value.toString().length;
					}
				} else {
					cellLength = 10; // Default length for empty cells
				}
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});

			column.width = Math.min(maxLength + 2, 30); // Set width to content, but max 30
		});
		// Ensure all columns are visible
		worksheet.views = [
			{ state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2", activeCell: "A2" },
		];

		const headerRowNumber = 1; // Since we add headers first
		const tableEndRowNumber = headerRowNumber + sheetData.length;
		const tableEndColumnLetter = String.fromCharCode(64 + headers.length);
		const tableName = "ProjectsTable";
		const tableRef = `A${headerRowNumber}:${tableEndColumnLetter}${tableEndRowNumber}`;

		const table = worksheet.addTable({
			name: tableName,
			ref: tableRef,
			columns: monthlyReportTableColumnDefs.map(col => ({ name: col.header })),
			rows: sheetData.map(row =>
				monthlyReportTableColumnDefs.map(col => {
					const value = row[col.accessor as keyof ProjectsExcelSheetData];
					if (value === null) return null;
					if (col.type === "currency" && typeof value === "number") {
						return value / 100;
					}
					return value;
				}),
			),
			style: {
				theme: DEFAULT_EXCEL_TABLE_STYLE,
				showRowStripes: true,
			},
			totalsRow: false,
		});

		// Make table sortable and filterable
		// @ts-expect-error type definitions are missing these properties
		table.table.columns.forEach(column => {
			column.filterButton = true;
		});

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = workBookTitle + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton disabled={visibleProjectIds.length === 0}
								buttonText=""
								onClick={generateExcel} />;
}

export default DownloadProjectsExcelButton;