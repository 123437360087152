import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import Headline from "~/components/Headline";
import Section from "~/components/sections/Section";
import { HR_STATISTICS_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import HrMainPageTabs from "~/modules/humanResources/components/HrMainPageTabs";
import ChartTypeSwitch from "~/modules/humanResources/components/StaffStatisticsView/components/ChartTypeSwitch";
import EmployeeReportsChart
	from "~/modules/humanResources/components/StaffStatisticsView/components/EmployeeReportsChart";
import RemainingVacationDaysTable
	from "~/modules/humanResources/components/StaffStatisticsView/components/RemainingVacationDaysTable";
import SickDaysPerEmployeeTable
	from "~/modules/humanResources/components/StaffStatisticsView/components/SickDaysPerEmployeeTable";
import type { HrStatisticsPageState } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import { ChartTypeValue } from "~/modules/humanResources/types/staffStatisticsTypes.ts";


const defaultPageState: HrStatisticsPageState = {
	selectedChartType: ChartTypeValue.sickDaysAndEmployeeCount,
	selectedYear: new Date().getFullYear().toString(),

};

const StaffStatisticsView: FunctionComponent = () => {
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: HR_STATISTICS_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});

	const [selectedChartType, setSelectedChartType] = useState<ChartTypeValue>(pageState.selectedChartType);
	const [selectedYear, setSelectedYear] = useState<string>(pageState.selectedYear);

	useEffect(() => {
		setPageState(prevState => ({ ...prevState, selectedChartType, selectedYear }));
	}, [selectedChartType, selectedYear, setPageState]);


	const contentIsTable = [ChartTypeValue.sickDaysPerEmployee, ChartTypeValue.remainingVacationDays].includes(selectedChartType);
	const contentIsChart = !contentIsTable;

	return (
		<div className="grid h-screen grid-rows-[auto_auto_auto_1fr] overflow-hidden">
			<BreadcrumbsSection pages={["HR", "Reports"]}
								className="bg-white" />
			<Section className="z-50 bg-white pb-4 pt-2">
				<div className="flex w-full items-center justify-between gap-x-4">
					<Headline type="h2">HR-Reports</Headline>

					<ChartTypeSwitch selectedChartType={selectedChartType}
									 setSelectedChartType={setSelectedChartType} />
				</div>
			</Section>
			<Section>
				<HrMainPageTabs selectedTabName="statistics" />
			</Section>
			<>
				{contentIsChart && <EmployeeReportsChart selectedChartType={selectedChartType} />}

				{selectedChartType === ChartTypeValue.sickDaysPerEmployee &&
					<SickDaysPerEmployeeTable setSelectedYear={setSelectedYear}
											  selectedYear={selectedYear} />}

				{selectedChartType === ChartTypeValue.remainingVacationDays &&
					<RemainingVacationDaysTable />}
			</>
		</div>
	);
};

export default StaffStatisticsView;