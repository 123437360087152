import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getVacationDaysReport,
} from "~/modules/humanResources/api/vacationDaysReport/vacationDaysReportApiDispatchers.ts";

export const VACATION_DAYS_REPORT_QUERY_KEY = ["vacation-days-report"];

export const useVacationDaysReport = () => useQuery({
	queryKey: VACATION_DAYS_REPORT_QUERY_KEY,
	queryFn: () => getVacationDaysReport().then(getDataFromResponse),
});