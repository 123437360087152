import type { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";

import { useSickDaysReport } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportQueries.ts";
import { ChartTypeValue } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

type ProcessedChartData = {
	chartData: {
		[year: string]: ChartData<"bar">;
	};
	maxValue: number;
}

type Props = {
	selectedChartType: ChartTypeValue;
};

const EmployeeReportsChart: FunctionComponent<Props> = ({ selectedChartType }) => {
	const {
		data: sickDaysReportData,
		isLoading: sickDaysReportIsLoading,
	} = useSickDaysReport();

	const processedChartData = useMemo(() => {
		if (sickDaysReportData) {
			const years = Object.keys(sickDaysReportData);
			const processedData: ProcessedChartData["chartData"] = {};
			let maxValue = 0;

			years.forEach(year => {
				const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
				const sickDays = months.map(month => sickDaysReportData[year][month]?.sickDays.total || 0);
				const staffCounts = months.map(month => sickDaysReportData[year][month]?.staffCount || 0);
				const ftes = months.map(month => sickDaysReportData[year][month]?.fte || 0);

				let dataSets: ChartDataset<"bar">[] = [];

				switch (selectedChartType) {
					case ChartTypeValue.sickDaysAndEmployeeCount:
						maxValue = Math.max(maxValue, ...sickDays, ...staffCounts);
						dataSets = [
							{
								label: "Krankheitstage",
								data: sickDays,
								backgroundColor: "rgb(239,68,68)",
							},
							{
								label: "Mitarbeiter:innen",
								data: staffCounts,
								backgroundColor: "rgb(67,152,238)",
							},
						];
						break;
					case ChartTypeValue.fte:
						maxValue = Math.max(maxValue, ...ftes, ...staffCounts);
						dataSets = [
							{
								label: "Mitarbeiter:innen",
								data: staffCounts,
								backgroundColor: "rgb(67,152,238)",
							},
							{
								label: "FTE",
								data: ftes,
								backgroundColor: "rgb(30,53,96)",
							},
						];
						break;
				}


				processedData[year] = {
					labels: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktoober", "November", "Dezember"],
					datasets: dataSets,
				};
			});

			return { chartData: processedData, maxValue };
		}
		return null;
	}, [sickDaysReportData, selectedChartType]);

	const chartOptions: ChartOptions<"bar"> = {
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				max: processedChartData?.maxValue,
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: false,
				text: "Krankheitstage",
			},
			datalabels: {
				display: false,
			},
		},
	};


	return <div className="mx-auto size-full max-w-7xl overflow-y-auto px-2 pb-10 pt-4 sm:px-6 lg:px-8">
		{sickDaysReportIsLoading && <LoadingPage />}
		{!sickDaysReportIsLoading && processedChartData &&
			<div className="grid grid-cols-2 gap-8">
				{Object.entries(processedChartData.chartData).reverse().map(([year, yearData]) => (
					<div key={year}>
						<h2>{year}</h2>
						<Bar options={chartOptions}
							 data={yearData} />
					</div>
				))}
			</div>}
	</div>;


};

export default EmployeeReportsChart;