import type { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";

export enum TimeTrackingFormNamesEnum {
	TIME_TRACKING_FORM = "timeTrackingForm",
	DELIVERABLE_FORM = "deliverableForm",
	LOCATION_FORM = "locationForm",
}

export type TimeTrackingTotalsByType = {
	[TimeTrackingTypeId.Internal]: number,
	[TimeTrackingTypeId.Project]: number,
	[TimeTrackingTypeId.Sales]: number,
	[TimeTrackingTypeId.PartTime]: number,
}

export type StaffMembersTimeTrackingsExcelData = {
	companyDisplayName: string;
	date: Date;
	minutesTracked: number;
	orderTitle: string;
	phaseTitle: string;
	projectTitle: string;
	timeTrackingTypeDisplayName: string;
};