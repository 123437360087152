import clsx from "clsx";
import Status from "components/statusDisplay/Status";
import type React from "react";
import { generatePath, NavLink } from "react-router-dom";

import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import type { ClientContactPersonType } from "~/modules/client/api/clientContactPerson/clientContactPersonTypes.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import type { WorkingStatusType as WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

interface Props {
	data: Project;
	workingStatuses: WorkingStatusType[];
	contacts?: ClientContactPersonType[];
}

const ProjectCompactInfoItem: React.FC<Props> = ({
	data: { id, title, clientContactPersonId, isActive, endDate },
	contacts,
}) => {
	const clientContactPerson = undefined !== contacts ? contacts.find((c) => clientContactPersonId === c.id) : null;
	return (
		<li className="w-full">
			<NavLink
				to={generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId: id })}
				className={clsx(
					"group flex transform-gpu items-center justify-between gap-x-6 rounded-lg bg-white p-5 shadow transition-transform duration-200",
					"hover:shadow-md",
				)}
			>
				<div className="flex items-center gap-x-3">
					<p className="text-sm font-semibold leading-6 text-gray-900 group-hover:text-secondary-500">{title}</p>
					<Status theme={isActive ? "success" : "error"}>
						{isActive ? "Aktiv" : "Inaktiv"}
					</Status>
				</div>
				<div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
					<p className="whitespace-nowrap">
						Enddatum: {endDate ? formatDateWithGermanMonth(new Date(endDate)): "k.A."}
					</p>
					<svg viewBox="0 0 2 2" className="size-0.5 fill-current">
						<circle cx={1} cy={1} r={1} />
					</svg>
					<p className="truncate">
						Kontaktperson {clientContactPerson ? clientContactPerson.fullName : <i>n/a</i>}
					</p>
				</div>
			</NavLink>
		</li>
	);
};

export default ProjectCompactInfoItem;
