import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useState } from "react";

import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteChangelog } from "~/modules/changelog/api/changelog/changelogApiDispatchers.ts";
import { ALL_CHANGELOGS_QUERY_KEY } from "~/modules/changelog/api/changelog/changelogQueries.ts";
import type { Changelog } from "~/modules/changelog/api/changelog/changelogTypes.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type DeleteTimeTrackingModalProps = {
	isOpen: boolean;
	onClose: () => void;
	changelog: Changelog | null;
};

const ListLabelValueElement: React.FC<{ label: string; value: string }> = ({ label, value }) => (
	<div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
		<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{value}</dd>
	</div>
);

const DeleteChangelogModal: React.FunctionComponent<DeleteTimeTrackingModalProps> = ({
	isOpen,
	onClose,
	changelog,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		if (!changelog) return;
		try {
			setBusy(true);
			await deleteChangelog(changelog.id);
			await queryClient.refetchQueries({ queryKey: ALL_CHANGELOGS_QUERY_KEY });

			onClose();
		} catch (error) {
			console.log(error);
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Eintrag löschen`,
		bodyText: `Möchtest Du diesen Eintrag wirklich löschen?`,
		deleteButtonText: `Eintrag löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{changelog && <dl className="mb-10 divide-y divide-gray-300">
				<ListLabelValueElement label="Version"
									   value={"V " + changelog.version} />
				<ListLabelValueElement label="Datum"
									   value={formatDateWithGermanMonth(new Date(changelog.date))} />
			</dl>}
		</DeleteEntityView>
	);
};

export default DeleteChangelogModal;
