import clsx from "clsx";
import { t } from "i18next";
import type React from "react";

import SearchInput from "~/components/formElements/SearchInput";
import MonthAndYearNavigation from "~/components/MonthAndYearNavigation";
import type { MonthAndYearNavigationHandlerPropsType } from "~/components/MonthAndYearNavigation/MonthAndYearNavigation.tsx";
import { statusVariants } from "~/components/statusDisplay/Status/statusVariants.ts";
import MonthlyInvoiceStatusFilter
	from "~/modules/billing/components/MonthlyBillingReportView/components/MonthlyInvoiceStatusFilter";
import {
	getInvoicingStatusTheme,
	invoicingStatusFilterOptions,
} from "~/modules/billing/utils/monthlyInvoicingUtils.ts";
import type { FormInputOption } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

interface MonthlyBillingReportFilterBarProps {
	displayedOrdersCount: number;
	month: string;
	monthSelectOptions: FormInputOption[];
	onChangeStatus: (state: any) => void;
	onGoToNextMonthClick: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onGoToPrevMonthClick: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onGoToTodayClick: () => void;
	onMonthSelectChange: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	onYearSelectChange: ({ month, year }: MonthAndYearNavigationHandlerPropsType) => void;
	search:string;
	setSearch: (search: string) => void;
	statusFilter: string[];
	totalOrdersCount: number;
	year: string;
	yearSelectOptions: FormInputOption[];
}

const MonthlyBillingReportFilterBar: React.FC<MonthlyBillingReportFilterBarProps> = ({
	displayedOrdersCount,
	month,
	monthSelectOptions,
	onChangeStatus,
	onGoToNextMonthClick,
	onGoToPrevMonthClick,
	onGoToTodayClick,
	onMonthSelectChange,
	onYearSelectChange,
	search,
	setSearch,
	statusFilter,
	totalOrdersCount,
	year,
	yearSelectOptions,
}) => {
	return (
		<section aria-labelledby="filter-heading"
				 className="flex w-full grow">
			<div className="w-full">
				<div className="mx-auto flex max-w-7xl items-center gap-x-5">
					<MonthAndYearNavigation
						onGoToNextMonthClick={onGoToNextMonthClick}
						onGoToPrevMonthClick={onGoToPrevMonthClick}
						onGoToTodayClick={onGoToTodayClick}
						onMonthSelectChange={onMonthSelectChange}
						onYearSelectChange={onYearSelectChange}
						month={month}
						year={year}
						monthSelectOptions={monthSelectOptions}
						yearSelectOptions={yearSelectOptions}
					/>
					<div>
						<SearchInput value={search} onChange={setSearch} placeholder="Suche nach Firma..." />
					</div>
					<div className="ml-auto">
						<MonthlyInvoiceStatusFilter statusFilter={statusFilter}
													onChange={onChangeStatus} />
					</div>
				</div>
			</div>
			{(statusFilter.length > 0 || search)&& (
				<div className="absolute left-0 w-full bg-gray-100 shadow-xl shadow-gray-100"
					 style={{ top: 73 }}>
					<div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
						<h3 className="text-sm font-medium text-gray-500">
							Aktive Filter
							<span className="sr-only">, active</span>
						</h3>

						<div aria-hidden="true"
							 className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

						<div className="sm:ml-4 sm:mt-0">
							<div className="-m-1 flex flex-wrap items-center gap-x-1">
								{invoicingStatusFilterOptions.filter(invoicingStatus => statusFilter.includes(invoicingStatus.name)).map((invoicingStatusOptions,
									index) => {
									const theme = getInvoicingStatusTheme(invoicingStatusOptions.name);
									const statusDisplayName = t(normalizeTKey(`entities:orderInvoicingStatus.${invoicingStatusOptions.name}`));
									return <span
										key={"statusFilter" + index}
										className={clsx(`inline-flex items-center 
										rounded-full
										py-1.5 pl-3 pr-2 
										text-sm font-medium`, statusVariants({
											theme,
											borderRadius: "full",
										}))}
									>
										{statusDisplayName}
										<button
											type="button"
											className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => onChangeStatus(invoicingStatusOptions.name)}
										>
											<span className="sr-only">Remove filter for status option</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>;
								})}
								{search &&
									<span className="inline-flex items-center rounded-full bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
									>
										<span className="mr-2 text-gray-500">Firma:</span><span>{search}</span>
										<button
											type="button"
											className="ml-1 inline-flex size-4 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
											onClick={() => setSearch("")}
										>
											<span className="sr-only">Remove filter for {search}</span>
											<svg
												className="size-2"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 8 8"
											>
												<path strokeLinecap="round"
													  strokeWidth="1.5"
													  d="M1 1l6 6m0-6L1 7" />
											</svg>
										</button>
									</span>
								}
							</div>
						</div>
						<span className="ml-auto text-xs text-gray-400">{displayedOrdersCount} von {totalOrdersCount} Bestellungen sichtbar</span>
					</div>
				</div>
			)}

		</section>
	);
};

export default MonthlyBillingReportFilterBar;
