import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

export const formInputBaseVariants = tv({
    base: "block w-full rounded-md",
    variants: {
        disabled: {
            true: "cursor-not-allowed border-opacity-50 bg-opacity-30 text-opacity-20 placeholder:text-opacity-30",
        },
        inputSize: {
            xs: "rounded-[0.2rem] px-1 py-0.5 text-xs leading-tight",
            sm: "px-1.5 py-1 text-sm",
            md: "px-3 py-2.5 text-sm",
        },
        textAlign: {
            left: "text-left",
            right: "text-right",
        },
        shadow: {
            true: "shadow-sm",
        },
        theme: {
            dark:"bg-gray-100 text-gray-600",
            light: "bg-white text-gray-900 ring-1 ring-inset ring-gray-300"
        },
        focusRing:{
            true:"ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500",
        },
        hasPrefix:{
            true:"pl-10",
        }
    },
    defaultVariants: {
        disabled:false,
        inputSize: "md",
        text: "left",
        theme: "light",
        focusRing: true
    }
});


export type FormInputBaseVariants = VariantProps<typeof formInputBaseVariants>;
