import type { Row, SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import Decimal from "decimal.js-light";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import Headline from "~/components/Headline";
import Table from "~/components/Table";
import { STAFF_MEMBER_VACATION_LEDGER_PATH } from "~/constants/appRoute.ts";
import { useVacationDaysReport } from "~/modules/humanResources/api/vacationDaysReport/vacationDaysReportQueries.ts";
import DownloadVacationDaysReportExcelButton
	from "~/modules/humanResources/components/StaffStatisticsView/components/RemainingVacationDaysTable/components/DownloadVacationDaysReportExcelButton";
import {
	remainingVacationDaysTableColumnDefs,
} from "~/modules/humanResources/components/StaffStatisticsView/components/RemainingVacationDaysTable/remainingVacationDaysTableColumnDefs.tsx";
import type { RemainingVacationDaysTableData } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;

const RemainingVacationDaysTable: FunctionComponent = () => {
	const navigate = useNavigate();
	const {
		data: vacationDayReportData,
		isLoading: vacationDayReportIsLoading,
	} = useVacationDaysReport();


	const tableData: RemainingVacationDaysTableData[] = useMemo(() => {
		if (!vacationDayReportData) return [];
		console.log({ vacationDayReportData });
		return vacationDayReportData.map((reportData) => ({
			...reportData,
			currentCareerLevelDisplayName: reportData.currentCareerLevelId ? getCareerLevelDisplayNameById(reportData.currentCareerLevelId) : "unbekannt",
			remainingDaysLastYear: reportData.wasEmployedAtEndOfLastYear ? new Decimal(reportData.remainingDaysLastYear) : null,
			remainingDaysCurrentYear: reportData.isEmployedToday ? new Decimal(reportData.remainingDaysCurrentYear) : null,
		}));
	}, [vacationDayReportData]);

	const [sorting, setSorting] = useState<SortingState>([{ id: "staffMemberFullName", desc: false }]);

	const handleRowClick = useCallback((row: Row<RemainingVacationDaysTableData>) => {
		navigate(generatePath(STAFF_MEMBER_VACATION_LEDGER_PATH, { staffMemberId: row.original.staffMemberId }));
	}, [navigate]);

	const table = useReactTable({
		data: tableData,
		columns: remainingVacationDaysTableColumnDefs,
		state: {
			sorting,
		},
		meta: {
			onRowClick: handleRowClick,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});
	return <div className="mx-auto grid size-full max-w-7xl grid-rows-[auto_1fr] overflow-hidden px-2 pb-10 pt-4 sm:px-6 lg:px-8">
		{(vacationDayReportIsLoading) ? <LoadingPage /> :
			<>
				<div className="flex items-center justify-between pb-4">
					<Headline type="h4">Resturlaubstage pro Mitarbeiter:in</Headline>
					<div className="ml-auto" />
					<DownloadVacationDaysReportExcelButton buttonText={lastYear.toString()}
														   disabled={vacationDayReportIsLoading}
														   isCurrentYearReport={false}
														   vacationReportData={vacationDayReportData} />
					<DownloadVacationDaysReportExcelButton buttonText="Aktuell"
														   disabled={vacationDayReportIsLoading}
														   isCurrentYearReport={true}
														   vacationReportData={vacationDayReportData} />
				</div>
				<div className="isolate overflow-hidden">
					<Table table={table}
						   verticalBorders={true} />
				</div>
			</>
		}
	</div>;
};

export default RemainingVacationDaysTable;