import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { WorkingStatus } from "components/statusDisplay/Status";
import DeleteProjectPhaseModal from "modules/project/components/ProjectDetailsView/components/DeleteProjectPhaseModal";
import type React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import ContentWrapper from "~/components/ContentWrapper";
import SectionHeading from "~/components/headings/SectionHeading";
import CalendarIcon from "~/components/icons/CalendarIcon";
import CaptainFemaleIcon from "~/components/icons/CaptainFemaleIcon";
import GlobeIcon from "~/components/icons/GlobeIcon";
import ManIcon from "~/components/icons/ManIcon";
import { useAllCountries } from "~/modules/country/api/country/countryQueries.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useProjectsOrders } from "~/modules/project/api/order/orderQueries.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries.ts";
import CreateOrderSidebar from "~/modules/project/components/ProjectDetailsView/components/CreateOrderSidebar";
import Orders from "~/modules/project/components/ProjectDetailsView/components/Orders";
import PhaseBudgetStatsSection
	from "~/modules/project/components/ProjectDetailsView/components/PhaseDetailsSection/components/PhaseBudgetStatsSection";
import PhaseInfoBoxElement
	from "~/modules/project/components/ProjectDetailsView/components/PhaseDetailsSection/components/PhaseInfoBoxElement";
import UpdateProjectPhaseSidebar
	from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectPhaseSidebar";
import UpdateProjectPhaseStatusSidebar
	from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectPhaseStatusSidebar";
import { formatProjectPhaseTitle } from "~/modules/project/utils/projectUtils.ts";
import { minutesToWorkdays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

interface PhasesProps {
	clientId: string;
	phaseNumber: number;
	projectId: string;
	showBudget: boolean;

}

const PhaseDetailsSection: React.FC<PhasesProps> = ({
	clientId,
	projectId,
	phaseNumber,
	showBudget,
}) => {
	const { t } = useTranslation();
	const [showCreateOrderSidebar, setShowCreateOrderSidebar] = useState<boolean>(false);
	const [showUpdatePhaseSidebar, setShowUpdatePhaseSidebar] = useState<boolean>(false);
	const [showDeletePhaseSidebar, setShowDeletePhaseSidebar] = useState<boolean>(false);
	const [showUpdateStatusSidebar, setShowUpdateStatusSidebar] = useState(false);

	const { data: allCountries } = useAllCountries();
	const { data: allLocations } = useAllLocations();
	const { data: allUsers } = useAllUsers();
	const { data: allWorkingStatuses } = useAllWorkingStatuses();
	const { data: projectOrdersData } = useProjectsOrders(projectId);
	const { data: projectPhasesData } = useProjectsProjectPhases(projectId);
	const { data: projectRoles } = useProjectProjectRoles(projectId);


	const phaseData = useMemo(() => {
		if (projectPhasesData) {
			return projectPhasesData.find((p) => p.phaseNumber === phaseNumber);
		}
		return undefined;
	}, [projectPhasesData, phaseNumber]);

	const {
		data: projectsStaffingsData,
	} = useProjectsStaffings(projectId);

	const phaseHasTimeTrackings = useMemo(() => {
		if (projectsStaffingsData && phaseData) {
			return projectsStaffingsData.filter((s) => s.projectPhaseId === phaseData.id && s.minutesTracked > 0).length > 0;
		}
		return false;
	}, [projectsStaffingsData, phaseData]);


	const [phasesOrders, phaseHasConfirmedOrders, confirmedBudgetCents, confirmedManDays] = useMemo(() => {
		let phaseHasConfirmedOrders = false;
		let confirmedBudgetCents = 0;
		let confirmedManDays = 0;
		let phasesOrders: Order[] = [];
		if (projectOrdersData && phaseData?.id) {
			phasesOrders = projectOrdersData.filter((order) => order.projectPhaseId === phaseData.id);

			phasesOrders.forEach((order) => {
				if (order.confirmedAt !== null) {
					phaseHasConfirmedOrders = true;
					confirmedBudgetCents += order.budgetCents;
					confirmedManDays += parseFloat(order.manDays);
				}
			});
		}

		return [phasesOrders, phaseHasConfirmedOrders, confirmedBudgetCents, confirmedManDays];
	}, [projectOrdersData, phaseData?.id]);

	if (phaseData === undefined || projectsStaffingsData === undefined || allUsers === undefined || projectOrdersData === undefined || projectRoles === undefined) return null;

	const projectPhaseId = phaseData.id;

	const {
		title,
		budgetCentsConfirmed,
		budgetCentsPlanned,
		budgetCentsTracked,
		locationId,
		countryId,
		startDate,
		endDate,
		manDaysConfirmed,
		manDaysPlanned,
		minutesTracked,
		phaseManagedBy,
		phaseLeadBy,
		workingStatusId,
	} = phaseData;

	const placeOfPerformanceLocation = null === locationId ? "-" : getOneOfCollection(allLocations, locationId);
	const placeOfPerformanceCountry = null === countryId ? "-" : getOneOfCollection(allCountries, countryId, "displayName");

	const placeOfPerformance = !placeOfPerformanceLocation && !placeOfPerformanceCountry ? "-" : `${placeOfPerformanceLocation?.displayName}, ${placeOfPerformanceCountry}`;

	const workingStatusName = getOneOfCollection(allWorkingStatuses, workingStatusId, "name");
	const phaseManagerFullName = allUsers.find((user) => phaseManagedBy === user.id)?.fullName;
	const phaseLeadFullName = allUsers.find((user) => phaseLeadBy === user.id)?.fullName;
	const manDaysTracked = minutesToWorkdays(minutesTracked);

	const budgetCents = phaseHasConfirmedOrders ? budgetCentsConfirmed : budgetCentsPlanned;
	const manDays = phaseHasConfirmedOrders ? parseFloat(manDaysConfirmed) : parseFloat(manDaysPlanned);

	return (
		<ContentWrapper className="w-full overflow-x-auto pb-10 pt-2">
			<SectionHeading theme="none">
				<div className="flex items-center gap-x-2">
					<h2
						className="font-bold text-secondary-500 ">
						<span className="text-[1.6rem]">Phase {formatProjectPhaseTitle(title, phaseNumber)}</span>
					</h2>

					<div className={"ml-auto flex justify-between"}>
						<div className="ml-auto flex items-center justify-end gap-2">
							<WorkingStatus id={workingStatusId}>
								{t(normalizeTKey("entities:workingStatus." + workingStatusName))}
							</WorkingStatus>
							<ButtonWithPopover
								items={[
									{
										label: "Phase bearbeiten",
										onClick: () => setShowUpdatePhaseSidebar(true),
										icon: PencilIcon,
									},
									{
										label: "Status ändern",
										onClick: () => setShowUpdateStatusSidebar(true),
										icon: CheckIcon,
									},
									{
										label: "Phase löschen",
										onClick: () => setShowDeletePhaseSidebar(true),
										disabled: phaseHasTimeTrackings,
										icon: TrashIcon,
									},
								]}
								theme="dark"
							/>
						</div>
					</div>
				</div>
			</SectionHeading>
			<div
				className="mt-4 transition-opacity duration-200">
				<dl className="mt-4 flex flex-row gap-x-5">
					<Card>
						<div className="flex flex-row gap-x-2">
							<div className="flex flex-col gap-y-4 px-2">
								<PhaseInfoBoxElement label="Laufzeit"
													 icon={<CalendarIcon className="w-8  fill-primary-700" />}>
									{startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a"}
								</PhaseInfoBoxElement>
								<PhaseInfoBoxElement label="Leistungsort"
													 icon={<GlobeIcon className="w-8  fill-primary-700" />}>
									{placeOfPerformance}
								</PhaseInfoBoxElement>
							</div>
							<div className="mx-4 h-full border-r-DEFAULT border-primary-700" />
							<div className="flex flex-col gap-y-4">
								<PhaseInfoBoxElement label="Angebotsmanager"
													 icon={
														 <ManIcon className={clsx("w-7", phaseManagerFullName ? "fill-primary-700" : "fill-gray-300")} />}>
									<span className={clsx(!phaseManagerFullName && "text-gray-400")}>{phaseManagerFullName || "--"}</span>
								</PhaseInfoBoxElement>
								<PhaseInfoBoxElement label="Projektleitung"
													 icon={
														 <CaptainFemaleIcon className={clsx("w-7", phaseLeadFullName ? "fill-primary-700" : "fill-gray-300")} />}>
									<span className={clsx(!phaseLeadFullName && "text-gray-400")}>{phaseLeadFullName || "--"}</span>
								</PhaseInfoBoxElement>
							</div>
						</div>
					</Card>
					<PhaseBudgetStatsSection
						budgetCentsPlanned={budgetCents}
						budgetCentsTracked={budgetCentsTracked}
						manDaysPlanned={manDays}
						manDaysTracked={manDaysTracked}
						showBudget={showBudget}
					/>
				</dl>
			</div>
			<div className="mt-8 w-full">
				<Orders
					key={phaseData.id}
					allUsers={allUsers}
					clientId={clientId}
					onNewOrderClick={() => setShowCreateOrderSidebar(true)}
					phasesOrders={phasesOrders}
					projectPhaseId={projectPhaseId}
					projectRoles={projectRoles}
					staffings={projectsStaffingsData}
					showBudget={showBudget}
				/>
			</div>

			<CreateOrderSidebar
				isOpen={showCreateOrderSidebar}
				setOpen={() => setShowCreateOrderSidebar(false)}
				clientId={clientId}
				projectId={projectId}
				projectPhaseId={projectPhaseId}
			/>

			<UpdateProjectPhaseSidebar
				confirmedBudgetCents={confirmedBudgetCents}
				confirmedManDays={confirmedManDays}
				isOpen={showUpdatePhaseSidebar}
				onClose={setShowUpdatePhaseSidebar}
				phaseHasConfirmedOrders={phaseHasConfirmedOrders}
				projectId={projectId}
				projectPhaseData={phaseData}
			/>
			<DeleteProjectPhaseModal
				isOpen={showDeletePhaseSidebar}
				projectId={projectId}
				projectPhaseId={projectPhaseId}
				projectPhaseTitle={phaseData.title}
				onClose={() => setShowDeletePhaseSidebar(false)} />
			<UpdateProjectPhaseStatusSidebar
				currentPhaseStatusId={phaseData.workingStatusId}
				phaseHasTimeTrackings={phaseHasTimeTrackings}
				isOpen={showUpdateStatusSidebar}
				projectId={projectId}
				projectPhaseId={projectPhaseId}
				onClose={() => setShowUpdateStatusSidebar(false)} />
		</ContentWrapper>
	);
};

export default PhaseDetailsSection;
