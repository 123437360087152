import type { FunctionComponent } from "react";

import { useAllChangelogs } from "~/modules/changelog/api/changelog/changelogQueries.ts";
import ChangelogView from "~/modules/changelog/components/ChangelogView";
import LoadingPage from "~/pages/LoadingPage.tsx";


const ChangelogPage: FunctionComponent = () => {

	const {
		data: allChangelogsData,
		isLoading: allChangelogsIsLoading,
	} = useAllChangelogs();

	if (allChangelogsIsLoading) return <LoadingPage />;
	if (!allChangelogsData) return null;
	return <ChangelogView changelogs={allChangelogsData} />;
};

export default ChangelogPage;