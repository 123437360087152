import {
	ChatBubbleBottomCenterTextIcon,
	DocumentTextIcon,
	InformationCircleIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import Status from "components/statusDisplay/Status";
import Decimal from "decimal.js-light";
import CreateStaffingSidebar from "modules/project/components/ProjectDetailsView/components/CreateStaffingSidebar";
import DeleteOrderModal from "modules/project/components/ProjectDetailsView/components/DeleteOrderModal";
import DeleteStaffingModal from "modules/project/components/ProjectDetailsView/components/DeleteStaffingModal";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import SectionHeading from "~/components/headings/SectionHeading";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import HorizontalStats from "~/components/HorizontalStats";
import type { HorizontalStat } from "~/components/HorizontalStats/horizontalStatsTypes.ts";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import { useClientsContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import CreateAdditionalStaffingForUserSidebar
	from "~/modules/project/components/ProjectDetailsView/components/CreateAdditionalStaffingForUserSidebar";
import MonthlyTimeTrackingReport
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/MonthlyTimeTrackingReport";
import StaffingsTable
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable";
import type {
	StaffingsTableData,
} from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/StaffingsTable/staffingsTableTypes.ts";
import UpdateOrderSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateOrderSidebar";
import UpdateStaffingSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateStaffingSidebar";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";
import getOneOfCollection from "~/utils/getOneOfCollection.ts";

interface OrderProps {
	allUsers: User[];
	clientId: string;
	orderData: Order;
	projectRoles: ProjectRole[];
	staffings: Staffing[];
	totalOrders: number;
	showBudget: boolean;
}

type VisibleSections = "staffings" | "details" | "comments" | "monthly-overview";

const OrderDetails: FunctionComponent<OrderProps> = ({
	allUsers,
	clientId,
	orderData,
	projectRoles,
	showBudget,
	staffings,
	totalOrders,
}) => {
	const [visibleSection, setVisibleSection] = useState<VisibleSections | null>("staffings");

	const {
		budgetCents,
		budgetCentsTracked,
		manDays: manDaysPlanned,
		minutesTracked,
		clientContactPersonId,
		comment,
		confirmedAt,
		endDate,
		invoiceByMail,
		invoiceRecipientId,
		isBilledMonthly,
		isFixedPrice,
		isForeignCountry,
		isSkillBased,
		orderNumber,
		paymentTargetDays,
		projectId,
		startDate,
		title,
		travelExpensesIncluded,
	} = orderData;
	const [showCreateStaffingSidebar, setShowCreateStaffingSidebar] = useState(false);
	const [showUpdateOrderSidebar, setShowUpdateOrderSidebar] = useState(false);
	const [showDeleteOrderSidebar, setShowDeleteOrderSidebar] = useState(false);
	const [selectedStaffingIdForUpdate, setSelectedStaffingIdForUpdate] = useState<string | null>(null);
	const [selectedStaffingIdForDeletion, setSelectedStaffingIdForDeletion] = useState<string | null>(null);
	const [showIneffectiveStaffings, setShowIneffectiveStaffings] = useState<boolean>(false);
	const [userIdToCreateStaffingFor, setUserIdToCreateStaffingFor] = useState<string | null>(null);
	const { data: contactPersonsData } = useClientsContactPersons(clientId);
	const { data: invoiceRecipientsData } = useClientsInvoiceRecipients(clientId);

	const invoiceRecipientName = useMemo(() => {
		if (invoiceRecipientId) {
			const invoiceRecipient = getOneOfCollection(invoiceRecipientsData, invoiceRecipientId);
			return invoiceRecipient?.displayName + " (Zentraler Rechnungsempfänger)";
		} else if (clientContactPersonId) {
			const contactPerson = getOneOfCollection(contactPersonsData, clientContactPersonId);
			return contactPerson?.fullName + " (Kontaktperson)";
		}

		return "n/a";
	}, [contactPersonsData, invoiceRecipientsData, invoiceRecipientId, clientContactPersonId]);

	const orderDetailsData = [
		{ label: "Personentage", content: manDaysPlanned },
		{ label: "Budget", content: formatCentsToCurrency(budgetCents) },
		{
			label: "Laufzeit",
			content: startDate && endDate ? `${formatDateRange(new Date(startDate), new Date(endDate))}` : "n/a",
		},
		{
			label: "Rechnungsempfänger",
			content: <>{invoiceRecipientName}</>,
		},
		{
			label: "Abrechnungsart",
			content: (
				<>
					{isFixedPrice ? "Festpreis" : "Tagessatz"}, {isBilledMonthly ? "monatlich" : "individuell"}
				</>
			),
		},
		{ label: "Reisekosten", content: travelExpensesIncluded ? "included" : "On top" },
		{ label: "Rechnung", content: invoiceByMail ? "Mail" : "Post" },
		{ label: "Abrechnung Rolle", content: isSkillBased ? "Projektrolle" : "Skillklasse" },
		{ label: "Zahlungsziel", content: paymentTargetDays + " Tage" },

		{ label: "Leistung/Rechnung Ausland", content: isForeignCountry ? "ja" : "nein" },
	];

	const popOverItems = useMemo(() => {
		const items = [
			{
				label: "Bestellung bearbeiten",
				onClick: () => setShowUpdateOrderSidebar(true),
				icon: PencilIcon,
			}];

		if (minutesTracked === 0 && totalOrders > 1) {
			items.push({
				label: "Bestellung löschen",
				onClick: () => setShowDeleteOrderSidebar(true),
				icon: TrashIcon,
			});
		}

		return items;

	}, [minutesTracked, totalOrders]);

	const [staffingsTableData, manDaysAssigned, budgetCentsAssigned, ineffectiveStaffingsCount] = useMemo(() => {
		if (staffings && allUsers && projectRoles) {
			let manDaysAssigned = 0;
			let budgetCentsAssigned = 0;
			let ineffectiveStaffingsCount = 0;
			const tableData: StaffingsTableData[] = [];
			staffings.forEach((staffing) => {
				const user = allUsers.find((u) => staffing.userId === u.id)!;
				const projectRoleDisplayName = projectRoles.find((pr) => staffing.projectRoleId === pr.id)?.displayName;

				const manDaysTracked = Math.ceil(staffing.minutesTracked / 60 / 8 * 100) / 100;
				const budgetCentsPlanned = staffing.manDays * staffing.dailyRateCents;
				const careerLevelId = staffing.careerLevelId || user.careerLevelId;

				const careerLevelDisplayName = careerLevelId ? getCareerLevelDisplayNameById(careerLevelId) : null;
				const startDate = new Date(staffing.startDate);
				const endDate = staffing.endDate ? new Date(staffing.endDate) : null;

				const budgetTrackedPercentage = Math.round(staffing.budgetCentsTracked / budgetCentsPlanned * 100);
				const manDaysTrackedPercentage = Math.round(manDaysTracked / staffing.manDays * 100);
				tableData.push({
					avatarImage: user.avatarImage,
					budgetCentsPlanned,
					budgetCentsTracked: staffing.budgetCentsTracked,
					budgetTrackedPercentage,
					careerLevelDisplayName,
					dailyRateCents: staffing.dailyRateCents,
					endDate,
					id: staffing.id,
					isActive: staffing.isActive,
					isBillable: staffing.isBillable,
					isCurrent: staffing.isCurrent,
					manDaysPlanned: staffing.manDays,
					manDaysTracked,
					manDaysTrackedPercentage,
					roleDisplayName: projectRoleDisplayName,
					startDate,
					userFullName: user.fullName,
					userFirstName: user.firstName,
					userId: user.id,
					userIsActive: user.isActive,
					userLastName: user.lastName,
				});
				if (staffing.isBillable) {
					manDaysAssigned += staffing.manDays;
					budgetCentsAssigned += new Decimal(staffing.manDays).mul(staffing.dailyRateCents).toNumber();
				}

				if (!staffing.isCurrent) {
					ineffectiveStaffingsCount++;
				}

			});


			return [tableData, manDaysAssigned, budgetCentsAssigned, ineffectiveStaffingsCount];
		}
		return [[], 0, 0, 0];
	}, [staffings, allUsers, projectRoles]);

	const [orderBudgetStats, orderManDaysStats] = useMemo(() => {
		const orderBudgetStats: HorizontalStat[] = [
			{
				label: "Bestellvolumen",
				value: formatCentsToCurrency(budgetCents, 0),
			},
			{
				label: "Im Staffing zugewiesen",
				value: formatCentsToCurrency(budgetCentsAssigned, 0),
				isHighlighted: budgetCentsAssigned > budgetCents,
			},
			{
				label: "Verfügt",
				value: formatCentsToCurrency(budgetCentsTracked, 0),
			},
			{
				label: "Rest",
				value: formatCentsToCurrency(budgetCents - budgetCentsTracked, 0),
				isHighlighted: budgetCentsTracked > budgetCents,
			},
		];

		const orderManDaysStats: HorizontalStat[] = [
			{
				label: "Bestellvolumen",
				value: parseFloat(manDaysPlanned).toFixed(0) + " PT",
			},
			{
				label: "Im Staffing zugewiesen",
				value: manDaysAssigned.toFixed(0) + " PT",
				isHighlighted: manDaysAssigned > parseFloat(manDaysPlanned),
			},
			{
				label: "Verfügt",
				value: Math.round(minutesTracked / 60 / 8).toFixed(0) + " PT",
			},
			{
				label: "Rest",
				value: (parseFloat(manDaysPlanned) - Math.round(minutesTracked / 60 / 8)).toFixed(0) + " PT",
				isHighlighted: budgetCentsTracked > budgetCents,
			},
		];

		return [orderBudgetStats, orderManDaysStats];
	}, [budgetCents, budgetCentsAssigned, budgetCentsTracked, manDaysAssigned, manDaysPlanned, minutesTracked]);

	const totalManDaysUnassigned = parseFloat(manDaysPlanned) - manDaysAssigned;
	const totalBudgetUnassigned = budgetCents - budgetCentsAssigned;

	const handleTabClick = (tabIndex: string) => {
		if (tabIndex === visibleSection) {
			setVisibleSection(null);
		} else {
			setVisibleSection(tabIndex as VisibleSections);
		}
	};

	return (
		<>
			<section className="flex flex-col">
				<div className="rounded-t-lg bg-gray-200 p-4 text-gray-500 transition-colors duration-200">
					<div className="flex justify-between gap-8">
						<Headline type="h4"
								  className="mb-0">
							{title}
						</Headline>
						<div className="flex items-center justify-end gap-2">
							<Status theme={null === confirmedAt ? "error" : "success"}>
								{null === confirmedAt ? "Nicht bestätigt" : "Bestätigt"}
							</Status>
							<Status>
								Bestellnummer: {orderNumber || <span className="ml-1 opacity-40">nicht vorhanden</span>}
							</Status>
							<ButtonWithPopover
								theme="dark"
								items={popOverItems}
							/>
						</div>
					</div>
				</div>
				<div className="relative rounded-b-lg bg-gray-400/5">
					<div className="mx-4 mt-4 flex gap-x-4">
						<div className="min-w-0 grow">
							<Card>
								<HorizontalStats className="py-2"
												 stats={orderBudgetStats} />
							</Card>
						</div>
						<div className="min-w-0 grow">
							<Card>
								<HorizontalStats className="py-2"
												 stats={orderManDaysStats} />
							</Card>
						</div>
					</div>
					<div className="px-4">
						<SectionHeading theme={undefined === visibleSection ? "none" : "dark"}>
							<div className="flex w-full items-center justify-between">
								<SectionHeadingTabs
									tabs={[
										{
											name: `Staffing (${staffings.length})`,
											value: "staffings",
											icon: <UsersIcon className="w-5" />,
											active: visibleSection === "staffings",
										},
										{
											name: "Rechnungen",
											value: "monthly-overview",
											icon: <DocumentTextIcon className="w-5" />,
											active: visibleSection === "monthly-overview",
										},
										{
											name: "Bestelldetails",
											value: "details",
											icon: <InformationCircleIcon className="w-5" />,
											active: visibleSection === "details",
										},
										{
											name: `Kommentare (${!comment ? 0 : 1})`,
											value: "comments",
											icon: <ChatBubbleBottomCenterTextIcon className="w-5" />,
											active: visibleSection === "comments",
										},
									]}
									onTabClick={handleTabClick}
								/>
								{"staffings" === visibleSection && <div className="flex items-center gap-x-4">
									{ineffectiveStaffingsCount > 0 &&
										<SwitchPlain inputSize="xs"
													 label={`Ungültige anzeigen (${ineffectiveStaffingsCount})`}
													 checked={showIneffectiveStaffings}
													 onChange={() => setShowIneffectiveStaffings(!showIneffectiveStaffings)} />}
									<Button size="sm"
											onClick={() => setShowCreateStaffingSidebar(true)}>Neues
										Staffing</Button></div>}
							</div>
						</SectionHeading>
					</div>
					<div>


						{"staffings" === visibleSection &&
							<div className="px-4 py-3">
								<StaffingsTable tableData={staffingsTableData}
												showIneffectiveStaffings={showIneffectiveStaffings}
												onDeleteStaffingClick={setSelectedStaffingIdForDeletion}
												onUpdateStaffingClick={setSelectedStaffingIdForUpdate}
												onAddAdditionalStaffingClick={setUserIdToCreateStaffingFor}
												projectId={projectId}
												showBudget={showBudget} />
							</div>}

						{"details" === visibleSection && (
							<dl className="m-4 divide-y divide-gray-100">
								{orderDetailsData.map(({ label, content }, dIndex) => (
									<div key={dIndex}
										 className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{content}
										</dd>
									</div>
								))}
							</dl>
						)}

						{"comments" === visibleSection && (
							<>
								{comment ? (
									<div className="m-4 text-sm">{comment}</div>
								) : (
									<div className="m-4 text-xs text-gray-500">Keine Kommentare vorhanden.</div>
								)}
							</>
						)}

						{"monthly-overview" === visibleSection && <MonthlyTimeTrackingReport
							orderId={orderData.id}
							ordersStaffings={staffings}
							projectId={orderData.projectId} />}

					</div>
				</div>
			</section>
			<UpdateOrderSidebar
				isOpen={showUpdateOrderSidebar}
				setOpen={setShowUpdateOrderSidebar}
				clientId={clientId}
				projectId={orderData.projectId}
				orderData={orderData}
			/>

			<CreateStaffingSidebar
				isOpen={showCreateStaffingSidebar}
				onClose={() => setShowCreateStaffingSidebar(false)}
				orderData={orderData}
				totalBudgetUnassigned={totalBudgetUnassigned}
				totalManDaysUnassigned={totalManDaysUnassigned}
			/>

			<CreateAdditionalStaffingForUserSidebar allStaffings={staffingsTableData}
													isOpen={!!userIdToCreateStaffingFor}
													onClose={() => setUserIdToCreateStaffingFor(null)}
													orderData={orderData}
													totalBudgetUnassigned={totalBudgetUnassigned}
													totalManDaysUnassigned={totalManDaysUnassigned}
													userId={userIdToCreateStaffingFor}
			/>

			<UpdateStaffingSidebar
				isOpen={!!selectedStaffingIdForUpdate}
				close={() => setSelectedStaffingIdForUpdate(null)}
				projectId={orderData.projectId}
				allStaffings={staffingsTableData}
				staffingId={selectedStaffingIdForUpdate}
				totalBudgetUnassigned={totalBudgetUnassigned}
				totalManDaysUnassigned={totalManDaysUnassigned}
			/>

			<DeleteStaffingModal
				isOpen={!!selectedStaffingIdForDeletion}
				staffingId={selectedStaffingIdForDeletion}
				projectId={orderData.projectId}
				onCloseClick={() => setSelectedStaffingIdForDeletion(null)}
			/>
			<DeleteOrderModal
				isOpen={showDeleteOrderSidebar}
				orderId={orderData.id}
				orderTitle={orderData.title}
				projectId={orderData.projectId}
				onCloseClick={() => setShowDeleteOrderSidebar(false)}
			/>
		</>
	);
};

export default OrderDetails;
