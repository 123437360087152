import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { endOfDay, endOfMonth, isAfter, isBefore, isSameDay, startOfMonth, subMonths } from "date-fns";
import type { FunctionComponent } from "react";
import { useCallback, useEffect } from "react";

import Button from "~/components/buttons/Button";
import { DatePickerInputPlain } from "~/components/formElements/DatePickerInput/DatePickerInput.tsx";
import { MonthPickerInputPlain } from "~/components/formElements/MonthPickerInput/MonthPickerInput.tsx";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";

type Props = {
	filterValue: Date[];
	maxDate: Date | null;
	minDate: Date | null;
	onChange: (value: (Date | null)[]) => void;
	setUseVariableDateRange: (value: boolean) => void;
	useVariableDateRange: boolean;

};

const DateFilter: FunctionComponent<Props> = ({
	filterValue,
	maxDate,
	minDate,
	onChange,
	setUseVariableDateRange,
	useVariableDateRange,
}) => {
	const handleMonthPickerChange = useCallback((date: Date) => {
		onChange([startOfMonth(date), endOfMonth(date)]);
	}, [onChange]);

	const prevButtonDisabled = minDate ? subMonths(filterValue[0], 1) <= minDate : false;
	const nextButtonDisabled = maxDate ? filterValue[1] >= maxDate : false;

	useEffect(() => {
		if (!useVariableDateRange && (!isSameDay(filterValue[0], startOfMonth(filterValue[0])) || !isSameDay(filterValue[1], endOfMonth(filterValue[0])))) {
			onChange([startOfMonth(filterValue[0]), endOfMonth(filterValue[0])]);
		}
	}, [filterValue, onChange, useVariableDateRange]);

	const handleStartDateChange = useCallback((startDate: Date | null) => {
		if (!useVariableDateRange) return;
		let newEndDate: Date | null = filterValue[1];
		if (!!filterValue[1] && startDate !== null && isAfter(startDate, filterValue[1])) {
			newEndDate = null;
		}
		onChange([startDate, newEndDate]);
	}, [filterValue, onChange, useVariableDateRange]);

	const handleEndDateChange = useCallback((endDate: Date | null) => {
		if (!useVariableDateRange) return;
		let newStartDate: Date | null = filterValue[0];
		if (!!filterValue[0] && endDate !== null && isBefore(endDate, filterValue[0])) {
			newStartDate = null;
		}
		onChange([newStartDate, endDate ? endOfDay(endDate): null]);
	}, [filterValue, onChange, useVariableDateRange]);

	const handleGoToNextMonthClick = useCallback(() => {
		const nextMonth = new Date(filterValue[0]);
		nextMonth.setMonth(nextMonth.getMonth() + 1);
		onChange([startOfMonth(nextMonth), endOfMonth(nextMonth)]);
	}, [filterValue, onChange]);

	const handleGoToPrevMonthClick = useCallback(() => {
		const prevMonth = new Date(filterValue[0]);
		prevMonth.setMonth(prevMonth.getMonth() - 1);
		onChange([startOfMonth(prevMonth), endOfMonth(prevMonth)]);
	}, [filterValue, onChange]);

	return <div className="flex items-center gap-x-4">
		<SwitchPlain checked={useVariableDateRange}
					 onChange={() => setUseVariableDateRange(!useVariableDateRange)}
					 labelOn="Zeitraum"
					 labelOff="Monatsübersicht" />
		{!useVariableDateRange &&
			<div className="flex items-center justify-between gap-1">
				<Button size="sm"
						theme="none"
						onClick={handleGoToPrevMonthClick}
						disabled={prevButtonDisabled}>
					<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
				</Button>
				<div className="flex min-w-32 items-center justify-center">
					<MonthPickerInputPlain value={filterValue[0]}
										   clearable={false}
										   maxDate={maxDate || undefined}
										   minDate={minDate || undefined}
										   name="date"
										   onChange={(date) => handleMonthPickerChange(date!)} />
				</div>
				<Button size="sm"
						theme="none"
						onClick={handleGoToNextMonthClick}
						disabled={nextButtonDisabled}>
					<ChevronRightIcon className="size-5 hover:fill-accent-600" />
				</Button>
			</div>}
		{useVariableDateRange && <div className="flex flex-row gap-x-2">
			<div className="w-48">
				<DatePickerInputPlain name="startDate"
									  clearable={false}
									  maxDate={maxDate || undefined}
									  minDate={minDate || undefined}
									  value={filterValue[0]}
									  placeholder="Startdatum"
									  onChange={handleStartDateChange} />
			</div>
			<div className="w-48">
				<DatePickerInputPlain name="endDate"
									  clearable={false}
									  maxDate={maxDate || undefined}
									  minDate={minDate || undefined}
									  value={filterValue[1]}
									  placeholder="Enddatum"
									  onChange={handleEndDateChange}
				/>
			</div>


		</div>}

	</div>;
};

export default DateFilter;