import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import Card from "~/components/Card";
import Section from "~/components/sections/Section";
import { useAuth } from "~/contexts/AuthContext";
import type { Changelog } from "~/modules/changelog/api/changelog/changelogTypes.ts";
import CreateChangelogSidebar from "~/modules/changelog/components/ChangelogView/components/CreateChangelogSidebar";
import DeleteChangelogModal from "~/modules/changelog/components/ChangelogView/components/DeleteChangelogModal";
import UpdateChangelogSidebar from "~/modules/changelog/components/ChangelogView/components/UpdateChangelogSidebar";
import { RoleNames } from "~/types/entityNames.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	changelogs: Changelog[];
};

const ChangelogView: FunctionComponent<Props> = ({ changelogs }) => {
	const { hasAnyRole } = useAuth();
	const [showCreateChangelogSidebar, setShowCreateChangelogSidebar] = useState<boolean>(false);
	const [changelogDataToUpdate, setChangelogDataToUpdate] = useState<Changelog | null>(null);
	const [changelogDataToDelete, setChangelogDataToDelete] = useState<Changelog | null>(null);
	const useCanManageChangelog = hasAnyRole(RoleNames.Admin);
	const changelogEntries = useMemo(() => {
		return changelogs.sort(byObjectProperty("date", "desc")).map((changelog) => {
			return <Card key={changelog.id}>
				<div className="group relative">
					{useCanManageChangelog &&
						<div className="absolute right-0 top-0 opacity-0 transition-opacity group-hover:opacity-100">
							<div className="flex items-center">
								<Button theme="white"
										onClick={() => setChangelogDataToUpdate(changelog)}>
									<PencilIcon className="size-5 text-primary-400" />
								</Button>
								<Button theme="white"
										onClick={() => setChangelogDataToDelete(changelog)}>
									<TrashIcon className="size-5 text-danger-500" />
								</Button>
							</div>
						</div>}
					<div className="flex items-baseline justify-center gap-x-2 pb-2 pt-4">
						<span className="text-2xl font-bold">V {changelog.version}</span>
						<span className="text-sm font-semibold">({format(new Date(changelog.date), "dd.MM.yy")})</span>

					</div>
					<div className="prose max-w-none"
						 dangerouslySetInnerHTML={{ __html: changelog.html }} />
				</div>
			</Card>;
		});
	}, [changelogs, useCanManageChangelog]);

	return <div className="h-screen w-full">
		<Section scrollable={true}>
			<div className="flex flex-col gap-y-10 py-10">
				{useCanManageChangelog && <ButtonNewItem size="sm"
														 theme="inline"
														 onClick={() => setShowCreateChangelogSidebar(true)}>Neuer
					Eintrag</ButtonNewItem>}
				{changelogEntries}
			</div>
		</Section>
		<CreateChangelogSidebar isOpen={showCreateChangelogSidebar}
								onClose={() => setShowCreateChangelogSidebar(false)} />
		<UpdateChangelogSidebar changelogData={changelogDataToUpdate}
								isOpen={!!changelogDataToUpdate}
								onClose={() => setChangelogDataToUpdate(null)} />
		<DeleteChangelogModal isOpen={!!changelogDataToDelete}
							  onClose={() => setChangelogDataToDelete(null)}
							  changelog={changelogDataToDelete} />
	</div>;
};

export default ChangelogView;