import type { Editor } from "@tiptap/react";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

const colors = [
	{ name: "Black", value: "#0c0a09" },
	{ name: "Red", value: "#b91c1c" },
	{ name: "Blue", value: "#213d75" },
	{ name: "Blue", value: "#042440" },
	{ name: "Green", value: "#15803d" },
];

const RichTextEditorColorPicker = ({
	editor,
}: {
	editor: Editor;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const pickerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, []);

	const applyColor = (color: string) => {
		// First unset any existing color to avoid stacking of color spans
		editor.chain().focus().unsetColor().run();

		// Then apply the new color
		editor.chain().focus().setColor(color).run();
	};

	return (
		<div className="relative"
			 ref={pickerRef}>
			<button
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={clsx("text-danger-600 my-2 flex size-6 items-center justify-center gap-1 rounded text-xs hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-blue-500")}
				title="Text color"
			>
				<span>A</span>
			</button>
			{isOpen && (
				<div className="absolute left-0 top-0 z-10 mt-1 flex items-center gap-x-0.5 rounded border bg-white p-2 text-xs shadow-lg">
					{colors.map((color) => (
						<button
							key={color.value}
							className="block w-full rounded px-2 py-1 text-left text-white hover:bg-opacity-30"
							style={{ background: color.value }}
							onMouseDown={(e) => {
								// Prevent the mousedown event from bubbling up
								e.preventDefault();
								e.stopPropagation();
							}}
							onClick={(e) => {
								e.preventDefault();
								applyColor(color.value);
								setIsOpen(false);
							}}
						>
							A
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default RichTextEditorColorPicker;