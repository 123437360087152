import { createColumnHelper } from "@tanstack/react-table";
import clsx from "clsx";

import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type { RemainingVacationDaysTableData } from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { decimalJsSort } from "~/utils/tanStackTableUtils.ts";

const columnHelper = createColumnHelper<RemainingVacationDaysTableData>();

const previousYear = new Date().getFullYear()- 1;

export const remainingVacationDaysTableColumnDefs = [
	columnHelper.accessor("staffMemberFullName", {
		header: "Mitarbeiter:in",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
		cell: (props) => props.getValue(),
	}),
	columnHelper.accessor("currentCareerLevelDisplayName", {
		header: "Karrierestufe",
		meta: {
			headerTextAlign: "left",
			cellTextAlign: "left",
		},
		cell: (props) => props.getValue(),
	}),
	columnHelper.accessor("remainingDaysLastYear", {
		header: "Resturlaub Ende "+ previousYear,
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		sortingFn: decimalJsSort,
		cell: (props) => {
			const initialValue = props.getValue();
			let value = EMPTY_VALUE_PLACEHOLDER;
			let isNegative = false;

			if (initialValue !== null) {
				isNegative = initialValue.isNegative();
				value = formatNumberWithComma(initialValue.toNumber(), 1);
			}
			return <div className="flex justify-center"><div className={clsx("w-8 text-right", isNegative && "font-bold text-red-500")}>{value}</div></div>
		}
	}),
	columnHelper.accessor("remainingDaysCurrentYear", {
		header: "Resturlaub aktuell",
		meta: {
			headerTextAlign: "center",
			cellTextAlign: "center",
		},
		sortingFn: decimalJsSort,
		cell: (props) => {
			const initialValue = props.getValue();
			let value = EMPTY_VALUE_PLACEHOLDER;
			let isNegative = false;

			if (initialValue !== null) {
				isNegative = initialValue.isNegative();
				value = formatNumberWithComma(initialValue.toNumber(), 1);
			}
			return <div className="flex justify-center"><div className={clsx("w-8 text-right", isNegative && "font-bold text-red-500")}>{value}</div></div>
		}
	}),

];