import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { TabBarVariantName } from "~/components/TabBar/tabBarTypes.ts";

export const tabBarTabVariants = tv({
	base: "flex cursor-pointer items-center gap-2 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-bold [&:not(:first-child)]:ml-8",
	variants: {
		active: {
			true: "",
			false: "",
		},
		theme: {
			[TabBarVariantName.Default]: "",
			[TabBarVariantName.Light]: "",
		},
	},
	compoundVariants: [
		{
			theme: TabBarVariantName.Default,
			active: true,
			class: "border-primary-500 text-primary-500",
		},
		{
			theme: TabBarVariantName.Default,
			active: false,
			class: "border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700",
		},
		/*		{
					theme: TabBarVariantName.Light,
					active: true,
					css: "bg-gray-200 text-gray-800",
				},
				{
					theme: TabBarVariantName.Light,
					active: false,
					css: "bg-gray-200 text-gray-800",
				},*/
	],
	defaultVariants: {
		active: false,
		theme: TabBarVariantName.Default,
	},
});


export type TabBarTabVariants = VariantProps<typeof tabBarTabVariants>;
