import { HoverCard } from "@mantine/core";
import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = PropsWithChildren & {
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	manDaysPlanned: number;
	manDaysTracked: number;
	portalId: string;
	showBudget?: boolean;
	width?: number;
};

const StaffingStatsHoverCard: FunctionComponent<Props> = ({
	budgetCentsPlanned,
	budgetCentsTracked,
	children,
	manDaysPlanned,
	manDaysTracked,
	portalId,
	showBudget = false,
	width = 200,
}) => {
	const unit = showBudget ? "" : " Tage";
	const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
	const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
	const formatter = showBudget ? formatCentsToCurrency : (value: number) => formatNumberWithComma(value, 2);
	const balance = plannedValue - trackedValue;
	return (
		<HoverCard
			width={width}
			withinPortal={true}
			portalProps={{ target: "#" + portalId }}
			openDelay={0}
			closeDelay={20}
			shadow="md"
			position="top"
		>
			<HoverCard.Target>{children}</HoverCard.Target>
			<HoverCard.Dropdown
				className={clsx(
					"rounded-md bg-primary-700 text-sm text-gray-900 shadow-md",
				)}
			>
				<div className={clsx("grid grid-cols-[auto_1fr] gap-x-4 px-4 py-3 text-white", balance < 0 ? "bg-red-500" : "bg-success-600")}>
					<div>Plan</div>
					<div className="text-right">{formatter(plannedValue)}{unit}</div>
					<div>Verbrauch</div>
					<div className="text-right">{formatter(trackedValue)}{unit}</div>
					<div className="col-span-2 my-0.5 h-px w-full bg-white"/>
					<div className="font-bold">Rest</div>
					<div className="text-right font-bold">{formatter(balance)}{unit}</div>
				</div>
			</HoverCard.Dropdown>
		</HoverCard>
	);
};

export default StaffingStatsHoverCard;