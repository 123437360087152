import { tv } from "tailwind-variants";

import type { VariantProps } from "~/types/tailwindVariants";

export const statusIndicatorDotVariants = tv({
	base: "size-2 rounded-full ring-1 ring-inset",
	variants: {
		theme: {
			highlight: "bg-blue-300 ring-blue-600/40",
			success: "bg-green-300 ring-green-600/40",
			default: "bg-gray-300 ring-gray-500/10",
			info: "bg-yellow-300 ring-yellow-600/40",
			error: "bg-red-300 ring-red-600/40",
		}
	},
	defaultVariants: {
		borderRadius: "default",
		theme: "default",
	},
});


export type StatusIndicatorDotVariants = VariantProps<typeof statusIndicatorDotVariants>;

