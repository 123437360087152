import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Popover } from "@mantine/core";
import clsx from "clsx";
import type { FunctionComponent, MouseEvent, ReactElement } from "react";
import { useCallback, useRef, useState } from "react";

import useOnClickOutside from "~/hooks/useOnClickOutside.ts";

export type PopoverItem = {
	label: string;
	icon?: ReactElement | any;
	iconColorClassName?: string;
	onClick: (argument?: unknown) => void;
	disabled?: boolean;
}

interface Props {
	items: PopoverItem[];
	theme?: "default" | "dark" | "light" | "lightest"
	icon?: "dots" | "plus"
}

const ButtonWithPopover: FunctionComponent<Props> = ({items, theme = "default", icon = "dots"}) => {
	const [open, setOpen] = useState(false);
	const popoverItemsContainerRef = useRef(null);
	useOnClickOutside(popoverItemsContainerRef, () => setOpen(false));

	const handleMenuButtonClick = useCallback((event: MouseEvent) => {
		event.stopPropagation();
		setOpen((prev) => !prev);
		return event;
	}, []);

	const onItemClick = useCallback((event: MouseEvent<HTMLButtonElement>, onClick: PopoverItem["onClick"]) => {
		event.stopPropagation();
		setOpen(false);
		onClick();
	}, []);

	return (
		<Popover position="bottom-end"
				 opened={open}
		>
			<Popover.Target>
				<button type="button"
						className={clsx("flex items-center justify-center", {
							"text-white hover:text-gray-900": "default" === theme,
							"text-gray-500 hover:text-gray-700": "dark" === theme,
							"text-gray-800 hover:text-gray-600": "light" === theme,
							"text-gray-600 hover:text-gray-600": "light" === theme && "plus" === icon,
							"text-white hover:text-gray-400": "lightest" === theme
						})}
						onClick={(event) => handleMenuButtonClick(event)}
				>
					<span className="sr-only">Open options</span>
					{icon === "dots" ? <EllipsisVerticalIcon className="size-5"
															 aria-hidden="true"/> : <PlusIcon className="size-4"
																							  aria-hidden="true"/>}
				</button>
			</Popover.Target>


			<Popover.Dropdown
			>
				<div ref={popoverItemsContainerRef}>
					{items.map(({label, icon, onClick, iconColorClassName, disabled}, itemIndex) => {
						const Icon = icon;
						return (
							<div key={itemIndex}>
								<button
									disabled={disabled}
									onClick={(event) => onItemClick(event, onClick)}
									className={clsx(
										"w-full",
										"text-gray-700",
										"hover:bg-gray-100 hover:text-gray-900",
										"flex px-4 py-2 text-sm",
										disabled ? "cursor-not-allowed opacity-60":"cursor-pointer",
									)}>
									{icon && <Icon className={clsx("mr-3 size-5 opacity-80",
										iconColorClassName || "text-gray-400", "hover:opacity-100")}
												   aria-hidden="true"/>}
									<span>{label}</span>
								</button>
							</div>
						);
					})}
				</div>
			</Popover.Dropdown>
		</Popover>
	);
};

export default ButtonWithPopover;
