import { t } from "i18next";
import type { FieldErrors, UseFormWatch } from "react-hook-form";
import type { ObjectSchema } from "yup";

import { DEFAULT_PAYMENT_TARGET_DAYS_OPTIONS, PAYMENT_TARGET_DAYS_DEFAULT_VALUE } from "~/constants/form.ts";
import type { FormInputOption } from "~/types/form.ts";

// removes all non-alphanumeric characters and converts to lowercase and remove all spaces
export const sanitizeStringForComparison = (string: string): string => {
	return string.toLowerCase().replace(/[^a-z0-9]/g, "");
};

export const sanitizeEmailForComparison = (email: string): string => {
	return email.toLowerCase().replace(/[^a-z0-9@.]/g, "");
};

export const sanitiseTitleForComparison = (title: string): string => {
	return title.toLowerCase().replace(/\s+/g, " ");
};


export const mapDataToInputOptionsData = <T>(data: T[],
	valuePropName: keyof T,
	labelPropName: keyof T,
	includeDefaultOption?: boolean): FormInputOption[] => {
	let selectOptions = data
		.filter(item => typeof item[valuePropName] === "string" && typeof item[labelPropName] === "string")
		.map(item => createInputOptionData(item[labelPropName] as string, item[valuePropName] as string));

	if (includeDefaultOption) {
		selectOptions = [getInputDefaultOptionsData(), ...selectOptions];
	}

	return selectOptions;
};

export const createInputOptionData = (displayName: string, value: string): FormInputOption => {
	return {
		label: displayName,
		value,
	};
};

export const getInputDefaultOptionsData = (labelText?: string, value?: string): FormInputOption => {
	return createInputOptionData(labelText || t("form:select.defaultOption"), value || "");
};

export const preventSubmitOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
	if (event.key === "Enter") {
		event.preventDefault();
	}
};

export const getPaymentTargetDefaultValues = (initialPaymentTargetDays: number | null): {
	paymentTargetDays: number,
	paymentTargetDaysCustom: number | null
} => {
	let paymentTargetDays = initialPaymentTargetDays || PAYMENT_TARGET_DAYS_DEFAULT_VALUE;

	let paymentTargetDaysCustom = null;
	if (!DEFAULT_PAYMENT_TARGET_DAYS_OPTIONS.includes(paymentTargetDays)) {
		paymentTargetDays = 0;
		paymentTargetDaysCustom = initialPaymentTargetDays;
	}

	return {
		paymentTargetDays,
		paymentTargetDaysCustom,
	};
};

export function transformEmptyDateValueToNull(value: Date | string, originalValue: Date | string | null) {
	return originalValue === "" ? null : value;
}

export function transformEmptyDateValueToZero(value: number | null, originalValue: number | null) {
	return !originalValue ? 0 : value;
}

export function formValidationDebug(formValues: UseFormWatch<any>,
	validators: ObjectSchema<any>,
	formErrors: FieldErrors<any>) {
	const validatorKeys = Object.keys(validators.fields);
	const formValueKeys = Object.keys(formValues);
	const missingKeysInValidator = validatorKeys.filter(key => !formValueKeys.includes(key));
	const missingKeysInFormValue = formValueKeys.filter(key => !validatorKeys.includes(key));
	console.log({ missingKeysInValidator, missingKeysInFormValue, formErrors });
	console.log(formValues);
}
