import StaffStatisticsView from "modules/humanResources/components/StaffStatisticsView";
import type { FunctionComponent } from "react";

import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { PermissionNames } from "~/types/entityNames.ts";

const StaffStatisticsPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanManageStaff);
	if (guard) return guard;

	return <StaffStatisticsView />;
};

export default StaffStatisticsPage;