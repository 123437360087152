import type { SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import Headline from "~/components/Headline";
import Table from "~/components/Table";
import { useSickDaysReport } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportQueries.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import sickDaysPerEmployeeTableColumnDefs
	from "~/modules/humanResources/components/StaffStatisticsView/components/SickDaysPerEmployeeTable/sickDaysPerEmployeeTableColumnDefs.tsx";
import type {
	SickDaysPerStaffMemberTableData,
	SickDaysPerStaffMemberTableMeta,
} from "~/modules/humanResources/types/staffStatisticsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

type Props = {
	selectedYear: string;
	setSelectedYear: (year: string) => void;
};

const SickDaysPerEmployeeTable: FunctionComponent<Props> = ({ selectedYear, setSelectedYear }) => {
	const {
		data: sickDaysReportData,
		isLoading: sickDaysReportIsLoading,
	} = useSickDaysReport();

	const yearSelectOptions = useMemo(() => {
		if (sickDaysReportData) {
			return Object.keys(sickDaysReportData).sort((a, b) => parseInt(b) - parseInt(a)).map(year => ({
				value: year,
				label: year,
			}));

		}
		return [];
	}, [sickDaysReportData]);


	const { data: staffMembersData } = useAllStaffMembers();
	const [sorting, setSorting] = useState<SortingState>([{ id: "staffMemberFullName", desc: false }]);
	const tableData: SickDaysPerStaffMemberTableData[] | null = useMemo(() => {
		if (selectedYear && sickDaysReportData && staffMembersData) {
			const yearData = sickDaysReportData[selectedYear];
			return Object.keys(yearData).reduce((acc, month) => {
				Object.keys(yearData[month].sickDays.staffMembers).forEach(staffMemberId => {
					const existingEntry = acc.find(entry => entry.staffMemberId === staffMemberId);
					const sickDays = yearData[month].sickDays.staffMembers[staffMemberId];
					if (!existingEntry) {
						acc.push({
							staffMemberId: staffMemberId,
							staffMemberFullName: staffMembersData.find(staffMember => staffMember.id === staffMemberId)!.fullName,
							months: { [month]: sickDays },
							total: sickDays,
						});
					} else {
						existingEntry.months[month] = yearData[month].sickDays.staffMembers[staffMemberId];
						existingEntry.total += sickDays;
					}
				});
				return acc;

			}, [] as SickDaysPerStaffMemberTableData[]);
		}
		return [];
	}, [selectedYear, sickDaysReportData, staffMembersData]);

	const table = useReactTable({
		data: tableData,
		columns: sickDaysPerEmployeeTableColumnDefs,
		state: {
			sorting,
			columnPinning: {
				left: ["staffMemberFullName"],
			},
		},
		meta: {
			selectedYear,
		} as SickDaysPerStaffMemberTableMeta,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});
	/*	{showYearSelect &&
		*/
	return <div className="mx-auto grid size-full max-w-7xl grid-rows-[auto_1fr] overflow-hidden px-2 pb-10 pt-4 sm:px-6 lg:px-8">
		{sickDaysReportIsLoading ? <LoadingPage /> :
			<>
				<div className="flex items-center justify-between pb-4">
					<Headline type="h4">Krankheitstage pro Mitarbeiter:in</Headline>
					<SelectPlain value={selectedYear}
								 optionsData={yearSelectOptions}
								 onChange={setSelectedYear} />
				</div>
				<div className="isolate overflow-hidden">
					<Table table={table}
						   verticalBorders={true} />
				</div>
			</>
		}

	</div>;

};


export default SickDaysPerEmployeeTable;