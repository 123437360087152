import type { FunctionComponent } from "react";

import type { TabBarTabVariants } from "~/components/TabBar/components/TabBarTab/tabBarTabVariants.ts";
import { tabBarTabVariants } from "~/components/TabBar/components/TabBarTab/tabBarTabVariants.ts";
import type { Tab } from "~/components/TabBar/tabBarTypes.ts";

type Props = TabBarTabVariants & {
	icon?: Tab["icon"];
	id: Tab["id"];
	label: Tab["label"];
	onClick: (tab: Tab["id"]) => void;
};

const TabBarTab: FunctionComponent<Props> = ({
	icon,
	id,
	active,
	label,
	onClick,
	theme
}) => {
	return <div
		onClick={() => onClick(id)}
		className={tabBarTabVariants({active, theme})}
	>
		{icon && icon}
		{label}
	</div>;
};

export default TabBarTab;