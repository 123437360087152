export interface WorkingStatusType {
	id: string;
	name: string;
}

export const WorkingStatusId = {
	Quotation: "1",
	Active: "2",
	Hold: "3",
	Closed: "4",
	Cancelled: "5",
} as const;

export type WorkingStatusId = typeof WorkingStatusId[keyof typeof WorkingStatusId];
